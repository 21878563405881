<template>
  <div class="indexcenter">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
      <div v-if="heardersw">
        <titletop />
      </div>
      <div class="optionbander">
        <div class="bigsix">第一代钢贸服务解决方案</div>
        <div class="smallsix">以服务打造新业务增长极</div>
      </div>
      <div class="backsmaimg">
        <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
        <div class="posionband">
          <div class="posionbando">定制化解决方案</div>
          <div class="posionbandt" >让客户感动</div>
        </div>
      </div>
    </div>

    <div class="xingkong">
      <div class="xkleft">
        <p class="xkone " id="panel"  v-show="swxkone">
          WHAT IS
        </p>
        <p class="xktwo " id="approach"  v-show="swxkone">
          WHAT IS STAR ALLLANC
        </p>
        <p class="xkthree " id="graphene"  v-show="swxkone" >
          ————    <span style="margin-left: 10px;"> 什么是星空联盟</span>
        </p>
      </div>
      <div class="xkright" id="xkright">

      </div>
    </div>
    <div class="bianzu">
      <div class="bzitemo">
        <p class="bztext">圆桌会议</p>
        <img class="bianzuimg" src="../assets/img/yzhy.png" alt="">
      </div>
      <div class="bzitemt">
        <p class="bztext">年度协议</p>
        <img class="bianzuimg" src="../assets/img/ndxy.png" alt="">
      </div>
      <div class="bzitemth">
        <p class="bztext">批量返利</p>
        <img class="bianzuimg" src="../assets/img/plfl.png" alt="">
      </div>
      <div class="bzitemf">
        <p class="bztext">资源保供</p>
        <img class="bianzuimg" src="../assets/img/zybg.png" alt="">
      </div>
      <div class="bzitemzj">
        <p class="bztext">专家支持</p>
        <img class="bianzuimg" src="../assets/img/zjzc.png" alt="">
      </div>
      <div class="gyljr">
        <p class="bztext">供应链金融</p>
        <img class="bianzuimg" src="../assets/img/gylaq.png" alt="">
      </div>
      <div class="gyinganquan">
        <p class="bztext">供应链安全</p>
        <img class="bianzuimg" src="../assets/img/gylaq.png" alt="">
      </div>
      <div class="shhbz">
        <p class="bztext">售后保障</p>
        <img class="bianzuimg" src="../assets/img/shbz.png" alt="">
      </div>
      <div class="clkyan">
        <p class="bztext">材料科研</p>
        <img class="bianzuimg" src="../assets/img/clyf.png" alt="">
      </div>
      <div class="ksyingy">
        <p class="bztext">快速响应</p>
        <img class="bianzuimg" src="../assets/img/ksxy.png" alt="">
      </div>
    </div>
    <div class="checkgongyi">
      <div class="chacktitle">
        <p class="chacktop">星空联盟-彼此成就</p>
        <p class="chackboom">整合多方资源 提供定制化解决方案备份</p>
      </div>
      <div class="chacicenter">
        <div class="chacitem" @click="index = 1">
          <img class="chacitemimg" src="../assets/img/gangchang.png" alt="">
          <p class="chacitemtext">钢厂</p>
        </div>
        <div class="chacitem" @click="index = 2">
          <img class="chacitemimg" src="../assets/img/jgsd.png" alt="">
          <p class="chacitemtext">加工</p>
        </div>
        <div class="chacitem" @click="index = 3">
          <img class="chacitemimg" src="../assets/img/zuahns.png" alt="">
          <p class="chacitemtext">专家</p>
        </div>
        <div class="chacitem" @click="index = 4">
          <img class="chacitemimg" src="../assets/img/zjinzz.png" alt="">
          <p class="chacitemtext">资金</p>
        </div>
        <div class="chacitem" @click="index = 5">
          <img class="chacitemimg" src="../assets/img/wlzz.png" alt="">
          <p class="chacitemtext">物流</p>
        </div>
      </div>
      <div class="ckyhrback" v-if="index == 1">
        <div class="ckpotion">
          <p class="ckpotop">钢厂</p>

          <p class="ckpoboom">与国内众多知名钢厂合作，</p>
          <p class="ckpoboom">可线上或线下咨询。</p>
        </div>
      </div>
      <div class="ckyhrback1" v-if="index == 2">
        <div class="ckpotion">
          <p class="ckpotop">加工</p>

          <p class="ckpoboom">在下单，生产,交货的过程中几乎可以做到</p>
          <p class="ckpoboom">全流程可视化</p>
        </div>
      </div>
      <div class="ckyhrback2" v-if="index == 3">
        <div class="ckpotion">
          <p class="ckpotop">专家</p>
          <p class="ckpoboom">搭建客户研发场景的平台，利用钢厂强大的研发团队</p>
          <p class="ckpoboom">解决真实材料开发和使用的堵点和卡点。</p>
        </div>
      </div>
      <div class="ckyhrback3" v-if="index == 4">
        <div class="ckpotion">
          <p class="ckpotop">资金</p>

          <p class="ckpoboom">通过生产、技术、销售、渠道、共同完成。</p>
        </div>
      </div>
      <div class="ckyhrback4" v-if="index == 5">
        <div class="ckpotion">
          <p class="ckpotop">物流</p>

          <p class="ckpoboom">铁运、水运、汽运相结合、专人跟进、</p>
          <p class="ckpoboom">  安全高效。</p>
        </div>
      </div>
    </div>
    <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
export default {
  data(){
    return{
      heardersw:false,
      swxkone:false,
      index: 1
    }
  },
  mounted(){
    //判断到达底部
    window.onscroll = function(){

      //定义变量获取屏幕视口宽度
      let windowWidth = document.body.clientWidth;
      if(windowWidth>700){
        //变量scrollTop是滚动条滚动时，距离顶部的距离
        let winscrollTop = document.documentElement.scrollTop||document.body.scrollTop;
        let panel = document.getElementById("panel").offsetTop;
        // let approach = document.getElementById("approach").offsetTop;
        // let graphene = document.getElementById("graphene").offsetTop;
        // let xkright = document.getElementById("xkright").offsetTop;

        console.log(winscrollTop);
        if(winscrollTop+500>=panel){
          console.log(this.swxkone);
          document.getElementById("panel").className ="xkone animationone";
          document.getElementById("panel").style.display = "block";
          document.getElementById("approach").className ="xktwo animationtwo";
          document.getElementById("xkright").className ="xkright animationthree";
          document.getElementById("approach").style.display = "block";
          document.getElementById("xkright").style.display = "block";
          document.getElementById("graphene").className ="xkthree animationthree";
          document.getElementById("graphene").style.display = "block";

        }
        else{
          document.getElementById("panel").className ="xkone";
          document.getElementById("panel").style.display = "none";
          document.getElementById("approach").className ="xktwo";
          document.getElementById("xkright").className ="xkright";
          document.getElementById("approach").style.display = "none";
          document.getElementById("xkright").style.display = "none";
          document.getElementById("graphene").className ="xkthree";
          document.getElementById("graphene").style.display = "none";

        }
        if(winscrollTop+300>=panel){
          console.log(this.swxkone);
          document.getElementById("panel").className ="xkone animationone";
          document.getElementById("panel").style.display = "block";
          document.getElementById("approach").className ="xktwo animationtwo";
          document.getElementById("xkright").className ="xkright animationthree";
          document.getElementById("approach").style.display = "block";
          document.getElementById("xkright").style.display = "block";
          document.getElementById("graphene").className ="xkthree animationthree";
          document.getElementById("graphene").style.display = "block";

        }
        else{
          document.getElementById("panel").className ="xkone";
          document.getElementById("panel").style.display = "none";
          document.getElementById("approach").className ="xktwo";
          document.getElementById("xkright").className ="xkright";
          document.getElementById("approach").style.display = "none";
          document.getElementById("xkright").style.display = "none";
          document.getElementById("graphene").className ="xkthree";
          document.getElementById("graphene").style.display = "none";

        }




      }
    }

  },
  methods:{
    showhearder(){
      this.heardersw =! this.heardersw
    }
  },
  name: 'App',
  components: {
    Foot,
    titletop
  }
}
</script>
<style lang="scss" scope>
.animationone{
  animation:1s fadeInUp;
}
.animationtwo{
  animation:3s fadeInUp;
}
.animationthree{
  animation:4s fadeInUp;
}
.bianzu{
  background-image: url(../assets/img/bianzu.png);
  height: 245px;
  max-width: 1574px;
  margin: 0 auto;
  background-position: 100%;
  background-size: 100% 100%;
  position: relative;
  .bzitemo{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: -26px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: -12px;
      width: 59px;
      height: 59px;
    }
  }
  .gyinganquan{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: 1000px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: 1015px;
      width: 59px;
      height: 59px;
    }
  }
  .clkyan{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: 1339px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: 1358px;
      width: 59px;
      height: 59px;
    }
  }
  .ksyingy{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      right: -25px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      right: -14px;
      width: 59px;
      height: 59px;
    }
  }
  .shhbz{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      left: 1172px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      left: 1182px;
      width: 59px;
      height: 59px;
    }
  }
  .gyljr{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      left: 830px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      left: 842px;
      width: 59px;
      height: 59px;
    }
  }
  .bzitemt{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      left: 154px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      left: 158px;
      width: 59px;
      height: 59px;
    }
  }
  .bzitemzj{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: 655px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: 672px;
      width: 59px;
      height: 59px;
    }

  }
  .bzitemf{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      left: 490px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      left: 499px;
      width: 59px;
      height: 59px;
    }
  }
  .bzitemth{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: 314px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: 329px;
      width: 59px;
      height: 59px;
    }
  }
}
.xingkong{
  width: 100%;
  display: flex;
  margin: 200px auto;
  // justify-content: space-between;
  align-items: center;
  // padding: 200px;
  height: 700px;
  box-sizing: border-box;
  .xkleft{
    width: 50%;
    //  border: 1px solid red;
    // margin-left: 50px;
    text-align: right;
    margin-right: 60px;
    .xkone{
      font-size: 120px;
      font-family: LiGothicMed;
      color: #F2F2F2;
      line-height: 180px;
      letter-spacing: 12px;
    }
    .xktwo{
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 45px;
      margin-top: -86px;
      margin-bottom: 30px;
    }
    .xkthree{
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 45px;
    }
  }
  .xkright{
    // border: 1px solid red;
    background-image: url(../assets/img/xklm.png);
    height: 600px;
    width: 820px;
    background-position: 100%;
    background-size: 100% 100%;
  }
}
.indexcenter{
  .backimg{
    // border: 1px solid red;
    height: 600px;
    background-image: url(../assets/img/dbanner.png);
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
  }
  .hearderimg{
    width: 29px;
    height: 21px;
    position: absolute;
    right: 50px;
    top: 20px;
  }
  .showhearder{
    padding: 50px ;
    padding-top: 100px;

    width: 345px;
    height: 608px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: white;
    z-index: 10;
    .heaitem{
      // border: 1px solid red;
      display: flex;
      align-items: center;
      margin: 30px 0;
      .heaitemtext{
        // font-size: 18px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .ullist{
      padding-left: 80px;
      .liitem{
        list-style: none;
        margin: 20px 0;
      }
      .liactive{
        color: rgb(230,82,86);
      }

    }
  }
  .backsmaimg{
    width: 36%;
    height: 300px;
    border-radius: 8px;
    background-image: url(../assets/img/bigbanhder.png);
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -100px;
    right: 220px;
    .posionband{
      position: absolute;
      // border: 1px solid red;
      left: 100px;
      top: 80px;
      color: white;
      .posionbando{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 74px;
      }
      .posionbandt{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        margin-top: 20px;
      }
    }
  }
  .optionbander{
    position: absolute;
    top: 239px;
    left: 232px;
    color: white;
    .bigsix{
      font-size: 60px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 89px;
      letter-spacing: 12px;
    }
    .smallsix{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 27px;
      letter-spacing: 6px;
      margin-top: 20px;
    }
  }
  .bander{
    width: 100%;
  }
  .banderdsd{
    // width: 700px;
    margin-left: 797px;
    margin-top: -173px;
  }
}
.checkgongyi{
  max-width: 1600px;
  margin: 200px auto;
  .chacktitle{
    .chacktop{
      font-size: 50px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 70px;
      letter-spacing: 5px;
    }
    .chackboom{
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      letter-spacing: 2px;
    }
  }
  .chacicenter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 100px 50px;
    .chacitem{
      .chacitemimg{
        width: 120px;
        height: 120px;
      }
      .chacitemtext{
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        letter-spacing: 1px;
      }
    }
  }
  .ckyhrback{
    background-image: url(../assets/img/xklm/bg.png);
    height: 700px;
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
    color: white;
    .ckpotion{
      position: absolute;
      top: 50px;
      left: 40px;
      .ckpotop{
        font-size: 80px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 112px;
        letter-spacing: 5px;
        margin-bottom: 50px;
      }
      .ckpoboom{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 70px;
      }
    }
  }
  .ckyhrback1{
    background-image: url(../assets/img/vbuanju.png);
    height: 700px;
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
    color: white;
    .ckpotion{
      position: absolute;
      top: 50px;
      left: 40px;
      .ckpotop{
        font-size: 80px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 112px;
        letter-spacing: 5px;
        margin-bottom: 50px;
      }
      .ckpoboom{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 70px;
      }
    }
  }
  .ckyhrback2{
    background-image: url(../assets/img/xklm/bg1.png);
    height: 700px;
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
    color: white;
    .ckpotion{
      position: absolute;
      top: 50px;
      left: 40px;
      .ckpotop{
        font-size: 80px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 112px;
        letter-spacing: 5px;
        margin-bottom: 50px;
      }
      .ckpoboom{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 70px;
      }
    }
  }
  .ckyhrback3{
    background-image: url(../assets/img/xklm/bg2.png);
    height: 700px;
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
    color: white;
    .ckpotion{
      position: absolute;
      top: 50px;
      left: 40px;
      .ckpotop{
        font-size: 80px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 112px;
        letter-spacing: 5px;
        margin-bottom: 50px;
      }
      .ckpoboom{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 70px;
      }
    }
  }
  .ckyhrback4{
    background-image: url(../assets/img/xklm/bg3.png);
    height: 700px;
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
    color: white;
    .ckpotion{
      position: absolute;
      top: 50px;
      left: 40px;
      .ckpotop{
        font-size: 80px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 112px;
        letter-spacing: 5px;
        margin-bottom: 50px;
      }
      .ckpoboom{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 70px;
      }
    }
  }
}
</style>