<template>
  <div class="dsdsdsd">
      111
    
       
  </div>
</template>
<script>
export default {
data(){
 return{

 }
},
mounted(){
 

},
methods:{
  
},
name: 'App',
components: {
 
  
}
}
</script>
<style lang="scss" scope>

</style>