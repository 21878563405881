<template>
  <div class="founderstory">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
        <div v-if="heardersw">
        <titletop />
       </div>
    <div class="optionbander">
      <div class="bigsix">值得托付-彼此成就-共同富裕</div>
      <div class="smallsix" >为行业与社会做出巨大贡献的公司</div>
    </div>
    <div class="backsmaimg">
     <div class="posionband">
      <div class="posionbando">创始人故事</div>
      <div class="posionbandt" >让员工幸福</div>
    </div>
   </div>
    </div>
    <div class="fonderimg">
      <div class="fonder" >
        <div class="fonderleft" style=" width: 48%;position: relative;">
         <img src='../assets/img/dongshiz.png' alt="">
         <img src='../assets/img/dongsz.gif' style="position: absolute;left: -57px;top: -53px;" alt="">
          <p class="jianjie" style="font-size: 32px;font-family: STSongti-SC-Regular, STSongti-SC;position: absolute;left: 120px;bottom: -90px">依道而行——人生哲学</p>
        </div>
        <div class="right" style="text-align: left;margin-left: 80px;width: 48%; height: 100%;" >
           <div class="hangping">皇平 KEVIN<p class="jianjie"> 中共党员 鸿旗集团董事长</p></div>
           <div >
           <p class="jianjie">来自于云南，毕业于东北大学</p>
           <p class="jianjie">上海市工商联钢贸商会副会长</p>
           <p class="jianjie">上海市宝山区工商联钢构商会副会长，云南商会会员 </p>
           <p class="jianjie">东北大学长三角青年企业家俱乐部发起人之一</p>
           <p class="jianjie">迄今为止深耕钢材行业20余年，在钢贸行业拥有很高的美誉度</p>
           </div>
        
        </div>
    </div>
    </div>
   
    <div class="foundcon">
      <div class="foundconleft">
        <p class="fountext">万鸿昨天，鸿旗今天，今日鸿旗不能忘记昨日万鸿之奋斗。</p>
        <p class="fountext">于2010年正式操盘万鸿发展，得益于布局优秀的资源，得益于团队齐心协力，</p>
        <p class="fountext">万鸿的发展非常顺利，每年都是高速增长约50%-100%，我们依然是直接服务终端客户。</p>
      </div>
      <img src="../assets/img/hqmz.png" alt="">
    </div>
   
     <div class="foundconright foundconleft">
       <img src="../assets/img/dshit.png" alt="">
     <div class="foundconleft fonconimg">
        <p class="fountext">对未来保持乐观并在行动中不断总结与调整，</p>
        <p class="fountext">总会找到最适合自己发展的模式。</p>
        <p class="fountext">到2021年我们集团化发展。</p>
        <p class="fountext"> 注册了上海鸿旗企业发展集团有限公司，正式升级为鸿旗集团。 </p>
        <p class="fountext">鸿旗与“红旗”同音，因此我们寄予厚望期待它的发展与时代同频，</p>
        <p class="fountext">并能在民族复兴的伟大历程中贡献自己的力量。 </p>
        <p class="fountext"> 于此特别说明：2017我有幸加入中国优秀传统文化-阳明心学的学习，</p>
        <p class="fountext">随着学习不断深入，我们在集团发展层面提出了五位一体的五星鸿旗战略。</p>

      </div>
      
     </div>
   
<div class="foundcon" style="margin: 150px auto;">
  <img src="../assets/img/hoset.png" alt="">

</div>
<div class="foundconright foundconrleft">
     <div class="foundconleft fonconimgleft">
        <p class="fountext">接下来，我们全体同仁会万众一心，众志成城朝着这个方向努力奋斗。</p>
        <p class="fountext"> 一定可以在民族复兴的历程中做出我们应有的贡献， </p>
        <p class="fountext"> 鸿旗人也终将因此而感到自豪和骄傲。</p>
        <p class="fountext"> 这是文化自信的伟大时代，这是民族复兴的盛世中华， </p>
        <p class="fountext">我们一定可以成为一家伟大的企业，我们一定可以助力行业发展升级， </p>
        <p class="fountext"> 并最终成为为服务中国制造的最坚强后盾，这是所有钢贸人的终极荣耀！ </p>

      </div>
      <img src="../assets/img/dshio.png" class="" alt="">
     </div>
   
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
export default {
data(){
 return{
  heardersw:false,
 }
},
mounted(){
 console.log(this.$store.state.currPage);

},
methods:{

  showhearder(){
    this.heardersw =! this.heardersw
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
 
}
}
</script>
<style lang="scss" scope>

.foundcon{
  display: flex;
  max-width: 1600px;
  align-content: center;
  margin: 100px auto;
  justify-content: space-between;
  .foundconleft{
    .fountext{
      font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 60px;
    }
  }
 
}
.foundconright{
  // border: 1px solid red;
   display: flex;
  align-content: center;
  margin: 100px auto;
  margin-left: 200px; 
  max-width: 1900px;
  .fonconimg{
     background-image: url(../assets/img/dstop.png);
  background-size: 100% 100%;
  padding: 100px 50px; 
  width: 960px;
  // padding-top: 200px;
  }
  .fonconimgleft{
    background-image: url(../assets/img/dstop.png);
  background-size: 100% 100%;
  padding: 200px 50px; 
  width: 960px;
  padding-left: 200px;
  }
  .fountext{
    font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #000000;
line-height: 60px;
  }
}
.foundconrleft{
  margin-left: 0px; 
}
.fonderimg{
  width: 100%;
  background-image: url(../assets/img/donimg.png);
  background-size: 100% 100%;
  height: 720px;
  // margin: 200px auto;
  margin-bottom: 200px;
}
.fonder{

   display: flex;
    justify-content: space-between;
    height: 550px; 
    align-items: center;
    margin: 0 auto;
     max-width: 1600px;
     padding-top: 100px;
   
}
.hangping{
  font-size: 40px;
  font-family: FZBWKSJW--GB1-0, FZBWKSJW--GB1;
  font-weight: normal;
  color: #000000;
  line-height: 40px;
  margin: 30px 0;
  margin-bottom:40px;
}
.jianjie{
  font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 60px;
text-align: left;
// width: ;

}
.founderstory{
  width: 100%;
  overflow: hidden;
  // border: 1px solid red;
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/banbanne.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
  border-radius: 8px;
background-image: url(../assets/img/bigbanhder.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 80px;
  color: white;
  .posionbando{
    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 74px;
    text-align: left;
  }
  .posionbandt{
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    text-align: left;
    color: #FFFFFF;
    line-height: 27px;
    margin-top: 20px;
    margin-left: 3px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 232px;
  color: white;
  .bigsix{
    font-size: 60px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 89px;
letter-spacing: 12px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}



}


</style>