<template>
  <!--时间线-->
  <div style="display:flex;">
      <!-- 当日志记录不超过9个，全排列 -->
     
      <!-- 当日志记录超过8个，可以左右滑动 -->
      <div class="container" >
          <div style="display:flex;" class="nianfenxia">
              <div @click="moveLeft()" class="moveLeft"> 
                  <img src="../../assets/img/zuohua.png" alt="">
              </div>

              <div v-for="(item,index) in timeLineList" :key="index" v-if="index > point && index <= point_end" style="width: 360px;" >
                 
                <div  :class="index % 2 == 0 ?'listxiantop':'listxianbom' " >
               <img v-if="index % 2 == 0" src="../../assets/img/niantop.png" alt="">
               <img v-else src="../../assets/img/nianbom.png" alt="">

                  <div class="lineinfo">
                      {{timeLineList[index].info}}
                  </div>
                  <div  :class="index % 2 == 0 ?'linetimestamp':'linetimestamppp' ">
                     {{timeLineList[index].timestamp}}
                  </div>
                </div>
              </div>
             
                <div @click="moveRight()" class="moveRight"> 
                  <img src="../../assets/img/zuohua.png" alt="">
         
                  
              </div>
          </div>
      </div>
  </div>
  
</template>

<script>
export default {
  name: 'timeLine',
  props:['timeLineList'],
  data() {
      return {
          timeIndex: this.timeLineList.length-1, //默认当前选择的为最近的时间点
          point: this.timeLineList.length-4, //时间轴只展示6个,初始为最后6个
          point_end: this.timeLineList.length-1,
          right_button_active: false,
          left_button_active: true,
          left_button_disabled: false,
          right_button_disabled:true,
      }
  },
  methods: {
      
      moveLeft()  {
          if(this.point>0){
              this.point -= 1
              this.point_end -= 1
              this.timeIndex -= 1
              this.right_button_disabled = false
              this.right_button_active = true
              if(this.point===0){
                  //如果移到第一个时间点，设置左按钮不可点击
                  this.left_button_disabled = true
                  this.left_button_active = false
              }
          }
      },
      moveRight() {
          if(this.point_end<this.timeLineList.length-1){
              this.point += 1
              this.point_end += 1
              this.timeIndex += 1
              this.left_button_disabled = false
              this.left_button_active = true
              console.log("————————————",this.timeIndex)
              if(this.point_end===this.timeLineList.length-1){
                  //如果移到最后一个时间点，设置右按钮不可点击
                  this.right_button_disabled = true
                  this.right_button_active = false
              }
          }
      }
  }
}
</script>

<style lang="scss" scoped>
.moveLeft{
  position: relative;
 top: 32px;
 left: -18px;
}
.moveRight{
  position: relative;
 top: 32px;
 left: 256px;
}
.lineinfo{
 position: relative;
 top: -70px;
 left: 206px;
 color: rgb(200,34,39);
 text-align: left;
 
}
.linetimestamp{
  position: relative;
 top: -136px;
 left:62px;
 text-align: center;
 color: rgb(200,34,39);
}
.linetimestamppp{
  position: relative;
 top: -62px;
 left:62px;
 text-align: center;
 color: rgb(200,34,39);
}
.container{
width: 100%;
  height: 488px;
 margin: 100px 0;
  margin-bottom: 5px;
  // border: 1px solid red;
  background-image: url(../../assets/img/yunbeijin.png);
  background-size: 100% 100%;
  .nianfenxia{
    width: 1400px;
  height: 22px;
  margin: 0 auto;
    background-image: url(../../assets/img/nianfenx.png);
    position: relative;
    top: 240px;
    .listxianbom{
      position: relative;
      top: 20px;
      left: 54px;
    }
    .listxiantop{
      position: relative;
      top: -88px;
    }
  }
}
.dot{
  border:2px solid #DCDFE6;
  width: 15px;
  height: 15px;
  border-radius: 15px;
  background: white;
  margin: 2px 0px;
  box-sizing: border-box;
}
.item{
  flex:1;
  border-bottom:1px solid #DCDFE6;
  margin-bottom: 9px;
  box-sizing: border-box;
}
.item_bottom{
  flex:1;
  text-align:center;
  height: 15px;
  margin-top:7px;
  font-size: 14px;
}
.move-button {
  border:2px solid #DCDFE6;
  width: 40px;
  height: 19px;
  border-radius: 19px;
  background: white;
  text-align: center;
  box-sizing: border-box;
}
.active{
  background-color: #fff !important;
  border: 3px solid #67C23A;
}

</style>