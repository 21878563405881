<template>
  <div class="callus">
    <div class="backimg">
        <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
       <div v-if="heardersw">
        <titletop />
       </div>
      <div class="optionbander">
        <div class="bigsix">用心倾听每一份声音</div>
        <div class="smallsix">虚心接受-拥抱成长</div>
      </div>
      <div class="backsmaimg">
       <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
       <div class="posionband">
        <div class="posionbando">董事长直达</div>
        <div class="posionbandt" >鸿旗企业</div>
      </div>
     </div>
      </div>


      <div style="max-width: 1600px; margin: 100px auto">
        <div  style="
          margin-top: 200px;
        
        ">
           <p style="width: 162px;
height: 48px;
font-size: 32px;
font-family: AppleSystemUIFont;
color: #010001;
line-height: 47px;border-bottom: 4px solid #C82227;padding-bottom: 10px;">董事长直达</p>
        </div>

       <div style="max-width: 1440px;display: flex; margin: 100px auto;margin-bottom: 200px;" >
             <div style="width: 720px;height: 580px;  box-shadow:30px 40px 40px 40px rgb(245,245,245);" >
               <div style="display: flex; margin-top: 240px;margin-left: 100px;">
                <img style="width: 95px; height: 81px;" src="../assets/img/jianyi.png" alt="">
              <div style="margin-top: -60px;margin-left: 50px;">
                <p style="font-size: 28px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #000000;
line-height: 47px;">其他反馈</p>
                <p style="width: 418px;
height: 56px;
font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 28px;margin: 50px 0;">我们会诚恳倾听以及虚心接受每一份来自
不同身份的建议以及鞭策，一起拥抱成长。</p>
                <p style="width: 260px;

font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
background: #C82227;padding: 10px;box-sizing: border-box;">winhonor01@163.com</p>
              </div>
               </div>
             </div>
             <img src="../assets/img/dszhua.png" alt="">
       </div>

      </div>
 
    


   
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
export default {
data(){
 return{
  heardersw:false,
 }
},
mounted(){
 console.log(this.$store.state.currPage);

},
methods:{

  showhearder(){
    this.heardersw =! this.heardersw
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
 
}
}
</script>
<style lang="scss" scope>


.animationone{
  animation:1s fadeInUp;
  }
  .animationtwo{
  animation:3s fadeInUp;
  }
   .animationthree{
  animation:4s fadeInUp;
  }


.callus{
  overflow: hidden;
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/dbanner.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
background-image: url(../assets/img/bigbanhder.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
  border-radius: 8px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 80px;
  color: white;
  .posionbando{
    font-size: 50px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 74px;
  }
  .posionbandt{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
margin-top: 20px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 232px;
  color: white;
  .bigsix{
    font-size: 60px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 89px;
letter-spacing: 12px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}


}

</style>