<template>
  <div class="swiperBox">
    <div class="container">
     <div class="wave">
         <div class="circle"></div>
         <div class="card">
           
         </div>
     </div>
</div>
  </div>
</template>

<script>
  export default {
  data() {
    return {
     
    };
  },
  methods: {
  }
};

</script>

<style scoped lang="scss">



/* 波动效果 */
.wave {
    position: relative;
    width: 20px;  
    height: 20px;  
    text-align: center;
}
.wave .circle{
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    background: #77ACF8 ;
}
.wave .circle:first-child {
    animation: circle-opacity 2s infinite;
}

@keyframes circle-opacity{
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(2);
    }
}
.card{
  width: 20px;  
    height: 20px;  
    border-radius: 50%;
    background-color: #77ACF8;
    color:#fff;
    position: absolute;
    z-index: 999;
}
</style>