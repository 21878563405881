<template>
  <div class="aboutUs">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
      <div v-if="heardersw">
        <titletop />
      </div>
      <div class="optionbander">
        <div class="bigsix">致力于成为钢铁行业的一面旗帜</div>
        <div class="smallsix" >资源共享-品牌共创</div>
      </div>
      <div class="backsmaimg">
        <div class="posionband">
          <div class="posionbando">上海世贝材料科技有限公司</div>
          <div class="posionbandt" >鸿旗企业/企业简介/下属公司</div>
        </div>
      </div>
    </div>

    <div class="subordtop">
      <div class="suboleft">
        <div  style="display: flex; justify-content: space-between;">
          <div>
        <span style="width: 100px;
height: 267px;
font-size: 140px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #114989;
">01</span> <span style="color: #114989;">-关于世贝</span>
          </div>
          <div style="width: 375px;
height: 42px;
font-size: 28px;
font-family: AppleSystemUIFont;
color: #000000;
line-height: 41px;padding-top: 120px;">
            节约资源 让钢铁制品使用更久
          </div>
        </div>
        <div style="width: 860px;
height: 204px;
font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #666666;
line-height: 51px;margin-top: 80px;">
          上海世贝材料科技有限公司致力于关注并将最前沿科技材料转化为社会生产力之事
          业。我们专注于打造民族“钢旗”品牌，聚焦于服务锌铝镁系列产品！3镁11铝科技产
          品的防腐性能堪比不锈钢，如何快速推广并为客户创造价值从而实现节约社会资源，
          这个责任世贝敢于担当，“钢旗”品牌责无旁贷。
        </div>
      </div>
      <div class="suboright">
        <img style="width: 680px; height: 500px;" src="../assets/img/shibei/dangjianimg.png"  alt="">
      </div>
    </div>


    <div class="subordtop">
      <div class="suboright">
        <img style="width: 680px; height: 500px;" src="../assets/img/shibei/dangjianimg1.png"  alt="">
      </div>
      <div class="suboleft" style="margin-left: 60px;">
        <div  style="display: flex; justify-content: space-between; margin-top: -50px;">
          <div>
        <span style="
height: 267px;
font-size: 140px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #114989;
">02</span> <span style="color: #114989;">-服务优势</span>
          </div>
          <div style="
height: 42px;
font-size: 28px;
font-family: AppleSystemUIFont;
color: #000000;
line-height: 41px;padding-top: 120px;">
            资源共享 品牌共创 彼此成就
          </div>
        </div>
        <div>
          <span style="font-size: 24px;">渠道优势</span>
          <span style="
height: 204px;
font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #666666;
line-height: 51px;margin-top: 10px;">
     —钢旗拥有战略合作原料商，全国布局的彩涂代工商、油漆生产商、全国分布的服
务商战略合作的终端客户，为客户提供供应链整体解决方案。

    </span>
        </div>
        <div>
          <span style="font-size: 24px;">团队优势</span>
          <span style="
height: 204px;
font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #666666;
line-height: 51px;margin-top: 10px;">
    —钢旗拥有职业的运营采用优质原材料，确保专业的材料团队、专业的技术团队、
行业内经过严格的进出料检验和国际的顾问团队，以“按贡献度享受分配质量标准”的价值观共同营
造共生共荣共发展的生态系统。


    </span>
        </div>
        <div>
          <span style="font-size: 24px;">质量承诺 </span>
          <span style="
height: 204px;
font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #666666;
line-height: 51px;margin-top: 10px;">
    —保证提供的产品均采用优质原材料，确保材料经过严格的进出料检验和国际质量标准。


    </span>
        </div>
        <div style="width: 980px;">
          <span style="font-size: 24px;">增值服务</span>
          <span style="
height: 204px;
font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #666666;
line-height: 51px;margin-top: 10px;">
     —金融服务优势、管理服务优势、企业文化服务优势、立体综合式服务体系利益客户。

    </span>
        </div>

      </div>

    </div>

    <div>
      <p style="font-size: 46px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 47px;text-align: center;margin: 80px 0;">
        产品介绍
      </p>
      <div>
        <div class="zhongzi">
          <div class="left">

            <chanpin1 />
          </div>
<!--          <div class="right">
            <p class="rightop">钢旗3镁11铝锌铝镁光板 SZAMCHN</p>
            <div class="righcen">
              <p class="righcentext">超耐蚀-是热浸镀锌钢板的10-20倍</p>
              <p class="righcentext">自修复-切割面有自我修复的功能</p>
              <p class="righcentext">长寿命-标准环境之下,质保达到30年以上</p>
              <p class="righcentext">易加工-冷弯,拉伸,冲压,焊接等加工性能优越</p>
            </div>
            <p class="rightop">使用领域：新能源领域(含光伏行业)畜牧行业、支架、吊架、管廊行业高速护栏行业现代化种植行业家电、汽车行业、高端建筑行业。</p>
            <p class="righbomcp">联系我们:400-600-8891</p>
          </div>-->
        </div>

      </div>
    </div>
    <div>
      <!-- <chapinjies /> -->
    </div>



    <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import chanpin1 from '../components/shehui/chanpin1.vue'
// import chapinjies from '../components/shehui/chapinjies.vue'
export default {
  data(){
    return{
      heardersw:false,
    }
  },
  mounted(){
    console.log(this.$store.state.currPage);

  },
  methods:{

    showhearder(){
      this.heardersw =! this.heardersw
    }
  },
  name: 'App',
  components: {
    Foot,
    titletop,
    chanpin1,

  }
}
</script>
<style lang="scss" scope>

.zhongzi{
  max-width: 1600px;
  margin: 100px auto;
  display: flex;
  align-items: center;
  .right{
    margin-left: 80px;
    .rightop{
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 37px;
    }
    .righcen{
      margin: 100px 0;
    }
    .righcentext{
      font-size: 22px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #666666;
      line-height: 60px;
    }
    .righbomcp{
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border-radius: 8px;
      margin-top: 80px;
      line-height: 45px;
      background: rgb(34,129,192);
      padding: 10px;
      width: 356px;
      height: 45px;
      color: white;
    }
  }
}

.aboutUs{
  overflow-x: hidden;
  .backimg{
    // border: 1px solid red;
    height: 600px;
    background-image: url(../assets/img/shibei/banner.png);
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
  }
  .hearderimg{
    width: 29px;
    height: 21px;
    position: absolute;
    right: 50px;
    top: 20px;
  }
  .showhearder{
    padding: 50px ;
    padding-top: 100px;

    width: 345px;
    height: 608px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: white;
    z-index: 10;
    .heaitem{
      // border: 1px solid red;
      display: flex;
      align-items: center;
      margin: 30px 0;

    }

  }
  .liactive{
    color: rgb(230,82,86);
  }
  .backsmaimg{
    width: 710px;
    height: 300px;
    background-image: url(../assets/img/shibei/bigbanhder.png);
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    border-radius: 8px;
    bottom: -100px;
    right: 220px;
    .posionband{
      box-sizing: border-box;
      padding: 0 0 0 55px;
      position: absolute;
      // border: 1px solid red;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: white;
      .posionbando{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 74px;
      }
      .posionbandt{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        margin-top: 20px;
      }
    }
  }
  .optionbander{
    position: absolute;
    top: 239px;
    left: 232px;
    color: white;
    .bigsix{
      font-size: 60px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 89px;
      letter-spacing: 12px;
    }
    .smallsix{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 27px;
      letter-spacing: 6px;
      margin-top: 20px;
    }
  }

  .subordtop{
    max-width: 1600px;
    margin: 200px auto;
    display: flex;
    justify-content: space-between;

  }

}

</style>