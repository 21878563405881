<template>
  <div>


<div style="height: 650px;">
  <div v-if="syindex==0">
  <div class="jiandang" >
    <div class="scllboom">
        <div class="titrighttlscll">
        <div class="rightop">
        <div class="rightopone">
        <p style="color:#C82227; font-size: 22px;">钢铁行业重大新闻</p>
        <p style="color: #aaa;">2</p>
       </div>
       <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
       <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2023</p>
       <p style="text-align: right; color: #aaa;">07</p>
       </div>
        </div>
        <div class="righboom">
          日前，宝武集团韶钢松山第一车螺纹钢筋深加工产品出库发往客户工地，标志着韶钢松山正式推出“钢厂―加工中心―用户”的服务新模式，凭借钢厂及加工单位合作优势，为下游企业提供产品制造、半成品加工配套的“一站式”解决方案。发展深加工,延伸产业链,加快服务转型,是钢铁企业求生存、谋发展的有效途径。韶钢松山将继续深入挖掘钢材产品加工服务内涵，打造新的效益增长点，更好地服务市场客户。
        </div>
      
</div>
        <div class="titleftlscll">
          <img src="../xinwen/img/hyt.png" class="titleimg" alt="" />
 
      </div>
     
      </div>
    
  </div>

</div>
  <div class="jiandang" v-if="syindex==1">
    <div class="scllboom">
        <div class="titrighttlscll">
        <div class="rightop">
        <div class="rightopone">
          <p style="color:#C82227; font-size: 22px;">钢铁行情预测</p>
        <p style="color: #aaa;">2</p>
       </div>
       <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
        <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2023</p> 
       <p style="text-align: right; color: #aaa;">06</p> 
       </div>
        </div>
        <div class="righboom">
          上周钢材市场呈现冲高回落态势，主要是大部分下游工程项目尚未复工，钢价无量空涨不可持续。元宵节过后，下游工程项目将加快复工。当前钢厂多数处于小幅亏损或盈亏边缘，上周供应增速偏快，导致市场情绪谨慎。短期钢价或震荡偏弱运行。
        </div>
      
</div>
        <div class="titleftlscll">
          <img src="../xinwen/img/hyt.png" class="titleimg" alt="" />
 
      </div>
     
      </div>
  
  </div>
  <div class="jiandang" v-if="syindex==2">
    <div class="scllboom">
        <div class="titrighttlscll">
        <div class="rightop">
        <div class="rightopone">
          <p style="color:#C82227; font-size: 22px;">冷轧板卷行情</p>
        <p style="color: #aaa;">2</p>
       </div>
       <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
        <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2023</p> 
       <p style="text-align: right; color: #aaa;">07</p> 
       </div>
        </div>
        <div class="righboom">
          2月7日国内冷轧市场价格部分下调，市场交投氛围冷清。据兰格钢铁云商平台监测统计，国内1m冷卷均价4598元，较上一个工作交易日价格下调9元。上海1.0mm冷轧板卷价格4510元，广州1.0mm冷轧板卷价格4570元，天津1.0mm冷轧板卷价格4480元。华北地区天津市场价格暂稳，据贸易商反馈，近期钢材库存水平较春节前快速提升，现货价格报价多稳，市场交投氛围冷清，贸易商为出货可接受议价。
        </div>
      
</div>
        <div class="titleftlscll">
          <img src="../xinwen/img/hyth.png" class="titleimg" alt="" />
 
      </div>
     
      </div>
  
  </div>
 
</div>
<div class="xinwenimgclick ">

<img
        @click="imgclickjian"
        src="../../assets/img/zuohua.png"
        style="width: 35px"
        alt=""
      />
      <img
        @click="imgclickadd"
        src="../../assets/img/youhua.png"
        style="width: 35px"
        alt=""
      />
</div>


</div>
</template>

<script>
export default {
  data() {
    return {
      syindex: 0,
    };
  },

  mounted() {},
  methods: {
    imgclickjian() {
      if (this.syindex > 0) {
        this.syindex = this.syindex - 1;
      }
    },
    imgclickadd() {
      if (this.syindex < 2) {
        this.syindex = this.syindex + 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
 .xinwenimgclick{
  // border: 1px solid red;
    width: 180px;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
  }
.jiandang {
  // height: 572px;
  .scllboom {
    display: flex;
    // align-items: center;
    max-width: 1600px;
    margin: 50px auto;
    height: 400px;
    // border: 1px solid red;
    .titleftlscll {
      // border: 1px solid red;
      .titleimg {
        // border: 1px solid red;
        width: 864px;
        height: 556px;
      }
      .imgclick {
        width: 200px;
        // border: 1px solid red;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;
       
      }
    }
    .titrighttlscll {
      padding: 10px 20px;
      width: 864px;
      // height: 600px;
      // width: 400px;
      // border: 1px solid red;
      .rightop {
        box-shadow:10px 6px 30px 1px rgb(245,245,245);
        height: 70px;
        width: 100%;
        padding:  10px;
        box-sizing: border-box;
        .rightopone {
          // padding: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid rgb(200, 36, 41);
        }
      }
      .righboom {
        width: 800px;
        height: 380px;
        padding: 10px;
        box-sizing: border-box;
        box-shadow:10px 6px 30px 1px rgb(245,245,245);
        text-align: left;
        // margin-top: 80px;
        color: #aaa;
        font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 60px;
      }
    }

  }
}

</style>
