<template>
  <div class="motherland">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
      <div v-if="heardersw">
        <titletop />
      </div>
      <div class="optionbander">
        <div class="bigsix">新一代钢贸服务解决方案</div>
        <div class="smallsix" >以服务打造新业务增长极</div>
      </div>
      <div class="backsmaimg">
        <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
        <div class="posionband">
          <div class="posionbando">创新型服务性企业<br/>鸿旗新材 | 新能源</div>
          <div class="posionbandt" >让社会尊重</div>
        </div>
      </div>
    </div>
    <div class="weiyiwangBox">
      <div class="weiyiwangContent">
        <p>创新型服务性企业-尾易网</p>
        <span>
          尾易网，成立于2021年11月，是国内专业的金属尾货交易平台。集团深耕冶金行业十余年，以帮助终端客户消化闲置库存、迅速回笼资金为切入点、致力于渗透全产业链、打造生态圈。以上海总部为核心，辐射全国，集团年交易额超20亿元。是专业的金属尾货交易平台。
        </span>
      </div>
      <img src="../assets/img/motherland/weiyiwangBg.png" alt="">
    </div>
    <div class="weiyiwangTips">
      <div>
        <p>
          尾货资源价值
        </p>
        <em></em>
        <span>
          尾货价值：金属可利用尾货使用广泛，低价可利用的尾货资源价值确定性高，可降低采购成本，适用低端产业链客户。
        </span>
      </div>
      <div>
        <p>
          采集分销
        </p>
        <em></em>
        <span>
          围绕行业上下游客户打造集采分销平台，帮助下游终端现货集采并链接上游钢厂现货资源分销，集合采购需求，降低采购成本。
        </span>
      </div>
      <div>
        <p>
          线上专家
        </p>
        <em></em>
        <span>
          打造冶金专家智库共享平台，集合全国范围内冶金行业专家，帮助客户个性化定制材料，从而提高性价比。
        </span>
      </div>
    </div>
    <div class="gangqiBox">
      <img src="../assets/img/motherland/gangqiBg.png" alt="">
      <div class="gangqiContent">
        <p>创新型服务性企业-钢旗</p>
        <span>
          上海世贝材料科技有限公司致力于关注并将最前沿科技材料转化为社会生产力之事业。我们专注于打造民族“钢旗”品牌，聚焦于服务锌铝镁系列产品！3镁11铝科技产品的防腐性能堪比不锈钢，如何快速推广并为客户创造价值从而实现节约社会资源。这个责任世贝敢于担当，“钢旗”品牌责无旁贷。期待与您合作共赢，共筑商祺！
        </span>
      </div>
    </div>
    <div class="cailiaoBox">
      <p>新材料研发联动</p>
      <em></em>
      <span>
        为打破应用风险、信息不对称壁垒从而阻碍了新材料行业创新之痛点。我们将整合不同行业对新材料的性能需求并汇总到钢企或相关研发机构。躬身于合理统筹研发、试生产、小批试用、批量使用等环节，使研发从需求到研发再到各个应用场景全面使用形成闭环。整合钢企与科研机构新材料的研发课题，打通未来的应用场景最终实现研发联动。
      </span>
      <div>
        <img src="../assets/img/motherland/newBg.png" alt="">
      </div>
    </div>
    <div class="xingkongBox">
      <p>新材料推广-星空联盟</p>
      <em></em>
      <span>
           通过整合钢厂研发科学家、品保工程师、成型及加工专家、终端客户的技术总监、应用工程师、行业内顶级设计院的设计师共同研讨，在技术层面彻底解决替代问题，并签署钢厂、鸿旗、终端客户三方战略合作协议，为新材料推广保驾护航进而形成营销闭环。
      </span>
      <div class="imgTipsBox">
        <div class="imgTips">
          <img src="../assets/img/motherland/tabBg1.png" alt="">
          <span>
            其与普通聚酯彩涂板相比，产品有更好的防腐性和耐久性。加工成型性优异，油漆质保期在15年上，满足中、高档建筑物的耐久性要求。钢旗技术人员针对业主厂房所处环境以及使用环境，选用合适的高端油漆类别，引入私人订制概念，连同锌铝镁基板，整体保年限在40年以上。
          </span>
          <p>钢旗3镁11铝锌铝镁彩涂产品<br/>SZAMPPG</p>
        </div>
        <div class="imgTips">
          <img src="../assets/img/motherland/tabBg2.png" alt="">
          <span>
            具有良好的稳定性、抗腐蚀性和机械性能 ，正常条件下油漆质保期在20年以上。钢旗技术人员针对业主厂房所处环境以及使用境，选用合适的高端油漆类别，引入私人订制概念，连同锌铝镁基板，整体质保年限在50以上。
          </span>
          <p>钢旗3镁11铝锌铝镁彩涂产品<br/>SZAMSTR</p>
        </div>
        <div class="imgTips">
          <img src="../assets/img/motherland/tabBg3.png" alt="">
          <span>
            为耐候、防腐等特殊需求制定的高标准产品。色牢度高、耐超高低温、耐酸碱、耐化学溶剂腐蚀。
          </span>
          <p>钢旗3镁11铝锌铝镁覆膜板产品<br/>SZAMAKO</p>
        </div>
        <div class="imgTips">
          <img src="../assets/img/motherland/tabBg4.png" alt="">
          <span>
            超耐蚀：是热浸镀锌钢板的10~20倍。<br/>自修复：切割面有自我修复的功能。<br/>长寿命：标准环境之下，质保达到30年上。<br/>易加工：冷弯、拉伸、冲压、焊接等加工性能优越。
          </span>
          <p>钢旗3镁11铝锌铝镁光板产品<br/>SZAMCHN</p>
        </div>
      </div>
      <b>*任意鸿旗集团战略协议合作伙伴均享推广需求权益均可发送邮件至钢旗官方邮箱：gangqi118@flagofsteel.com</b>
    </div>
    <div class="successBox">
      <p>钢旗品牌建设</p>
      <em></em>
      <span>
           通过整合钢厂研发科学家、品保工程师、成型及加工专家、终端客户的技术总监、应用工程师、行业内顶级设计院的设计师共同研讨，在技术层面彻底解决替代问题，并签署钢厂、<br/>品牌是口碑与信任，是产品力的呈现，是综合解决方案的实现，是贯穿整个供应链过程的暖心服务，是让客户及客户的客户产生崇高感的尊贵体验。<br/>我们致力于在独有的品牌建设模式下，将最好的产品推广到市场。让客户得到最大的实惠，让设计院始终代言最前沿科技材料，让制造商匠心得以呈现从而成就“钢旗”品牌！
      </span>
      <div>
        <img src="../assets/img/motherland/successBg1.png" class="successBg" alt="">
        <img src="../assets/img/motherland/successBg2.png" class="successBg" alt="">
<!--        <div class="successText">
          <p>成功案例</p>
          <span>
            2016年，酒钢率先把3镁11铝锌铝镁镀层产品实现了工业化生产，产品拥有超耐蚀、自修复、易加工、长寿命等性能特点，在某些应用场景可替代高镀层浸锌产品、铝合金产品、不锈钢产品等社会价值巨大。鸿旗通过立体式，综合营销方案把酒钢锌铝镁打造成为全国一流品牌，助力了新能量（光伏）等行业的发展，打开了多个应用场景推动了锌铝镁产品的应用普及，目前全国锌铝镁年产销超600万吨，节约了社会资源，实现了资源的优化配置。同时也增强了我们在其它新材料推广方面与贵司合作的信心。
          </span>
          <img src="../assets/img/motherland/logo.png" alt="">
        </div>-->
      </div>
    </div>
    <div class="powerBox">
      <p>新能源电力-双碳绿色环保</p>
      <em></em>
      <span>
        承天时行， 阳光生活。2020年9月，中国在第75届联合国大会提出2030年前碳达峰、2060年前碳中和目标。应国家碳达峰、碳中和、“3060目标”，鸿旗电力屋顶电站系统解决方案<br/>孕育而生，让光伏电力系统与建筑真正融为一体。屋顶光伏系统产生的电力一部分可以自用，多余部分回网卖给电力公司，<br/>从而实现屋顶“零碳建造”，电力“绿色生产”， 助力“碳达峰，碳中和”的国家双碳战略，成为为绿色环保做出实质性贡献的公司，让地球返老还童。
      </span>
      <div>
        <img src="../assets/img/motherland/powerBg.png" alt="">
        <div class="rightBox">
          <div class="cardBox">
            <div class="card">
              <p>光伏车棚</p>
              <span>以光伏板作为车棚顶板，集功能与环保于一体，保护车辆的同时可源源不断生产绿色电力。</span>
            </div>
            <div class="card">
              <p>光伏幕墙</p>
              <span>以光伏组件作为幕墙材料，美观大气兼具发电功能，让绿色建筑跨上新的台阶。</span>
            </div>
          </div>
          <div class="cardBox">
            <div class="card">
              <p>光伏电站运营维护</p>
              <span>为电站带来专业的运营维护服务，保证光伏发电系统安全、经济、高效运行，让业主收益最大化。</span>
            </div>
            <div class="card">
              <p>分布式屋顶电站</p>
              <span>因地制宜，利用现有屋面建设光伏电站，自发自用，余电上网多种选择。</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="zhizhaoBox">
      <p>制造业赋能-助力中国式现代化建设实现</p>
      <em></em>
      <span>
        鸿旗集团的整体经营战略和企业文化建设在党建引领下也有序展开，通过全员参与、广泛征集、整体决策的鸿旗集团战略大会，制定了“五星鸿旗”“三位一体”“员工幸福感”等战略方针
及企业文化方针。突出思想上要“利他”，业绩上要“突破”，责任上要“担当”三个层面实事求的行动方针，打造员工有归属、客户有期待、伙伴有信赖的新型服务型企业，努力成为行业
内可堪大用能担重任的栋梁企业。
      </span>
      <div>
        <img src="../assets/img/motherland/kjBg.png" alt="">
      </div>
    </div>
    <div class="footerTips">
      <p>
        鸿旗集团，涵盖产业互联网、尾货处置、产业供应链、新材料研究、金属材料采购服务、贸易全价值链，将最好的产品推广到市场，<br/>
        让客户得到最大的实惠，让设计院始终代言最前沿科技材料，让制造商匠心得以呈现，<br/>
        积极落实“节约资源，绿色双碳”构建以客户为中心的中国钢铁营销中心，助力钢铁供应链服务创新推动行业服务升级。
      </p>
    </div>

    <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import jiandang from '../components/shehui/jiandang'
import timeLine from '../components/shehui/timeLine'
import cishan from '../components/shehui/cishan'
import qiyegxue from '../components/shehui/qiyegxue'
import dangyuan from '../components/shehui/dangyuan'
import hangye from '../components/xinwen/hangye'
import kehuzixun from '../components/xinwen/kehuzixun'
import jituandongt from '../components/xinwen/jituandongt'

import swiperDemo from "../components/shehui/swiperDemo";

export default {
  data(){
    return{
      heardersw:false,
      syindex:0,
      xwindex:0,
      timeLineList: [{
        timestamp: '2009-10-6',
        info: '与酒钢集团-碳钢薄板厂签订经销协议，获“锌铝镁产品一级代理商”。'
      },{
        timestamp: '2011-01',
        info: '与河北钢铁集团有限公司签署代理协议，获“一级代理商”'
      },{
        timestamp: '2014-01',
        info: '与尚兴（中国）签订代理授权协议，获一级代理商'
      },{
        timestamp: '2015-01',
        info: '与冠洲鼎鑫板材签署代理商授权书，获得“核心代理商”'
      },{
        timestamp: '2015-03',
        info: '与深圳华美板材签订经销协议，获“华美彩涂板特邀经销商”'
      },{
        timestamp: '2016-01',
        info: '与宝钢股份签署协议，成为宝山股份彩涂产品认证经销商'
      },{
        timestamp: '2018-01',
        info: '成为无锡中彩集团“战略用户”'
      },{
        timestamp: '2018-8',
        info: '万鸿国际作为理事单位全票通过选举当选为副会长单位'
      },{
        timestamp: '2018-12',
        info: '万鸿国际荣获酒钢集团“2018年度市场开拓贡献奖'
      },{
        timestamp: '2019-10',
        info: '世贝材料加入上海市金属结构行业协会 '
      },{
        timestamp: '2021-05',
        info: '荣获酒钢集团颁发“2020年度优质合作伙伴奖'
      },{
        timestamp: '2022-04',
        info: '与酒钢集团-碳钢薄板厂签订经销协议，获“锌铝镁产品一级代理商”。'
      }],
    }
  },
  mounted(){
    console.log(this.$store.state.currPage);

  },
  methods:{
    titlsclltitle(a){
      this.syindex = a
    },
    newsclltitle(a){
      this.xwindex = a
    },
    showhearder(){
      this.heardersw =! this.heardersw
    }
  },
  name: 'App',
  components: {
    Foot,
    titletop,
    jiandang,
    cishan,
    qiyegxue,
    dangyuan,
    hangye,
    kehuzixun,
    jituandongt,
    timeLine,
    swiperDemo
  }
}
</script>
<style lang="scss" scope>
.motherland {
  overflow-x: hidden;
  .backimg{
    // border: 1px solid red;
    height: 600px;
    background-image: url(../assets/img/dbanner.png);
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
  }
  .hearderimg{
    width: 29px;
    height: 21px;
    position: absolute;
    right: 50px;
    top: 20px;
  }
  .showhearder{
    padding: 50px ;
    padding-top: 100px;

    width: 345px;
    height: 608px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: white;
    z-index: 10;
    .heaitem{
      // border: 1px solid red;
      display: flex;
      align-items: center;
      margin: 30px 0;
      .heaitemtext{
        // font-size: 18px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .ullist{
      padding-left: 80px;
      .liitem{
        list-style: none;
        margin: 20px 0;
      }


    }
  }
  .liactive{
    color: rgb(230,82,86);
  }
  .backsmaimg{
    width: 36%;
    height: 300px;
    border-radius: 8px;
    background-image: url(../assets/img/bigbanhder.png);
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -100px;
    right: 220px;
    .posionband{
      position: absolute;
      // border: 1px solid red;
      left: 50px;
      top: 25px;
      color: white;
      .posionbando{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 74px;
      }
      .posionbandt{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        margin-top: 20px;
      }
    }
  }
  .optionbander{
    position: absolute;
    top: 239px;
    left: 232px;
    color: white;
    .bigsix{
      font-size: 60px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 89px;
      letter-spacing: 12px;
    }
    .smallsix{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 27px;
      letter-spacing: 6px;
      margin-top: 20px;
    }
  }
  .bander{
    width: 100%;
  }
  .banderdsd{
    // width: 700px;
    margin-left: 797px;
    margin-top: -173px;
  }
}
.weiyiwangBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 160px;
  margin-top: 238px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .weiyiwangContent {
    p {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
      margin-bottom: 30px;
    }
    span {
      display: block;
      width: 738px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 60px;
    }
  }
  img {
    width: 800px;
    height: 440px;
    box-sizing: border-box;
    display: block;
  }
}
.weiyiwangTips {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 160px;
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
  align-items: center;
  div {
    width: 400px;
    p {
      font-size: 22px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #C82227;
      line-height: 30px;
    }
    em {
      display: block;
      width: 100%;
      height: 1px;
      background: #979797;
      margin-top: 8px;
      margin-bottom: 29px;
    }
    span {
      font-size: 18px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #666666;
      line-height: 30px;
    }
  }
}
.gangqiBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 160px;
  margin-top: 97px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 800px;
    height: 440px;
    box-sizing: border-box;
    display: block;
  }
  .gangqiContent {
    text-align: right;
    p {
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 45px;
      text-align: right;
    }
    span {
      display: block;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      width: 729px;
      color: #000000;
      line-height: 60px;
      text-align: right;
      margin-top: 30px;
    }
  }
}
.cailiaoBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 160px;
  margin-top: 97px;
  p {
    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 70px;
    text-align: center;
  }
  em {
    display: block;
    width: 100%;
    height: 1px;
    background: #979797;
    margin-top: 8px;
    margin-bottom: 22px;
  }
  span {
    display: block;
    box-sizing: border-box;
    padding: 0 57px 0 100px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 30px;
    margin-bottom: 43px;
  }
  div {
    width: 100%;
    display: flex;
    align-items: center;
    img {
      width: 575px;
      height: 386px;
      margin: 0 auto;
    }
  }
}
.xingkongBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 160px;
  margin-top: 61px;
  p {
    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 70px;
    text-align: center;
  }
  em {
    display: block;
    width: 100%;
    height: 1px;
    background: #979797;
    margin-top: 8px;
    margin-bottom: 22px;
  }
  span {
    display: block;
    box-sizing: border-box;
    padding: 0 57px 0 100px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 30px;
    margin-bottom: 43px;
  }
  .imgTipsBox {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .imgTips {
      width: 351px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      span {
        padding: 0;
        text-align: left;
        height: 175px;
        margin-top: 22px;
        margin-bottom: 0;
        font-size: 18px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #666666;
        line-height: 28px;
      }
      p {
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        margin-top: 22px;
        text-align: left;
      }
    }
  }
  b {
    display: block;
    width: 100%;
    text-align: right;
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: bold;
    color: #C82227;
    line-height: 30px;
    margin-top: 60px;
  }
}
.successBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 160px;
  margin-top: 113px;
  p {
    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 70px;
    text-align: center;
  }
  em {
    display: block;
    width: 100%;
    height: 1px;
    background: #979797;
    margin-top: 8px;
    margin-bottom: 22px;
  }
  span {
    display: block;
    box-sizing: border-box;
    padding: 0 57px 0 100px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 30px;
    margin-bottom: 43px;
  }
  div {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    padding-left: 286px;
    align-items: center;
    .successBg {
      width: 460px;
      height: 500px;
      margin-right: 116px;
    }
    .successText {
      width: 460px;
      height: 500px;
      box-sizing: border-box;
      padding: 33px 38px 0 45px;
      background-image: url(../assets/img/motherland/successBg.png);
      background-position: 100%;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      p {
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: bold;
        color: #C82227;
        line-height: 25px;
      }
      span {
        font-size: 17px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #000000;
        line-height: 30px;
        padding: 0;
        text-align: left;
        margin-top: 27px;
        margin-bottom: 13px;
      }
      img {
        width: 215px;
        height: 45px;
      }
    }
  }
}
.powerBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 160px;
  margin-top: 134px;
  p {
    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 70px;
    text-align: center;
  }
  em {
    display: block;
    width: 100%;
    height: 1px;
    background: #979797;
    margin-top: 8px;
    margin-bottom: 22px;
  }
  span {
    display: block;
    box-sizing: border-box;
    padding: 0 57px 0 100px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 30px;
    margin-bottom: 110px;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .rightBox {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      .cardBox {
        width: 956px;
        .card {
          width: 458px;
          height: 190px;
          background: #FFFFFF;
          box-shadow: 0px 5px 9px 0px rgba(0,0,0,0.21), 0px 22px 23px 0px rgba(0,0,0,0.03);
          border-radius: 12px;
          box-sizing: border-box;
          padding: 29px 29px 0 33px;
          flex-direction: column;
          p {
            font-size: 32px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 45px;
            text-align: left;
          }
          span {
            font-size: 18px;
            font-family: PingFangSC-Light, PingFang SC;
            font-weight: 300;
            color: #666666;
            line-height: 25px;
            padding: 0;
            text-align: left;
            margin-top: 24px;
          }
        }
      }
      .cardBox:nth-child(2) {
        margin-top: 40px;
      }
    }
  }
}
.zhizhaoBox {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 160px;
  margin-top: 97px;
  p {
    font-size: 50px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 70px;
    text-align: center;
  }
  em {
    display: block;
    width: 100%;
    height: 1px;
    background: #979797;
    margin-top: 8px;
    margin-bottom: 22px;
  }
  span {
    display: block;
    box-sizing: border-box;
    padding: 0 57px 0 100px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 30px;
    margin-bottom: 25px;
  }
  div {
    width: 100%;
    img {
      display: block;
      margin: 0 auto;
    }
  }
}
.footerTips {
  width: 100%;
  height: 313px;
  background-image: url(../assets/img/motherland/footerBg.png);
  background-position: 100%;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 82px 0 51px 20px;
  margin-top: 20px;
  p {
    font-size: 28px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 60px;
    text-align: center;
  }
}
</style>