<template>
  <div class="subordinate">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
      <div v-if="heardersw">
        <titletop />
      </div>
      <div class="optionbander">
        <div class="bigsix">信仰铸忠诚  永远跟党走</div>
        <div class="smallsix" >坚信一切美好都要共同守护</div>
      </div>
      <div class="backsmaimg">
        <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
        <div class="posionband">
          <div class="posionbando">社会责任/新闻资讯</div>
          <div class="posionbandt" >让社会尊重</div>
        </div>
      </div>
    </div>

    <div class="shehuiceon">
      <p class="centitle">社会责任</p>
      <div class="titlscll">
        <p  :class="this.syindex == 0 ? 'scllitem scllactive' : 'scllitem'" @click="titlsclltitle(0)">党建活动</p>
        <p  :class="this.syindex == 1 ? 'scllitem scllactive' : 'scllitem'" @click="titlsclltitle(1)">党员形象</p>
        <p  :class="this.syindex == 2 ? 'scllitem scllactive' : 'scllitem'" @click="titlsclltitle(2)">公司荣誉</p>
        <p  :class="this.syindex == 3 ? 'scllitem scllactive' : 'scllitem'" @click="titlsclltitle(3)">慈善大爱</p>
        <p  :class="this.syindex == 4 ? 'scllitem scllactive' : 'scllitem'" @click="titlsclltitle(4)">企业共学</p>
      </div>
      <div v-if="this.syindex == 0">
        <jiandang />
      </div>
      <div v-if="this.syindex == 1">
        <dangyuan />
      </div>
      <div v-if="this.syindex == 2">
        <timeLine :timeLineList = "timeLineList" />
      </div>
      <div v-if="this.syindex == 3">
        <cishan />
      </div>
      <div v-if="this.syindex == 4">
        <qiyegxue />
      </div>
    </div>



    <div class="shehuiceon xinwentitle">
      <p class="centitle">新闻咨询</p>
      <div class="titlscll xinwen">
        <p class=""  :class="this.xwindex == 0 ? 'scllitem scllactive' : 'scllitem'" @click="newsclltitle(0)">行业咨询</p>
        <p  :class="this.xwindex == 1 ? 'scllitem scllactive' : 'scllitem'" @click="newsclltitle(1)">客户咨询</p>
        <p  :class="this.xwindex == 2 ? 'scllitem scllactive' : 'scllitem'" @click="newsclltitle(2)">集团动态</p>
      </div>
      <div v-if="this.xwindex == 0">
        <hangye />
      </div>
      <div v-if="this.xwindex == 1">
        <kehuzixun />
      </div>
      <div v-if="this.xwindex == 2">
        <jituandongt />
      </div>
    </div>


    <!-- <div class="shehuiceon">
        <swiperDemo></swiperDemo>
    </div> -->


    <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import jiandang from '../components/shehui/jiandang'
import timeLine from '../components/shehui/timeLine'
import cishan from '../components/shehui/cishan'
import qiyegxue from '../components/shehui/qiyegxue'
import dangyuan from '../components/shehui/dangyuan'
import hangye from '../components/xinwen/hangye'
import kehuzixun from '../components/xinwen/kehuzixun'
import jituandongt from '../components/xinwen/jituandongt'

import swiperDemo from "../components/shehui/swiperDemo";

export default {
  data(){
    return{
      heardersw:false,
      syindex:0,
      xwindex:0,
      timeLineList: [{
        timestamp: '2009-10-6',
        info: '与酒钢集团-碳钢薄板厂签订经销协议，获“锌铝镁产品一级代理商”。'
      },{
        timestamp: '2011-01',
        info: '与河北钢铁集团有限公司签署代理协议，获“一级代理商”'
      },{
        timestamp: '2014-01',
        info: '与尚兴（中国）签订代理授权协议，获一级代理商'
      },{
        timestamp: '2015-01',
        info: '与冠洲鼎鑫板材签署代理商授权书，获得“核心代理商”'
      },{
        timestamp: '2015-03',
        info: '与深圳华美板材签订经销协议，获“华美彩涂板特邀经销商”'
      },{
        timestamp: '2016-01',
        info: '与宝钢股份签署协议，成为宝山股份彩涂产品认证经销商'
      },{
        timestamp: '2018-01',
        info: '成为无锡中彩集团“战略用户”'
      },{
        timestamp: '2018-8',
        info: '万鸿国际作为理事单位全票通过选举当选为副会长单位'
      },{
        timestamp: '2018-12',
        info: '万鸿国际荣获酒钢集团“2018年度市场开拓贡献奖'
      },{
        timestamp: '2019-10',
        info: '世贝材料加入上海市金属结构行业协会 '
      },{
        timestamp: '2021-05',
        info: '荣获酒钢集团颁发“2020年度优质合作伙伴奖'
      },{
        timestamp: '2022-04',
        info: '与酒钢集团-碳钢薄板厂签订经销协议，获“锌铝镁产品一级代理商”。'
      }],
    }
  },
  mounted(){
    console.log(this.$store.state.currPage);

  },
  methods:{
    titlsclltitle(a){
      this.syindex = a
    },
    newsclltitle(a){
      this.xwindex = a
    },
    showhearder(){
      this.heardersw =! this.heardersw
    }
  },
  name: 'App',
  components: {
    Foot,
    titletop,
    jiandang,
    cishan,
    qiyegxue,
    dangyuan,
    hangye,
    kehuzixun,
    jituandongt,
    timeLine,
    swiperDemo
  }
}
</script>
<style lang="scss" scope>
.shehuiceon{
  text-align: center;
  margin: 300px auto;
  max-width: 1600px;
  // border: 1px solid red;
  .centitle{
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0;
    font-size: 46px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 70px;

  }
  .titlscll{
    background-color: rgb(245,245,245);
    width: 1000px;

    height: 50px;
    margin:  0 auto;
    display: flex;
    // justify-content: ;
    align-items: center;
    border-radius: 66px;
    .scllitem{
      border-radius: 66px;
      width: 200px;
      height: 50px;
      background: rgb(245,245,245);

      line-height: 50px;
      padding: 0 10px;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #DDDDDD;
    }
    .scllactive{
      background: rgb(200,36,41);
      color:white ;
      border-radius: 66px;
    }

  }
  .xinwen{
    width: 600px;
  }
  .xinwenimgclick{
    width: 180px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .scllboom{
    display: flex;
    max-width:800px;
    margin:50px auto;
    height: 400px;
    // border: 1px solid red;
    .titleftlscll{
      // border: 1px solid red;
      .titleimg{
        // border: 1px solid red;
        width: 400px;
        height: 278px;
      }
      .imgclick{
        width: 100px ;
        // border: 1px solid red;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;
      }
    }
    .titrighttlscll{
      padding: 10px 20px;
      width: 400px;
      .rightop{
        .rightopone{
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid rgb(200,36,41);
        }
      }
      .righboom{
        text-align: left;
        margin-top: 50px;
        color: #aaa;
      }
    }
  }

}
.xinwentitle{
  margin-top: -100px;
}
.animationone{
  animation:1s fadeInUp;
}
.animationtwo{
  animation:3s fadeInUp;
}
.animationthree{
  animation:4s fadeInUp;
}
.bianzu{
  background-image: url(../assets/img/bianzu.png);
  height: 245px;
  max-width: 1574px;
  margin: 0 auto;
  background-position: 100%;
  background-size: 100% 100%;
  position: relative;
  .bzitemo{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: -26px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: -12px;
      width: 59px;
      height: 59px;
    }
  }
  .gyinganquan{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: 1000px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: 1015px;
      width: 59px;
      height: 59px;
    }
  }
  .clkyan{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: 1339px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: 1358px;
      width: 59px;
      height: 59px;
    }
  }
  .ksyingy{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      right: -25px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      right: -14px;
      width: 59px;
      height: 59px;
    }
  }
  .shhbz{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      left: 1172px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      left: 1182px;
      width: 59px;
      height: 59px;
    }
  }
  .gyljr{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      left: 830px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      left: 842px;
      width: 59px;
      height: 59px;
    }
  }
  .bzitemt{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      left: 154px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      left: 158px;
      width: 59px;
      height: 59px;
    }
  }
  .bzitemzj{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: 655px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: 672px;
      width: 59px;
      height: 59px;
    }

  }
  .bzitemf{
    position: relative;
    .bztext{
      position: absolute;
      top: 158px;
      left: 490px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top:-59px;
      left: 499px;
      width: 59px;
      height: 59px;
    }
  }
  .bzitemth{
    position: relative;
    .bztext{
      position: absolute;
      top: 60px;
      left: 314px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
    }
    .bianzuimg{
      position: absolute;
      top: 243px;
      left: 329px;
      width: 59px;
      height: 59px;
    }
  }
}
.xingkong{
  width: 100%;
  display: flex;
  margin: 200px auto;
  // justify-content: space-between;
  align-items: center;
  // padding: 200px;
  height: 700px;
  box-sizing: border-box;
  .xkleft{
    width: 50%;
    //  border: 1px solid red;
    // margin-left: 50px;
    text-align: right;
    margin-right: 60px;
    .xkone{
      font-size: 120px;
      font-family: LiGothicMed;
      color: #F2F2F2;
      line-height: 180px;
      letter-spacing: 12px;
    }
    .xktwo{
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 45px;
    }
    .xkthree{
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 45px;
    }
  }
  .xkright{
    // border: 1px solid red;
    background-image: url(../assets/img/xklm.png);
    height: 600px;
    width: 820px;
    background-position: 100%;
    background-size: 100% 100%;
  }
}
.subordinate{
  overflow: hidden;
  .backimg{
    // border: 1px solid red;
    height: 600px;
    background-image: url(../assets/img/dbanner.png);
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
  }
  .hearderimg{
    width: 29px;
    height: 21px;
    position: absolute;
    right: 50px;
    top: 20px;
  }
  .showhearder{
    padding: 50px ;
    padding-top: 100px;

    width: 345px;
    height: 608px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: white;
    z-index: 10;
    .heaitem{
      // border: 1px solid red;
      display: flex;
      align-items: center;
      margin: 30px 0;
      .heaitemtext{
        // font-size: 18px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
    .ullist{
      padding-left: 80px;
      .liitem{
        list-style: none;
        margin: 20px 0;
      }


    }
  }
  .liactive{
    color: rgb(230,82,86);
  }
  .backsmaimg{
    width: 36%;
    height: 300px;
    border-radius: 8px;
    background-image: url(../assets/img/bigbanhder.png);
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -100px;
    right: 220px;
    .posionband{
      position: absolute;
      // border: 1px solid red;
      left: 100px;
      top: 80px;
      color: white;
      .posionbando{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 74px;
      }
      .posionbandt{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        margin-top: 20px;
      }
    }
  }
  .optionbander{
    position: absolute;
    top: 239px;
    left: 232px;
    color: white;
    .bigsix{
      font-size: 60px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 89px;
      letter-spacing: 12px;
    }
    .smallsix{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 27px;
      letter-spacing: 6px;
      margin-top: 20px;
    }
  }
  .bander{
    width: 100%;
  }
  .banderdsd{
    // width: 700px;
    margin-left: 797px;
    margin-top: -173px;
  }
}
.checkgongyi{
  max-width: 1600px;
  margin: 200px auto;
  .chacktitle{
    .chacktop{
      font-size: 50px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #000000;
      line-height: 70px;
      letter-spacing: 5px;
    }
    .chackboom{
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 30px;
      letter-spacing: 2px;
    }
  }
  .chacicenter{
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 100px 50px;
    .chacitem{
      .chacitemimg{
        width: 120px;
        height: 120px;
      }
      .chacitemtext{
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 30px;
        letter-spacing: 1px;
      }
    }
  }
  .ckyhrback{
    background-image: url(../assets/img/vbuanju.png);
    height: 700px;
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
    color: white;
    .ckpotion{
      position: absolute;
      top: 50px;
      left: 40px;
      .ckpotop{
        font-size: 80px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 112px;
        letter-spacing: 5px;
        margin-bottom: 50px;
      }
      .ckpoboom{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 70px;
      }
    }
  }
}
</style>