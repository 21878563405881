<template>
  <div class="weiyiw">
    <div class="backimg">
        <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
       <div v-if="heardersw">
        <titletop />
       </div>
      <div class="optionbander">
        <div class="bigsix">专业的金属尾货交易平台</div>
        <div class="smallsix">万企物联-彼此成就</div>
      </div>
      <div class="backsmaimg">
       <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
       <div class="posionband">
        <div class="posionbando">上海尾易信息网络
</div>
<div class="posionbando">
服务有限公司</div>
        <div class="posionbandt" >鸿旗企业/企业简介/下属公司</div>
      </div>
     </div>
      </div>


      <div style="max-width: 1980px; margin: 100px auto">
        <div  style="
          margin-top: 200px;
        display: flex;
        position: relative;
        ">
          <img src="../assets/img/weiyiw/aboutUS.png" style="width: 358px;height: 600px;z-index: 15;position: absolute;top: 107px;left: 157px" alt="">
          <img style="z-index: 10;" src="../assets/img/smswy.png" alt="">
          <div class="aboutUsBox">
            <p>关于我们</p>
            <span>
              上海尾易网络信息服务有限公司尾易网络致力于推动和引领整个冶
金行业改革与发展，将拥抱互联网面向全球金属行业，打造金属尾
货交易平台、冶金行业专家共享平台和冶金行业权威信息发布平台。
            </span>
            <b>业务联系：400-600-8891</b>
          </div>

      </div>
 </div>

      <div class="serviceBox">
        <img src="../assets/img/weiyiw/Service.png" style="margin-left: 161px" alt="">
        <div class="contentBox">
          <img src="../assets/img/weiyiw/advantage.png" alt="">
          <p>
            尾易网秉持“万企物联，彼此成就”的经营理念,以互联网、大数据为工具，为行业提供了一站式的解决方案提供包括平台交易、B2B线上支付、专家指导、信息咨询等服务帮助行业客户提升效率、实现增长，为用户提供更好的商业体验钢厂集采分销降低采购成本，对接冶金专家高端定制材料。
          </p>
          <span>进入尾易网</span>
        </div>
        <img src="../assets/img/weiyiw/serviceBg.png" class="serviceBg" alt="">
        <img src="../assets/img/weiyiw/downS.png" class="downS" alt="">
      </div>

    <div>
      <swiperCoverflow
      :img-list="imgList"
      :slide-index="initialSlide"
      ref="swiperFlow"
      />
    </div>
    


   
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import swiperCoverflow from '../components/shehui/swiperCoverflow.vue'
import swiper from "@/common/amount/swiper";
export default {
data(){
 return{
  heardersw:false,
   imgList: [
     {
       src:require("../assets/img/weiyiw/swiper.png")
     },
     {
       src:require("../assets/img/weiyiw/swiper2.png")
     },
     {
       src:require("../assets/img/weiyiw/swiper3.png")
     },
     {
       src:require("../assets/img/weiyiw/swiper4.png")
     },
   ],
   initialSlide: 1
 }
},
mounted(){
 console.log(this.$store.state.currPage);
 this.$refs.swiperFlow.showFlag = true;
},
methods:{
  swiper,

  showhearder(){
    this.heardersw =! this.heardersw
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
  swiperCoverflow
 
}
}
</script>
<style lang="scss" scope>


.animationone{
  animation:1s fadeInUp;
  }
  .animationtwo{
  animation:3s fadeInUp;
  }
   .animationthree{
  animation:4s fadeInUp;
  }


.weiyiw{
  overflow-x: hidden;
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/weiyibanner.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
  border-radius: 8px;
background-image: url(../assets/img/weiyism.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 52px;
  color: white;
  .posionbando{
    font-size: 50px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 74px;
  }
  .posionbandt{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
margin-top: 20px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 232px;
  color: white;
  .bigsix{
    font-size: 60px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 89px;
letter-spacing: 12px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}


}
.aboutUsBox {
  width: 1399px;
  height: 739px;
  margin-left: -400px;
  box-sizing: border-box;
  padding: 75px 0 0 530px;
  background-image: url(../assets/img/weihei.png);
  background-position: 100%;
  background-repeat: no-repeat;
  p {
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 45px;
  }
  span {
    display: block;
    width: 660px;
    margin-top: 72px;
    margin-bottom: 160px;
    font-size: 22px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #FFFFFF;
    line-height: 60px;
  }
  b {
    display: block;
    width: 307px;
    height: 50px;
    background: linear-gradient(90deg, #EF0C15 0%, rgba(160,4,14,0) 100%);
    border-radius: 4px;
    text-align: center;
    line-height: 50px;
    font-size: 26px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #FFFFFF;
  }
}

.serviceBox {
  margin-top: 326px;
  box-sizing: border-box;
  padding: 0 160px 0 0;
  position: relative;
  margin-bottom: 168px;
  .contentBox {
    width: 1243px;
    height: 567px;
    box-sizing: border-box;
    padding: 21px 0 0 164px;
    background-image: url(../assets/img/weiyiw/serviceTextBg.png);
    background-position: 100%;
    background-repeat: no-repeat;
    p {
      display: block;
      width: 680px;
      font-size: 22px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #000000;
      line-height: 60px;
      letter-spacing: 2px;
      margin-top: 10px;
    }
    span {
      display: block;
      width: 180px;
      height: 50px;
      background: #C12229;
      border-radius: 4px;
      text-align: center;
      line-height: 50px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      margin-top: 18px;
    }
  }
  .serviceBg {
    position: absolute;
    top: -78px;
    left: 872px;
  }
  .downS {
    position: absolute;
    right: 160px;
    bottom: 0;
  }
}
</style>