<template>
  <div class="indexcenter">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
        <div v-if="heardersw">
        <titletop />
       </div>
    <div class="optionbander">
      <div class="bigsix">保持奋斗姿态 证明生命存在</div>
      <div class="smallsix" >携手并进-共同成长</div>
    </div>
    <div class="backsmaimg">
     <div class="posionband">
      <div class="posionbando">员工成长历程</div>
      <div class="posionbandt" >让员工幸福</div>
    </div>
   </div>
    </div>

    <div class="banitem">
      <p class="banitemtitle">
        员工案例
      </p>
      <div class="peoinfo">
     <div style="position: relative;">
     <img src="../assets/img/guhaifeng.png" alt="">
     <div class="peoinfooption"> 
     <div > <span class="potiontop">顾海松</span> <span class="potionceo">-上海万鸿国际业务一部经理</span></div>
     <p class="potionceo" style="margin: 16px 0;">2014年入职</p>
     <p class="potionboom">宽以待人，严以律己</p>
     </div>
     </div> 
     <div class="peoinfor">
    <div class="infotop">
       <div class="infotopleft">
            1
       </div>
       <div class="infotopright">
        宽以待人，严以律己
       </div>
    </div>
    <div class="infobom">
      2012年毕业，经历了两年环球航行，2014年加入万鸿国际，从年接单量2600吨/年到现在年接单50000余吨，2017年、2020年获得集团公司金牌业务奖、2021年获得集团公司“五一英雄奖”银奖和年度“个人业绩贡献最大”奖，2022年第二季度业务突出贡献奖，为超过53家客户提供优质的产品，助力光伏行业实现新材料的替代和推广。
    </div>
     </div>
    </div>

      <div class="peoinfo">
     <div class="peoinfor">
    <div class="infotop">
       <div class="infotopright nomagin">
        顾海松-战略十年
       </div>
    </div>
    <div class="infobom">
      <p>十年画卷：成为一个对社会有贡献的人。</p>
      <p> 人生哲学：宽以待人，严以律己。</p>
      <p>保障机制：持之以恒的学习才是生活的原动力。 </p>
      <p>战略主线：成功的团队成就每一人，失败的团队没有成功者。</p>
    </div>
     </div>
     <div >
     <img style="margin-top: 80px;" src="../assets/img/ghfzl.png" alt="">
     </div> 
    </div>

      <div class="peoinfo">
     <div style="position: relative;">
      <img style="margin-top: 80px;" src="../assets/img/ghfpy.png" alt="">
    
     </div> 
     <div class="peoinfor pingyu">
    <div class="infotophuh" style="margin-bottom: 10px">
      董事长-皇平评语：
    </div>
    <div class="infobomhuhu">
      顾海松同志对自己有严格的要求，是一位有梦想的优秀同仁，且对自己的梦想永不放弃，持之以恒。 相信一定可以在鸿旗集团收获最幸福的精彩人生。
    </div>
    <div class="infotophuh" style="margin: 20px 0">
      万鸿总经理-胡晓军评语：
    </div>
    <div class="infobomhuhu">
      年入百万如今已算不上梦想，应有更高的追求。脚踏实地，向更高的目标进发。
    </div>
    <div class="infotophuh" style="margin: 20px 0">
      客户（王总）评语：
    </div>
    <div class="infobomhuhu">
      除了丰富的知识和可靠的判断外，勇气便是你最宝贵的财富，继续鼓足你的勇气，不断颠覆自己，
迎接新的挑战。
    </div>
     </div>
    </div>

      <div class="peoinfo">
     
     <div class="peoinfor">
    <div class="infotop">
       <div class="infotopleft">
            2
       </div>
       <div class="infotopright">
        时刻保持空杯心态，谦虚做人，认真做事，一步一个脚印。
       </div>
    </div>
    <div class="infobom">
      2015年进入钢贸行业，蛰伏两年，平均一年拜访300余家客户，不是在客户那里就是在去客户的路上，全力以赴，去做好每一件事，去对待每一份工作，打一场战胜自己的战役，真正实现自己的价值，成为孩子的榜样。努力尝试，时间可以告诉我们答案，2017年第三季度最佳新人奖、2018年第一季度最佳新人奖、2019年第一季度精卫填海奖、2021年年度金牌业务奖，这一路走来有伙伴们协助，有领导们的鼎力相助，让我成为更好地自己，利益到更多的客户。
    </div>
     </div>
     <div style="position: relative;">
     <img src="../assets/img/zhxjs.png" alt="">
     <div class="peoinfooption"> 
     <div > <span class="potiontop">张凤霞</span> <span class="potionceo">-上海万鸿国际业务一部业务员</span></div>
     <p class="potionceo" style="margin: 16px 0;">2015年入职</p>
     <p class="potionboom">时刻保持空杯心态，谦虚做人，认真做事，一步一个脚印。</p>
     </div>
     </div> 
    </div>

      <div class="peoinfo">
  
     <img style="margin-top: 80px;" src="../assets/img/zhxzl.png" alt="">
 
     <div class="peoinfor">
    <div class="infotop">
       <div class="infotopright nomagin">
        张凤霞-战略十年
       </div>
    </div>
    <div class="infobom">
      <p>十年画卷：立志成为一个乐观热情积极向上的人！敢愿用心赢得尊敬服务好每一位客户成</p>
      <p>就他人。</p>
      <p>人生哲学：时刻保持空杯心态，谦虚做人，认真做事，一步一个脚印，真诚对他人好。</p>
      <p> 保障机制：认真学习中华传统文化，把中华文化传播更多人利益他人加强自我积累。</p>
      <p>  战略主线：成就彼此，成就自己。</p>
    </div>
     </div>
    
    </div>

      <div class="peoinfo">
    
     <div class="peoinfor pingyu">
    <div class="infotophuh" style="margin-bottom: 10px">
      董事长-皇平评语：
    </div>
    <div class="infobomhuhu">
      张凤霞同志是非常勤奋的小蜜蜂，是业务员的榜样，以勤奋书写青春，必将以勤奋收获人生，在家是子女的榜样在公司是大家学习的榜样！继续努力，一定可以在鸿旗集团收获值得荣耀的人生。
    </div>
   
    <div class="infotophuh" style="margin: 10px 0">
      万鸿总经理-胡晓军评语：
    </div>
    <div class="infobomhuhu">
      每天付出不亚于任何人的努力，勤劳是战胜一切困难的法宝。不忘初心，蝉联销冠。

    </div>
    <div class="infotophuh" style="margin: 10px 0">
      客户（夏总）评语：
    </div>
    <div class="infobomhuhu">
      独特的人格魅力，专业的业务水平，快捷的反应速度，能为客户解决问题，利益客户给客户带来
愉悦的合作体验。
    </div>
     </div>
     <div style="position: relative;">
      <img style="margin-top: 80px;" src="../assets/img/zhxpy.png" alt="">
    
     </div> 
    </div>
   
      <div class="ljiehuoj">
      历届员工获奖展示
     </div>
    </div>
    <div>
      <swiperCoverflow
          :img-list="imgList"
          :slide-index="initialSlide"
      />
    </div>
    

 


   
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import swiperCoverflow from '../components/shehui/swiperCoverflow.vue'
export default {
data(){
 return{
  heardersw:false,
   imgList: [
     {
       src:require("../assets/img/employeegrowth/bg1.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg2.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg3.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg4.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg5.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg6.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg7.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg8.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg9.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg10.png")
     },
     {
       src:require("../assets/img/employeegrowth/bg11.png")
     }
   ],
   initialSlide: 4
 }
},
mounted(){
 console.log(this.$store.state.currPage);

},
methods:{
  addaply(){
    this.$router.push({ path: '/positioninfo'});
  },
  showhearder(){
    this.heardersw =! this.heardersw
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
  swiperCoverflow
}
}
</script>
<style lang="scss" scope>
.ljiehuoj{
  margin: 100px auto;
  max-width: 1600px;
  font-size: 24px;
  font-weight: 500;
}
.banitem{
  margin: 0 auto;
  margin-top: 180px;
  max-width: 1700px;
  .banitemtitle{
    width: 128px;
height: 48px;
font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 47px;
padding-bottom: 10px ;
  border-bottom: 6px solid rgb(200,34,39);
  margin-bottom: 50px;
  }
  .peoinfo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 100px 0;
    .peoinfooption{
      position: absolute;
      bottom:80px;
      left: 50px;
     .potiontop{
      font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 45px;

     }
     .potionceo{
      font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #666666;
line-height: 37px;

     }
     .potionboom{
      font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 27px;
     }
    }
    // align-items: center;
    .peoinfor{
      width: 1035px;
      // border: 1px solid red;
      margin-left: 60px;
      height: 640px;
     .infotophuh{
      font-size: 26px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 40px;
     }
     .infobomhuhu{
     
      font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 50px;
}
    }
    .pingyu{
      width: 962px;
height: 340px;
    }
    .infotop{
      display: flex;
    // border: 1px solid red;
 
      .infotopleft{
        font-size: 72px;
font-family: STSongti-SC-Light, STSongti-SC;
font-weight: 300;
color: #C82227;
line-height: 100px;
padding-left: 26px;
padding-top: 10px;
box-sizing: border-box;
        width: 127px;
        height: 124px;
        background-image: url(../assets/img/number.png);
        background-size: 100% 100%;
      }
      .infotopright{
      padding-top:80px ;
      box-sizing: border-box;
      margin-left: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
// line-height: 27px;
font-size: 32px;
        // text-align: end;
      }
      .nomagin{
        margin-left: 0px;
      }
    }
  
    .infobom{
      width: 962px;
// height: 340px;
font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 68px;
margin-top: 100px;
    }
  }

}


.indexcenter{
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/yuanbander.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
  border-radius: 8px;
background-image: url(../assets/img/yuantop.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 80px;
  color: white;
  .posionbando{
    font-size: 50px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 74px;
  }
  .posionbandt{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
margin-top: 20px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 14%;
  color: #C83434;
  .bigsix{
    font-size: 42px;
font-family: LiSungLight;
color: #C83434;
line-height: 42px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #C83434;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}


}

</style>