<template>
  <div class="familyhapp">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
        <div v-if="heardersw">
        <titletop />
       </div>
    <div class="optionbander">
      <div class="bigsix">创建和谐共生</div>
      <div class="smallsix" >感恩客户-福泽员工-照顾长辈</div>
    </div>
    <div class="backsmaimg">
     <div class="posionband">
      <div class="posionbando">幸福建设/文体团建活动</div>
      <div class="posionbandt" >让员工幸福</div>
    </div>
   </div>
    </div>
    <div class="banitem">
    <p class="banitemtitle" style="width: 160px" id="xspanel">
      父母孝顺金
      </p>
      <div class="xiaoshun" style="padding: 80px 0;">
  <div class="left">
    <img src="../assets/img/xiaoshunjin.png" alt="">
  </div>
  <div class="right">
    <p class="righttitl">鸿旗集团坚持以人为本，以建设幸福家庭，并带领鸿旗</p>
    <p class="righttitl">人践行孝行，传播孝文化推动社会主义精神文明建设。</p>
    <img src="../assets/img/xiantiao.png" style="margin-bottom: 30px;margin-top: 30px;" alt="">
    <div class="righbom">
      <p class="righbomnum">
        <span  id="my-number"></span><span style="display:none;" id="jiahaoone">+</span>
      </p>
      <p class="righbomtext">
        “鸿旗孝顺基金”是万鸿国际在2013年设立的资金项目距今已有十余年。
      </p>
    </div>
    <div class="righbom">
      <p class="righbomnum">
        <span  id="my-numberbig"></span><span style="display:none;" id="jiahaotwo">+</span>
      </p>
      <p class="righbomtext">
        截止目前共有300+员工家庭享受过该政策。
      </p>
    </div>
  </div>
      </div>
      <div style="position: relative;" class="advanBg">
        <div style="background: white;z-index: 10000;width: 500px;height: 1008px;position: absolute;right: -500px;top: 0;"></div>
        <div id="stage_advan_Group" class="stage_advan_Group_id" >
          <div id="stage_advan_Group_copy" class="stage_advan_Group_id_copy" >
            <div id="stage_advan_yun2_02Copy" class="stage_advan_yun2_02Copy_id stage_advan lr1"></div>
            <div id="stage_advan_yun2_02" class="stage_advan_yun2_02_id stage_advan lr2"></div>
            <div id="stage_advan_yun2_03" class="stage_advan_yun2_02_id stage_advan lr3"></div>
            <div id="stage_advan_yun2_04" class="stage_advan_yun2_02_id stage_advan lr4" ></div>
            <div id="stage_advan_yun2_05" class="stage_advan_yun2_02_id stage_advan lr5" ></div>
            <div id="stage_advan_yun2_06" class="stage_advan_yun2_02_id stage_advan lr6"></div>
            <div id="stage_advan_yun2_07" class="stage_advan_yun2_02_id stage_advan lr7"></div>
            <div id="stage_advan_yun2_08" class="stage_advan_yun2_02_id stage_advan lr8"></div>
        </div>
      </div>
      <div style="background: white;z-index: 10000;width: 500px;height: 1008px;position: absolute;top: 0;left: -500px;"></div>
      <div style="background: white;z-index: 10000;width: 500px;height: 700px;"></div>
      </div>
      <div style="position: relative;top:180px;width: 100%;">
        <div style="position: relative">
          <img src="../assets/img/familyhapp/logoBox.png" style="display: block;margin: 0 auto" alt="">
          <img src="../assets/img/familyhapp/logo.png" style="display: block;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-50%)" alt="">
        </div>
        <img src="../assets/img/familyhapp/lineBox.png" style="display: block;margin: 0 auto" alt="">
      </div>
  </div>


  <div class="banitem banitemtop" style="overflow: hidden;margin-top: 320px;">
    <p class="banitemtitle">
      亲子活动
      </p>
      <div class="left">

        <familySwiper
            :img-list="imgArr"
            :slide-index="SlideIndex"
            ref="childEvent"
        />
      </div>
  </div>
    <div class="banitem banitemtop" style="margin-top: 100px">
      <p class="banitemtitle">
        员工团建
      </p>

      <div>
        <familySwipxuan2 />
      </div>
    </div>
 
   

   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import familySwiper from '../components/shehui/familySwiper.vue'
import familySwipxuan2 from '../components/shehui/familySwipxuan2.vue'
export default {
data(){
 return{
  heardersw:false,
  alreadyExecuted:false,
   showAdv: 1,
   showAdv1: false,
   showAdv2: false,
   showAdv3: 1,
   SlideIndex: '1',
   imgArr:[
     {
       src:require("../assets/img/qinzi/qinzi.png")
     },
     {
       src:require("../assets/img/qinzi/qinzi1.png")
     },
     {
       src:require("../assets/img/qinzi/qinzi2.png")
     },
     {
       src:require("../assets/img/qinzi/qinzi3.png")
     },
     {
       src:require("../assets/img/qinzi/qinzi4.png")
     },
     /*{
       src:require("../assets/img/qinzi/qinzi5.png")
     },
     {
       src:require("../assets/img/qinzi/qinzi6.png")
     },
     {
       src:require("../assets/img/qinzi/qinzi7.png")
     },
     {
       src:require("../assets/img/qinzi/qinzi8.png")
     },*/
   ]
 }
},
  computed: {},
created() {
  this.addShowAdv();
},
  mounted(){

  // this.addNumber(0, 10, 'my-number');
  // this.addNumber(0, 10, 'my-numberth');
  // this.addNumberbig(0, 300, 'my-numberbig');
  var that = this
  //    //判断到达底部
   window.onscroll = function(){

      //定义变量获取屏幕视口宽度
    let windowWidth = document.body.clientWidth;
    if(windowWidth>700){
       //变量scrollTop是滚动条滚动时，距离顶部的距离
      let winscrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      let xspanel = document.getElementById("xspanel").offsetTop;
      
      if(winscrollTop+300 >= xspanel){  
        if(!this.alreadyExecuted) {
          console.log(111);
          document.getElementById("jiahaoone").style.display = "inline-block";
          document.getElementById("jiahaotwo").style.display = "inline-block";
          document.getElementById("jiahaothree").style.display = "inline-block";
          that.addNumber(0, 10, 'my-number');
          that.addNumber(0, 10, 'my-numberth');
          that.addNumberbig(0, 300, 'my-numberbig');
   this.alreadyExecuted = true; 
}
         
      }
     
     
   
    
   
    }
  }
  
  },
methods:{
addNumber(start, end, id){
        var o = document.getElementById(id);
        var i = start;
        var Interval;
        if(i < end){
            Interval = setInterval(function(){
                i += 1; // 设置每次增加的动态数字，可调整
                if(i > end) {
                    clearInterval(Interval); // 清除setInterval的time，这个方法w3c可具体查看文档
                    o.innerHTML = end.toLocaleString(); // 此赋值是为了避免最后一次增加过后的数据和真实数据不同
                    i = 0;
                } else {
                    o.innerHTML = i.toLocaleString();
                }
            }, 100); // 数据跳转的速度控制
        }
    },
    addNumberbig(start, end, id){
        var o = document.getElementById(id);
        var i = start;
        var Interval;
        if(i < end){
            Interval = setInterval(function(){
                i += 2; // 设置每次增加的动态数字，可调整
                if(i > end) {
                    clearInterval(Interval); // 清除setInterval的time，这个方法w3c可具体查看文档
                    o.innerHTML = end.toLocaleString(); // 此赋值是为了避免最后一次增加过后的数据和真实数据不同
                    i = 0;
                } else {
                    o.innerHTML = i.toLocaleString();
                }
            }, 10); // 数据跳转的速度控制
        }
    },
  addaply(){
    this.$router.push({ path: '/positioninfo'});
  },
  showhearder(){
    this.heardersw =! this.heardersw
  },
  addShowAdv() {
    setInterval(() => {
      if (this.showAdv1) {
        this.showAdv1 = false;
        this.showAdv2 = false;
      } else {
        this.showAdv1 = true;
        this.showAdv2 = true;
      }
    },13000)
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
  familySwiper,
  familySwipxuan2
}
}
</script>
<style lang="scss" scope>
.familyhapp {
  overflow: hidden;
}
#stage_advan_Group {
  width: 1600px;
  height: 880px;
  // border: 1px solid red;
	position: absolute;
	margin: 0px;
	left: 0px;
	bottom: 0px;
  top:0px;
  z-index: 99;
	right: auto;
background-image: url(../assets/img/tanmuBg.png);
}
#stage_advan_Group_copy {
  width: 1560px;
  height: 880px;
  // border: 1px solid red;
  position: absolute;
  margin: 0px;
  left: 20px;
  bottom: 0px;
  top:0px;
  right: auto;
  overflow: hidden;
  background-image: url(../assets/img/tanmu.png);
}
#stage_advan_yun2_02Copy {
	position: absolute;
	margin: 0px;
	right: auto;
	bottom: auto;
	background-image: url(../assets/img/lr/lr1.png);
	background-color: rgba(0, 0, 0, 0);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0px 0px;
	float: left;
  -webkit-animation: 13s move1 infinite linear;
}
.lr1 {
  left: 700px;
  top: 30px;
  width: 1350px;
  height: 76px;
}

#stage_advan_yun2_02 {
	position: absolute;
	margin: 0px;
	right: auto;
	bottom: auto;
	background-image: url(../assets/img/lr/lr2.png);
	background-color: rgba(0, 0, 0, 0);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0px 0px;
   -webkit-animation: 10s move1 infinite linear;
}
.lr2 {
  left: 600px;
  top: 120px;
  width: 1310px;
  height: 76px;
}
#stage_advan_yun2_03{
	position: absolute;
	margin: 0px;
	right: auto;
	bottom: auto;
	background-image: url(../assets/img/lr/lr3.png);
	background-color: rgba(0, 0, 0, 0);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0px 0px;
  -webkit-animation: 11s move infinite linear;
}
.lr3 {
  left: 600px;
  top: 220px;
  width: 519px;
  height: 76px;
}
#stage_advan_yun2_04{
	position: absolute;
	margin: 0px;
	right: auto;
	bottom: auto;
	background-image: url(../assets/img/lr/lr4.png);
	background-color: rgba(0, 0, 0, 0);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0px 0px;
  -webkit-animation: 12s move1 infinite linear;
}
.lr4{
  left: 460px;
  top: 340px;
  width: 1310px;
  height: 76px;
}
#stage_advan_yun2_05{
	position: absolute;
	margin: 0px;
	right: auto;
	bottom: auto;
	background-image: url(../assets/img/lr/lr5.png);
	background-color: rgba(0, 0, 0, 0);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0px 0px;
  -webkit-animation: 13s move1 infinite linear;
}
.lr5 {
  left: 600px;
  top: 460px;
  width: 1140px;
  height: 76px;
}
#stage_advan_yun2_06{
	position: absolute;
	margin: 0px;
	right: auto;
	bottom: auto;
	background-image: url(../assets/img/lr/lr6.png);
	background-color: rgba(0, 0, 0, 0);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	background-position: 0px 0px;
  -webkit-animation: 10s move1 infinite linear;
}
.lr6 {
  left: 600px;
  top: 580px;
  width: 1140px;
  height: 76px;
}
#stage_advan_yun2_07{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr7.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 13s move1 infinite linear;
}
.lr7 {
  left: 600px;
  top: 670px;
  width: 1140px;
  height: 76px;
}
#stage_advan_yun2_08{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr8.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 10s move1 infinite linear;
}
.lr8 {
  left: 600px;
  top: 760px;
  width: 1264px;
  height: 76px;
}
#stage_advan_yun2_09{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr9.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 11s move1 infinite linear;
}
#stage_advan_yun2_10{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr10.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 12s move1 infinite linear;
}
#stage_advan_yun2_11{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr11.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 13s move1 infinite linear;
}
#stage_advan_yun2_12{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr12.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 10s move1 infinite linear;
}
#stage_advan_yun2_13{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr13.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 13s move1 infinite linear;
}
#stage_advan_yun2_14{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr14.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 10s move1 infinite linear;
}
#stage_advan_yun2_15{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr15.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 11s move1 infinite linear;
}
#stage_advan_yun2_16{
  position: absolute;
  margin: 0px;
  right: auto;
  bottom: auto;
  background-image: url(../assets/img/lr/lr16.png);
  background-color: rgba(0, 0, 0, 0);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: 0px 0px;
  -webkit-animation: 12s move infinite linear;
}
.stage_advan{
  // -webkit-animation: 10s move infinite linear;
}


// @-webkit-keyframes move {
// 	0% {
// 		left: -100px;
// 	}

// 	100% {
// 		left: 900px;
// 	}
// }

@keyframes move {
	0% {
		left: 1600px;
	}

	100% {
		left:-900px;
	}
}
@keyframes move1 {
  0% {
    left: 1600px;
  }

  100% {
    left:-1600px;
  }
}
 
 
.banitem{
  margin: 0 auto;
  margin-top: 180px;
  max-width: 1600px;
  // border: 1px solid red;
 .zhongzi{
  max-width: 1600px;
  margin: 100px auto;
  display: flex;
  align-items: center;
  .right{
   margin-left: 80px;
   .rightop{
    font-size: 26px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 37px;
   }
   .righcen{
    margin: 100px 0;
   }
   .righcentext{
    font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 60px;
   }
   .righbom{
    font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 45px;
   }
  }
 }

  .banitemtitle{
    width: 128px;
height: 48px;
font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 47px;
padding-bottom: 10px ;
  border-bottom: 6px solid rgb(200,34,39);
  margin-bottom: 20px;
  }
  .xiaoshun{
    display: flex;
    // align-items: center;
    .right{
      padding-top: 10px;
      margin-left: 40px;
      .righttitl{
        font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 45px;
      }
      .righbom{
        margin: 50px 0;
        .righbomnum{
          font-size: 80px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #C82227;
line-height: 112px;
        }
        .righbomtext{
          font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 30px;
        }
      }
    }
  }
  .baniteitem{
    padding-bottom: 30px;
    border-bottom: 2px solid #979797;
    margin: 80px 0;
    .iteitemone{
      font-size: 32px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #010001;
line-height: 45px;
    }
    .iteitemonered{
      color: #C82227;
      font-weight: 500;
    }
    .iteitemtwo{
      font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #676767;
margin: 30px 0;
    }
    .iteitemthree{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .itemleft{
        font-size: 22px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #000000;
line-height: 30px;
      }
      .itemright{
        display: flex;
      justify-content: space-between;
      align-items: center;
      .text{
        font-size: 22px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #C82227;
line-height: 30px;
margin-left: 10px;
      }
      }
    }
  }
}
.banitemtop{
margin-top: 480px;
}

.familyhapp{
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/dbanner.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
  border-radius: 8px;
background-image: url(../assets/img/bigbanhder.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 80px;
  color: white;
  .posionbando{
    font-size: 50px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 74px;
  }
  .posionbandt{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
margin-top: 20px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 232px;
  color: white;
  .bigsix{
    font-size: 60px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 89px;
letter-spacing: 12px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}


}

</style>