<template>
  <div class="weiyiwang">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
      <div v-if="heardersw">
        <titletop />
      </div>
      <div class="optionbander">
        <div class="bigsix">致力于打造BIPV一体化光伏建筑</div>
        <div class="smallsix" >零碳建造-绿色生产</div>
      </div>
      <div class="backsmaimg">
        <div class="posionband">
          <div class="posionbando">上海鸿旗电力发展有限公司</div>
          <div class="posionbandt" >鸿旗企业/企业简介/下属公司</div>
        </div>
      </div>
    </div>

    <div class="subordtop">
      <div class="suboleft">
        <div  >
          <div style="font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 47px;">
            公司介绍
          </div>
          <div style="display: flex;margin-top: 10px;">
            <p style="background: rgb(248,162,28); width: 40px;height: 6px;"></p> <p style="background:rgb(213,8,1); width: 90px;height: 6px;"></p>
          </div>
        </div>
        <div style="
width: 709px;
height: 240px;
font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 60px;margin-top: 80px;">
          应国家碳达峰、碳中和、“3060目标”、开启低碳新时代的大环境而生，
          根植于集团优势资源，专注于民房户用、工商业分布式BIPV、商业建筑、
          光伏幕墙等绿色能源开发，公司始终秉持“承天时行，阳光生活”的经营
          理念，为国家的绿色环保事业贡献力量。
        </div>
      </div>
      <div class="suboright">
        <img style="width: 680px; height: 500px;" src="../assets/img/pojs.png"  alt="">
      </div>




    </div>

    <div  style="max-width: 1600px; margin: 160px auto;">
      <div style="font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 47px;">
        服务优势
      </div>
      <div style="display: flex;margin-top: 10px;">
        <p style="background: rgb(248,162,28); width: 40px;height: 6px;"></p> <p style="background:rgb(213,8,1); width: 90px;height: 6px;"></p>
      </div>

      <div style="width: 1200px; margin: 100px auto; display: flex;">
        <div style="background: white; width: 336px;height: 336px; text-align: center; box-shadow:10px 6px 30px 20px rgb(245,245,245);">
          <img  style="margin-top: 60px;margin-bottom: 40px;" src="../assets/img/gout.png" alt="">
          <p style="width: 112px;
          margin: 0 auto;
height: 80px;
font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #F48F17;
line-height: 40px;">积极跟进 主动沟通</p>
        </div>
        <div style="background: #F48F17; width: 336px;height: 336px; text-align: center; ">
          <img  style="margin-top: 60px;margin-bottom: 40px;" src="../assets/img/shig.png" alt="">
          <p style="width: 112px;
          margin: 0 auto;
height: 80px;
font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: white;
line-height: 40px;">精密设计 严谨施工</p>
        </div>
        <div style="background: #EB5C0A; width: 336px;height: 336px; text-align: center; ">
          <img  style="margin-top: 60px;margin-bottom: 40px;" src="../assets/img/fuwuo.png" alt="">
          <p style="width: 112px;
          margin: 0 auto;
height: 80px;
font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: white;
line-height: 40px;">售前细心 售后安心</p>
        </div>
        <div style="background: #E43E04; width: 336px;height: 336px; text-align: center;">
          <img  style="margin-top: 60px;margin-bottom: 40px;" src="../assets/img/fuwu.png" alt="">
          <p style="width: 112px;
          margin: 0 auto;
height: 80px;
font-size: 28px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: white;
line-height: 40px;">只有不足 没有满足</p>
        </div>
      </div>


    </div>

    <div style="max-width: 1600px; margin: 0 auto;">
      <div style="font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 47px;">
        产品介绍
      </div>
      <div style="display: flex;margin-top: 10px;">
        <p style="background: rgb(248,162,28); width: 40px;height: 6px;"></p> <p style="background:rgb(213,8,1); width: 90px;height: 6px;"></p>
      </div></div>



    <div>

      <div>
        <div class="zhongzi">
          <div class="left">

            <pordianli />
          </div>
        </div>

      </div>
    </div>
    <div>
      <!-- <chapinjies /> -->
    </div>



    <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import pordianli from '../components/shehui/pordianli.vue'
// import chapinjies from '../components/shehui/chapinjies.vue'
export default {
  data(){
    return{
      heardersw:false,
    }
  },
  mounted(){
    console.log(this.$store.state.currPage);

  },
  methods:{

    showhearder(){
      this.heardersw =! this.heardersw
    }
  },
  name: 'App',
  components: {
    Foot,
    titletop,
    pordianli,

  }
}
</script>
<style lang="scss" scope>

.zhongzi{
  max-width: 1600px;
  margin: 100px auto;
  display: flex;
  // align-items: center;
  .right{
    margin-left: 80px;
    .rightop{
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 37px;
      margin-top: 30px;
    }
    .righcen{
      margin-top: 50px;
      margin-bottom: 30px;
    }
    .righcentext{
      font-size: 22px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #666666;
      line-height: 60px;
    }
    .righbomcp{
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 45px;
      background: #E43E04;
      padding: 10px;
      width: 400px;
      height: 45px;
      color: white;
    }
  }
}

.weiyiwang{
  overflow-x: hidden;
  .backimg{
    // border: 1px solid red;
    height: 600px;
    background-image: url(../assets/img/pobander.png);
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
  }
  .hearderimg{
    width: 29px;
    height: 21px;
    position: absolute;
    right: 50px;
    top: 20px;
  }
  .showhearder{
    padding: 50px ;
    padding-top: 100px;

    width: 345px;
    height: 608px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: white;
    z-index: 10;
    .heaitem{
      // border: 1px solid red;
      display: flex;
      align-items: center;
      margin: 30px 0;

    }

  }
  .liactive{
    color: rgb(230,82,86);
  }
  .backsmaimg{
    width: 710px;
    height: 300px;
    background-image: url(../assets/img/bigbanhderDl.png);
    background-position: 50%;
    background-repeat: no-repeat;
    border-radius: 10px;
    position: absolute;
    bottom: -100px;
    right: 220px;
    .posionband{
      box-sizing: border-box;
      padding: 0 0 0 55px;
      position: absolute;
      // border: 1px solid red;
      width: 100%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: white;
      .posionbando{
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 74px;
      }
      .posionbandt{
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 27px;
        margin-top: 20px;
      }
    }
  }
  .optionbander{
    position: absolute;
    top: 239px;
    left: 232px;
    color: white;
    .bigsix{
      font-size: 60px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 89px;
      letter-spacing: 12px;
    }
    .smallsix{
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 27px;
      letter-spacing: 6px;
      margin-top: 20px;
    }
  }

  .subordtop{
    max-width: 1600px;
    margin: 200px auto;
    display: flex;
    justify-content: space-between;

  }

}

</style>