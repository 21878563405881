<template>
  <div class="example-3d">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in imgArr" :key="index">
        <img
            :src="item.src"
            alt=""
        />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev"></div>
      <div class="swiper-button-next" slot="button-next"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-example-3d-coverflow',
  title: '3D Coverflow effect',
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      imgArr:[
        {
          src:require("./img/ygtj/bg.png")
        },
        {
          src:require("./img/ygtj/bg1.png")
        },
        {
          src:require("./img/ygtj/bg2.png")
        },
        {
          src:require("./img/ygtj/bg3.png")
        },
        {
          src:require("./img/ygtj/bg4.png")
        },
        {
          src:require("./img/ygtj/bg5.png")
        },
        {
          src:require("./img/ygtj/bg6.png")
        },
        {
          src:require("./img/ygtj/bg7.png")
        },
        {
          src:require("./img/ygtj/bg8.png")
        },
        {
          src:require("./img/ygtj/bg9.png")
        },
        {
          src:require("./img/ygtj/bg10.png")
        },
        {
          src:require("./img/ygtj/bg11.png")
        },
        {
          src:require("./img/ygtj/bg12.png")
        },
        {
          src:require("./img/ygtj/bg13.png")
        },
        {
          src:require("./img/ygtj/bg14.png")
        },
        {
          src:require("./img/ygtj/bg15.png")
        },
        {
          src:require("./img/ygtj/bg16.png")
        },
        {
          src:require("./img/ygtj/bg17.png")
        },
        {
          src:require("./img/ygtj/bg18.png")
        },
        {
          src:require("./img/ygtj/bg19.png")
        },
        {
          src:require("./img/ygtj/bg20.png")
        },
        {
          src:require("./img/ygtj/bg21.png")
        },
        {
          src:require("./img/ygtj/bg22.png")
        },
        {
          src:require("./img/ygtj/bg23.png")
        },
        {
          src:require("./img/ygtj/bg24.png")
        },
      ],
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        initialSlide :2,
        loop:true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 30,
          stretch: -150,
          depth: 100,
          modifier: 1,
          slideShadows : false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.example-3d {
  width: 100%;
  height: 216px;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 348px;
    height: 216px;
    text-align: center;
    font-weight: bold;
    img {
      width: 348px;
      height: 216px;
    }
  }
  .swiper-button-prev {
    left: 34%;
    background-image: url(./img/leftBtn.png);
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .swiper-button-next {
    right: 34%;
    background-image: url(./img/rightBtn.png);
    background-position: 50%;
    background-repeat: no-repeat;
  }
  .swiper-button-next:after{
    content: none;
  }
  .swiper-button-prev:after{
    content: none;
  }
}
</style>