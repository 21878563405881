<template>
  <div>
    <div class="swiperBox">
      <div class="banner-card">

        <ul class="ul">
          <li v-for="(item,index) in imgarr" :key="index">
            <img
                :src="item.src"
                alt=""
            />

          </li>
        </ul>
      </div>
      <div class="backcore">
      </div>

      <div style="width: 160px;margin: 0 auto; display: flex;justify-content: space-between; align-items: center;">
        <div  @click="leftMove()">
          <img src="../../assets/img/pozuo.png" alt="">
        </div>
        <div  @click="rightMove()">
          <img src="../../assets/img/poyou.png" alt="">
        </div>
      </div>
    </div>
    <div class="right" style="right: 18%" v-if="yuankacti == 'one'">
      <p class="rightop">睿顶BIPV</p>
      <div class="righcen">
        <p class="righcentext">免拆除原彩钢瓦 </p>
        <p class="righcentext"> 不影响生产经营</p>
        <p class="righcentext">水密性双重保护</p>
        <p class="righcentext">施工运维更便捷</p>
        <p class="righcentext"> 延长原屋顶寿命</p>
        <p class="righcentext">单位发电效能高</p>
      </div>
      <div style="font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 30px;
          margin-bottom: 30px;"
      >
        使用领域：有效适配各种类型彩钢瓦屋面不拆除。
      </div>
      <p class="righbomcp">联系我们：400-600-8891</p>
    </div>
    <div class="right" style="right: 12%" v-if="yuankacti == 'two'">
      <p class="rightop">智顶BIPV</p>
      <div class="righcen">
        <p class="righcentext">无孔无胶阻水防振技术  </p>
        <p class="righcentext">  防渗导水专利</p>
        <p class="righcentext"> 顺坡一体气楼</p>
        <p class="righcentext">检修安全便捷</p>
        <p class="righcentext"> 满足建筑三性</p>
      </div>
      <div style="font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 30px;margin-bottom: 30px;">使用领域：用于新厂房建设，需要按照光伏屋面送审、验收。</div>
      <p class="righbomcp">联系我们：400-600-8891</p>
    </div>
    <div class="right" v-if="yuankacti == 'three'">
      <p class="rightop">双顶BIPV</p>
      <div class="righcen">
        <p class="righcentext">适配常规组件</p>
        <p class="righcentext">定制化屋面板</p>
        <p class="righcentext">免除漏雨隐患</p>
        <p class="righcentext">施工运维便捷</p>
        <p class="righcentext">厂房验收规范</p>
        <p class="righcentext">屋顶产权清晰</p>
      </div>
      <div style="font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 30px;margin-bottom: 30px;">使用领域：用于新厂房建设，按照普通彩钢瓦屋面送审、验收。</div>
      <p class="righbomcp">联系我们：400-600-8891</p>
    </div>
  </div>
</template>

<script>
  export default {
  data() {
    return {
      default: {
        //默认值
        classArray: ["one", "two", "three",],
        leftSlider: true,
      },
      yuankacti:'one',
      bannerWrap: null,   
      time: null,
      lisArray: null,   //卡片的集合
      classlist: null,
      imgarr:[
        {
          src:require("../../assets/img/porcp.png")
        },
        {
          src:require("../../assets/img/porcp1.png")
        },
        {
          src:require("../../assets/img/porcp2.png")
        },
      ]
    
    };
  },
  methods: {
    constructor() {
      this.bannerWrap = document.getElementsByClassName("banner-card")[0];  
      let lis = this.bannerWrap.getElementsByTagName("li");  
      // 将类数组转化为数组
      this.lisArray = Array.from(lis);  
      this.classlist = this.default.classArray;
      //获取左右按钮
      this.move(this.classlist);
     
      this.listenMouseEvent();
      // this.listenMouseClickBtnEvent();
    },
    //移动
    move(list) {
      this.lisArray.forEach((value, index) => {
        value.setAttribute("class", list[index]); //给每一个li添加对应的类名
        console.log(list[0]);
        this.yuankacti = list[0]
      });
    },
    // 向左运动
    leftMove() {
      let popValue = this.classlist.pop(); //删除并返回数组的最后一个       
      this.classlist.unshift(popValue); //把刚才删除的最后一个添加到数组开头
      this.move(this.classlist);        //调用移动方法，每一个li会根据新数组的位置进行移动，从而实现轮播的效果
    },
    // 向右运动
    rightMove() {
      let shiftValue = this.classlist.shift(); //删除并返回数组的第一个
      this.classlist.push(shiftValue); //把刚才删除的第一个push到数组末尾
      this.move(this.classlist);
    },
    // 定时器自动切换
    timer() {
      this.time = setInterval(this.leftMove.bind(this), 3000);
    },
    // 鼠标移入移出事件
    // listenMouseEvent() {
    //   this.bannerWrap.addEventListener("mouseover", () => {
    //     clearInterval(this.time);
       
    //   });
    //   this.bannerWrap.addEventListener("mouseout", () => {
    //     this.timer();
    //   });
    // },
    // // 点击左右按钮事件
    // listenMouseClickBtnEvent() {
    //   this.leftBtn.addEventListener("click", () => {
    //     this.leftMove();
    //   });
    //   this.rightBtn.addEventListener("click", () => {
    //     this.rightMove();
    //   });
    // },
  },
  mounted() {
    this.constructor();
    console.log(this.lisArray);
  },
};

</script>

<style scoped lang="scss">

.backcore{
  width: 720px;
  height: 70px;
  background: rgb(228,62,4);
  position: relative;
  top: -320px;
  left: 6px;
}
.yuanquan{
  width: 100px;
  margin: 0 auto;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .yuank{
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid red;
  }
  .yuankactive{
 background:red ;
  }
}
.banner-card {
  width: 100%;
  height: 600px;
  line-height: 250px;
  position: relative;
  // margin-top: 100px;
}
.banner-card ul {
  width: 700px;
  height: 600px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  // overflow: hidden;
}
.banner-card li {
  width: 516px;
  height: 600px;
  position: absolute;
  top: 25px;
  list-style-type: none;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 4px;
}
.banner-card li img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.one {
  z-index: 99;
  left: 0px;
  transform: scale(0.9);
}

.two {
  z-index: 100;
  left: 100px;
  transform: scale(1);
}
.three {
  left: 200px;
  z-index: 99;
  transform: scale(0.9);
}
.four {
  z-index: 98;
  left: 2142px;
  transform: scale(0.8);
}
.five {
  left: 2942px;
  z-index: 97;
  transform: scale(0.7);
}
.six {
  left: 3742px;
  z-index: 96;
  transform: scale(0.6);
}

.banner-card div {
  transition: 0.3s;
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
}
.banner-card div span {
  display: inline-block;
  font-size: 25px;
}

.left-btn {
  width: 100px;
    height: 20px;
  left: 340px;
  top: -200px;
  z-index: 10000;
  // border: 1px solid red;
  position: relative;
  img{
    width: 100px;
    height: 20px;
  }
}
.right-btn {
  width: 100px;
    height: 20px;
  left: 1168px;
  top: -220px;
  z-index: 10000;
  // border: 1px solid red;
  position: relative;
  img{
    width: 100px;
    height: 20px;
  }
}

.right{
  position: absolute;
  right: 10%;
  top: 255%;
  margin-left: 80px;
  .rightop{
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 37px;
    margin-top: 30px;
  }
  .righcen{
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .righcentext{
    font-size: 22px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 60px;
  }
  .righbomcp{
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 45px;
    background: #E43E04;
    padding: 10px;
    width: 400px;
    height: 45px;
    color: white;
  }
}
</style>