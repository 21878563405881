<template>
  <div class="jiandang">
   <div style="display: flex; align-items: center;flex-wrap: wrap;  margin: 120px 0px; margin-bottom: 0;">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg1.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg2.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg3.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg4.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg5.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg6.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg7.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg8.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg9.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg10.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg11.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg12.png" alt="">
    <img style="margin: 20px 20px;" src="../../components/shehui/img/dangyuan/dyBg13.png" alt="">
    <div style="width: 242px; margin: 0 20px;"></div>
   </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      syindex: 0,
    };
  },

  mounted() {},
  methods: {
    imgclickjian() {
      if (this.syindex > 0) {
        this.syindex = this.syindex - 1;
      }
    },
    imgclickadd() {
      if (this.syindex < 1) {
        this.syindex = this.syindex + 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.jiandang {
  // height: 572px;
  .scllboom {
    display: flex;
     align-content: center;
    max-width: 1600px;
    margin: 50px auto;
    height: 400px;
    // border: 1px solid red;
    .titleftlscll {
      // border: 1px solid red;
      .titleimg {
        // border: 1px solid red;
        width: 864px;
        height: 556px;
      }
      .imgclick {
        width: 200px;
        // border: 1px solid red;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;
       
      }
    }
    .titrighttlscll {
      padding: 10px 20px;
      width: 864px;
      // width: 400px;
      // border: 1px solid red;
      .rightop {

        height: 50px;
        width: 100%;
        .rightopone {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid rgb(200, 36, 41);
        }
      }
      .righboom {
        text-align: left;
        margin-top: 150px;
        color: #aaa;
        font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 60px;
      }
    }
  }
}
</style>
