<template>
  <div class="swiperBox">
    <div class="banner-card">
      
      <ul class="ul">
        <li v-for="(item,index) in imgarr" :key="index">
          <img
            :src="item.src"
            alt=""
          />
          
        </li>
      </ul>
    </div>
    <div class="backcore">
      </div>
     
      <div style="width: 160px;margin: 0 auto; display: flex;justify-content: space-between; align-items: center;">
        <div  @click="leftMove()">
      <img src="../../assets/img/sszuo.png" alt="">
    </div>
    <div  @click="rightMove()">
      <img src="../../assets/img/ssyou.png" alt="">
    </div>
      </div>
    <div class="right" style="right: 18%" v-if="yuankacti == 'one'">
      <p class="rightop">太阳能光伏支架-可调式光伏支架</p>
      <div class="righcen">
        <p class="righcentext">主材：热镀锌、镀锌铝镁。 </p>
        <p class="righcentext"> 配件：铝合金、热镀锌、不锈钢。</p>
        <p class="righcentext">手动可调支架、剪式千斤顶可调支架、推杆式可调支架</p>
      </div>
      <p class="righbomcp" style="width: 400px;text-align: center;">联系我们：13564877812</p>
    </div>
    <div class="right" style="right: 23%" v-if="yuankacti == 'two'">
      <p class="rightop">太阳能光伏支架-固定式光伏支架</p>
      <div class="righcen">
        <p class="righcentext">主材：热镀锌、镀锌铝镁。</p>
        <p class="righcentext">配件：铝合金、热镀锌、不锈钢。山地支架、</p>
        <p class="righcentext">平地支架、农业（渔光）支架。</p>
      </div>
      <p class="righbomcp" style="width: 400px;text-align: center;">联系我们：13564877812</p>
    </div>
    <div class="right" v-if="yuankacti == 'three'">
      <p class="rightop">锌铝镁高速公路护栏及整套配件</p>
      <div class="righcen">
        <p class="righcentext" style="width: 682px;">预镀锌铝镁合金护栏,不仅大幅提高了护栏的耐腐蚀性、抗磨性等使用寿命,而且机械加工性能优异、绿色环保,性价比较高,具有较大的实用价值,符合可持续性发展的原则。而且对于改造段落的高速公路钢护栏方案中,采用防腐性能更佳的锌铝镁镀层技术进行波形梁钢护栏的局部改造综合性能比较高。</p>
      </div>
      <p class="righbomcp" style="width: 400px;text-align: center;">联系我们：13564877812</p>
    </div>
    <div class="right" v-if="yuankacti == 'four'">
      <p class="rightop">抗震桥架-抗震支架-抗震吊架</p>
      <div class="righcen">
        <p class="righcentext">主材：锌铝镁、镀锌、镀铝锌、玻璃钢、铝合金。</p>
        <p class="righcentext" style="width: 682px;">盛世信恒主要加工用酒钢锌铝镁来做原材料，目前最经济实惠的是表面带有耐蚀镀层的锌铝镁。由于锌铝镁镀层抗震桥架、抗震支架、抗震吊架在生产、运输与安装过程中形成镀层划伤，脱落等问题会影响构件本身防腐效果。目前在预镀处理的碳钢支架中，综合耐蚀性能最好的就是锌铝镁镀层抗震桥架、支架、吊架等。</p>
      </div>
      <p class="righbomcp" style="width: 400px;text-align: center;">联系我们：13564877812</p>
    </div>
    <div class="right" style="right: 18%" v-if="yuankacti == 'five'">
      <p class="rightop">太阳能光伏支架-分布式光伏支架</p>
      <div class="righcen">
        <p class="righcentext">主材：热镀锌、镀锌铝镁、阳极氧化。</p>
        <p class="righcentext">配件：阳极氧化、热镀锌、不锈钢。</p>
        <p class="righcentext">混凝土平屋面分布式支架、彩钢板斜屋面分布式支架。</p>
      </div>
      <p class="righbomcp" style="width: 400px;text-align: center;">联系我们：13564877812</p>
    </div>
    <div class="right" style="right: 23%" v-if="yuankacti == 'six'">
      <p class="rightop">太阳能光伏支架-跟踪式光伏支架</p>
      <div class="righcen">
        <p class="righcentext">主材：热镀锌、镀锌铝镁、不锈钢、</p>
        <p class="righcentext">油漆。回转减速电机式跟踪系统。</p>
      </div>
      <p class="righbomcp" style="width: 400px;text-align: center;">联系我们：13564877812</p>
    </div>
   
  </div>
</template>

<script>
  export default {
  data() {
    return {
      default: {
        //默认值
        classArray: ["one", "two", "three","four","five","six"],
        leftSlider: true,
      },
      yuankacti:'one',
      bannerWrap: null,   
      time: null,
      lisArray: null,   //卡片的集合
      classlist: null,
      imgarr:[
        {
          src:require("../../assets/img/newenergy/bg1.png")
        },
        {
          src:require("../../assets/img/newenergy/bg2.png")
        },
        {
          src:require("../../assets/img/newenergy/bg3.png")
        },
        {
          src:require("../../assets/img/newenergy/bg4.png")
        },
        {
          src:require("../../assets/img/newenergy/bg5.png")
        },
        {
          src:require("../../assets/img/newenergy/bg6.png")
        },
      ]
    
    };
  },
  methods: {
    constructor() {
      this.bannerWrap = document.getElementsByClassName("banner-card")[0];  
      let lis = this.bannerWrap.getElementsByTagName("li");  
      // 将类数组转化为数组
      this.lisArray = Array.from(lis);  
      this.classlist = this.default.classArray;
      //获取左右按钮
      this.move(this.classlist);
     
      this.listenMouseEvent();
      // this.listenMouseClickBtnEvent();
    },
    //移动
    move(list) {
      this.lisArray.forEach((value, index) => {
        value.setAttribute("class", list[index]); //给每一个li添加对应的类名
        console.log(list[0]);
        this.yuankacti = list[0]
      });
    },
    // 向左运动
    leftMove() {
      let popValue = this.classlist.pop(); //删除并返回数组的最后一个       
      this.classlist.unshift(popValue); //把刚才删除的最后一个添加到数组开头
      this.move(this.classlist);        //调用移动方法，每一个li会根据新数组的位置进行移动，从而实现轮播的效果
    },
    // 向右运动
    rightMove() {
      let shiftValue = this.classlist.shift(); //删除并返回数组的第一个
      this.classlist.push(shiftValue); //把刚才删除的第一个push到数组末尾
      this.move(this.classlist);
    },
    // 定时器自动切换
    timer() {
      this.time = setInterval(this.leftMove.bind(this), 3000);
    },
    // 鼠标移入移出事件
    // listenMouseEvent() {
    //   this.bannerWrap.addEventListener("mouseover", () => {
    //     clearInterval(this.time);
       
    //   });
    //   this.bannerWrap.addEventListener("mouseout", () => {
    //     this.timer();
    //   });
    // },
    // // 点击左右按钮事件
    // listenMouseClickBtnEvent() {
    //   this.leftBtn.addEventListener("click", () => {
    //     this.leftMove();
    //   });
    //   this.rightBtn.addEventListener("click", () => {
    //     this.rightMove();
    //   });
    // },
  },
  mounted() {
    this.constructor();
    console.log(this.lisArray);
  },
};

</script>

<style scoped lang="scss">

.backcore{
  width: 720px;
  height: 70px;
  background: rgb(34,129,192);
  position: relative;
  top: -320px;
  left: 6px;
}
.yuanquan{
  width: 100px;
  margin: 0 auto;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .yuank{
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid red;
  }
  .yuankactive{
 background:red ;
  }
}
.banner-card {
  width: 100%;
  height: 600px;
  line-height: 250px;
  position: relative;
  // margin-top: 100px;
}
.banner-card ul {
  width: 700px;
  height: 600px;
  margin: 0 auto;
  text-align: center;
  position: relative;
   overflow: hidden;
}
.banner-card li {
  width: 516px;
  height: 600px;
  position: absolute;
  top: 25px;
  list-style-type: none;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 4px;
}
.banner-card li img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.one {
  z-index: 99;
  left: 0px;
  transform: scale(0.9);
}

.two {
  z-index: 100;
  left: 100px;
  transform: scale(1);
}
.three {
  left: 200px;
  z-index: 99;
  transform: scale(0.9);
}
.four {
  z-index: 98;
  left: 2142px;
  transform: scale(0.8);
}
.five {
  left: 2942px;
  z-index: 97;
  transform: scale(0.7);
}
.six {
  left: 3742px;
  z-index: 96;
  transform: scale(0.6);
}

.banner-card div {
  transition: 0.3s;
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
}
.banner-card div span {
  display: inline-block;
  font-size: 25px;
}

.left-btn {
  width: 100px;
    height: 20px;
  left: 340px;
  top: -200px;
  z-index: 10000;
  // border: 1px solid red;
  position: relative;
  img{
    width: 100px;
    height: 20px;
  }
}
.right-btn {
  width: 100px;
  height: 20px;
  left: 1168px;
  top: -220px;
  z-index: 10000;
  // border: 1px solid red;
  position: relative;
  img{
    width: 100px;
    height: 20px;
  }
}

.right{
  position: absolute;
  right: 10%;
  top: 315%;
  margin-left: 80px;
  .rightop{
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 37px;
    margin-top: 30px;
  }
  .righcen{
    margin-top: 50px;
    margin-bottom: 30px;
  }
  .righcentext{
    font-size: 22px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 60px;
  }
  .righbomcp{
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 45px;
    //background: #E43E04;
    border-radius: 4px;
    padding: 10px;
    width: 400px;
    height: 45px;
    color: white;
  }
}
</style>