<template>
  <div class="qiyejs">
    <div class="backimg">
        <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
       <div v-if="heardersw">
        <titletop />
       </div>
      <div class="optionbander">
        <div class="bigsix">致力于成为行业和社会做出巨大贡献的公司</div>
        <div class="smallsix">虚心接受-拥抱成长备份</div>
      </div>
      <div class="backsmaimg">
       <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
       <div class="posionband">
        <div class="posionbando">企业介绍</div>
        <div class="posionbandt" >鸿旗企业</div>
      </div>
     </div>
      </div>
    <!--  集团介绍  -->
    <div class="jtjs">
      <img src="../assets/img/qiyejianjie/logo.png" class="logo" alt="">
      <div class="content">
        <p>
          集<br/>
          团<br/>
          介<br/>
          绍
        </p>
        <textarea style="white-space: pre-wrap;" disabled cols="30" rows="10">{{ text }}</textarea>
      </div>
      <img src="../assets/img/qiyejianjie/honorFlag.png" class="honorFlag" alt="">

    </div>
    <!--  集团架构  -->
    <div class="jtjg">
      <p class="h1">集团架构</p>
      <span class="h2">鸿旗集团旗下设立4家子公司+1合资公司</span>
      <img src="../assets/img/index/logo.png" class="logo" alt="">
      <img src="../assets/img/qiyejianjie/lineBg.png" class="lineBg" alt="">
      <div class="logo-box">
        <div class="logoBox" :class="[index === '1' ? 'logoBg' : '']" @mousemove="mousemove('1')" @click="toWh">
          <img src="../assets/img/qiyejianjie/logo/whLogoWT.png" alt="" v-if="index === '1'">
          <img src="../assets/img/qiyejianjie/logo/whLogo.png" alt="" v-else>
          <p>上海万鸿<br/>国际贸易有限公司</p>
        </div>
        <div class="logoBox" :class="[index === '2' ? 'logoBg' : '']" @mousemove="mousemove('2')" @click="toDl">
          <img src="../assets/img/qiyejianjie/logo/dlLogoWT.png" alt="" v-if="index === '2'">
          <img src="../assets/img/qiyejianjie/logo/dlLogo.png" alt="" v-else>
          <p>上海鸿旗电力<br/>发展有限公司</p>
        </div>
        <div class="logoBox" :class="[index === '3' ? 'logoBg' : '']" @mousemove="mousemove('3')" @click="toSb">
          <img src="../assets/img/qiyejianjie/logo/gqLogoWT.png" alt="" v-if="index === '3'">
          <img src="../assets/img/qiyejianjie/logo/gqLogo.png" alt="" v-else>
          <p>上海世贝材料<br/>科技有限公司</p>
        </div>
        <div class="logoBox" :class="[index === '4' ? 'logoBg' : '']" @mousemove="mousemove('4')" @click="toWy">
          <img src="../assets/img/qiyejianjie/logo/wyLogoWT.png" alt="" v-if="index === '4'">
          <img src="../assets/img/qiyejianjie/logo/wyLogo.png" alt="" v-else>
          <p>上海尾易网络<br/>信息服务有限公司</p>
        </div>
        <div class="logoBox" :class="[index === '5' ? 'logoBg' : '']" @mousemove="mousemove('5')" @click="toSS">
          <img src="../assets/img/qiyejianjie/logo/xhLogoWT.png" alt="" v-if="index === '5'">
          <img src="../assets/img/qiyejianjie/logo/xhLogo.png" alt="" v-else>
          <p>甘肃盛世信恒新能源<br/>科技有限责任公司</p>
        </div>
      </div>
      <div class="logo-box">
        <div style="display: flex;flex-direction: column;align-items: center;margin-top: 72px;width: 210px;" >
          <img src="../assets/img/qiyejianjie/hoverBg.png" alt="" v-if="index === '1'">
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;margin-top: 72px;width: 210px;">
          <img src="../assets/img/qiyejianjie/hoverBg.png" alt="" v-if="index === '2'">
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;margin-top: 72px;width: 210px;">
          <img src="../assets/img/qiyejianjie/hoverBg.png" alt="" v-if="index === '3'">
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;margin-top: 72px;width: 210px;">
          <img src="../assets/img/qiyejianjie/hoverBg.png" alt="" v-if="index === '4'">
        </div>
        <div style="display: flex;flex-direction: column;align-items: center;margin-top: 72px;width: 210px;">
          <img src="../assets/img/qiyejianjie/hoverBg.png" alt="" v-if="index === '5'">
        </div>
      </div>
      <div class="tips" v-if="index === '1'">万鸿国际是一家专业经营镀锌、锌铝镁、镀铝锌及其彩涂钢品的现代供应链企业。</div>
      <div class="tips" v-if="index === '3'">世贝材料致力于关注并将最前沿科技材料转化为社会生产力之事业。</div>
      <div class="tips" v-if="index === '2'">鸿旗电力致力于民房户用、工商业分布式BIPV、商业建筑、光伏幕墙等绿色能源开发。</div>
      <div class="tips" v-if="index === '4'">尾易网络致力于推动和引领整个冶金行业改革与发展，将拥抱互联网面向全球金属行业。</div>
      <div class="tips" v-if="index === '5'">盛世信恒致力于为清洁能源发电项目提供系统解决方案。</div>
    </div>
    <!--  企业文化  -->
    <div class="qywh">
      <p class="h1">企业文化</p>
      <div class="wenhuaBox">
<!--        <div class="iconBox">
          <div class="h2Box">
            <img src="../assets/img/qiyejianjie/wenhua/smIcon1.png" alt="">
            <span>鸿旗使命</span>
          </div>
          <p class="h3">用心赢得尊敬</p>
          <img src="../assets/img/qiyejianjie/wenhua/hongqiBg.png" class="hongqiBg" alt="">
        </div>-->
        <div :class="[iconIndex === '1' ? 'animation1' : 'animation2', iconFlag ? 'iconBox' : 'iconBox1 iconBg']" @mousemove="mousemoveIcon('1')" @mouseleave="mouseleave('1')">
          <div class="h2Box">
            <img src="../assets/img/qiyejianjie/wenhua/smIcon.png" v-if="!iconFlag">
            <img src="../assets/img/qiyejianjie/wenhua/smIcon1.png" v-else-if="iconFlag">
            <span>鸿旗使命</span>
          </div>
          <p class="h3" v-if="imgFlag">构建以客户为中心的中国钢铁营销中心</p>
          <img src="../assets/img/qiyejianjie/wenhua/hongqiBg.png" class="hongqiBg" v-if="imgFlag">
        </div>
        <div :class="[iconIndex === '2' ? 'animation1' : 'animation2', iconFlag1 ? 'iconBox' : 'iconBox1 iconBg1']" @mousemove="mousemoveIcon('2')" @mouseleave="mouseleave('2')">
          <div class="h2Box">
            <img src="../assets/img/qiyejianjie/wenhua/jzIcon.png" v-if="!iconFlag1">
            <img src="../assets/img/qiyejianjie/wenhua/jzIcon1.png" v-else-if="iconFlag1">
            <span>鸿旗价值观</span>
          </div>
          <p class="h3" v-if="imgFlag1">用心赢得尊敬</p>
          <img src="../assets/img/qiyejianjie/wenhua/jiazhiBg.png" class="hongqiBg" v-if="imgFlag1">
        </div>
        <div :class="[iconIndex === '3' ? 'animation1' : 'animation2', iconFlag2 ? 'iconBox' : 'iconBox1 iconBg2']" @mousemove="mousemoveIcon('3')" @mouseleave="mouseleave('3')">
          <div class="h2Box">
            <img src="../assets/img/qiyejianjie/wenhua/zzIcon.png" v-if="!iconFlag2">
            <img src="../assets/img/qiyejianjie/wenhua/zzIcon1.png" v-else-if="iconFlag2">
            <span>行动准则</span>
          </div>
          <p class="h3" v-if="imgFlag2">构建以客户为中心的中国钢铁营销中心</p>
          <img src="../assets/img/qiyejianjie/wenhua/zhunzeBg.png" class="hongqiBg" v-if="imgFlag2">
        </div>
        <div :class="[iconIndex === '4' ? 'animation1' : 'animation2', iconFlag3 ? 'iconBox' : 'iconBox1 iconBg3']" @mousemove="mousemoveIcon('4')" @mouseleave="mouseleave('4')">
          <div class="h2Box">
            <img src="../assets/img/qiyejianjie/wenhua/yjIcon.png" v-if="!iconFlag3">
            <img src="../assets/img/qiyejianjie/wenhua/yjIcon1.png" v-else-if="iconFlag3">
            <span>鸿旗愿景</span>
          </div>
          <p class="h3" v-if="imgFlag3">成就社会，服务民众，致力于成为一家为行业和社会做出巨大贡献的公司</p>
          <img src="../assets/img/qiyejianjie/wenhua/yjBg.png" class="hongqiBg" v-if="imgFlag3">
        </div>
        <div :class="[iconIndex === '5' ? 'animation1' : 'animation2', iconFlag4 ? 'iconBox' : 'iconBox1 iconBg4']" @mousemove="mousemoveIcon('5')" @mouseleave="mouseleave('5')">
          <div class="h2Box">
            <img src="../assets/img/qiyejianjie/wenhua/zxIcon.png" v-if="!iconFlag4">
            <img src="../assets/img/qiyejianjie/wenhua/zxIcon1.png" v-else-if="iconFlag4">
            <span>哲学和原则</span>
          </div>
          <p class="h3" v-if="imgFlag4">万物皆因物应心愿而崇高</p>
          <img src="../assets/img/qiyejianjie/wenhua/yaunzeBg.png" class="hongqiBg" v-if="imgFlag4">
        </div>
      </div>
    </div>
    <!--  企业发展  -->
    <div class="qyfz">
      <p class="h1">企业发展历程</p>
      <div class="contentBox">
        <div class="yearBox">
          <div class="line"></div>
          <!--     2023       -->
          <div class="yearLine">
            <img src="../assets/img/qiyejianjie/2023.png" alt="">
            <div class="contentText" v-if="page == '1'">
              <p>2023</p>
              <span><em></em>鸿运天降于美好心灵，旗开得胜于彼此成就</span>
            </div>
            <div class="contentText" v-else-if="page == '2'">
              <p>2020</p>
              <span>文化筑基 哲学护航</span>
              <span style="color: #666666;"><em></em>上海致良知年会暨3.0战略说明会</span>
              <span style="color: #666666;"><em></em>《万鸿经营哲学》印刷并发布，是一本为员工、客户的哲学书</span>
            </div>
            <div class="contentText" v-else-if="page == '3'">
              <p>2018</p>
              <span>科技引领</span>
              <span style="color: #666666;"><em></em>上海世贝材料科技有限公司成立</span>
            </div>
          </div>
          <!--     2022       -->
          <div class="yearLine1">
            <img src="../assets/img/qiyejianjie/2022.png" alt="">
            <div class="contentText" v-if="page == '1'">
              <span style="color: #666666;"><em></em>鸿旗集团搬迁鸿旗大厦</span>
              <span>彼此成就 以客户为中心</span>
              <p>2022</p>
            </div>
            <div class="contentText" v-else-if="page == '2'">
              <span style="color: #666666;"><em></em>世贝材料“钢旗”品牌钢旗3镁11铝锌铝镁产品推介会圆满完成</span>
              <span>顺势而为</span>
              <p>2019</p>
            </div>
            <div class="contentText" v-else-if="page == '3'">
              <span style="color: #666666;"><em></em>万鸿国际广东、浙江、安徽、成都、江苏分公司成立</span>
              <span>渠道延展</span>
              <p>2015</p>
            </div>
          </div>
          <!--     2021       -->
          <div class="yearLine2">
            <img src="../assets/img/qiyejianjie/2021.png" alt="">
            <div class="contentText" v-if="page == '1'">
              <p>2021</p>
              <span>扬帆起航</span>
              <span style="color: #666666;"><em></em>鸿旗集团成立，产业集群式服务创新开始</span>
              <span style="color: #666666;"><em></em>尾易网络专业的金属尾货交易平台正式上线</span>
              <span style="color: #666666;"><em></em>上海鸿旗电力发展有限公司成立</span>
              <span style="color: #666666;"><em></em>甘肃盛世信恒新能源科技有限责任公司成立</span>
            </div>
            <div class="contentText" v-else-if="page == '2'">
              <p>2018</p>
              <span>责任担当</span>
              <span style="color: #666666;"><em></em>世贝材料创立“钢旗”品牌，向金属新材料研发方向前进</span>
              <span style="color: #666666;"><em></em>万鸿国际当选为宝山钢结构商(协)会副会长单位</span>
            </div>
            <div class="contentText" v-else-if="page == '3'">
              <p>2010</p>
              <span>起源</span>
              <span style="color: #666666;"><em></em>上海万鸿国际贸易有限公司成立</span>
            </div>
          </div>
        </div>
        <div class="btnBox">
          <img src="../assets/img/qiyejianjie/leftBtn.png" @click="prveBtn" alt="">
          <img src="../assets/img/qiyejianjie/rightBtn.png" @click="nextBtn" alt="">
        </div>
      </div>
    </div>
    <!--  营销渠道  -->
    <div class="yxqd">
      <p class="h1">营销渠道</p>
      <div class="content">
        <img src="../assets/img/qiyejianjie/cpt.png" class="cpt" alt="">
        <div class="gansu">
          <p class="WtDiaopoit" v-if="showMap == '1'"></p>
          <em v-if="showMap == '1'"></em>
          <img src="../assets/img/qiyejianjie/map/gansuMap.png" @click="mapClick(1)" v-if="showMap == '1'">
          <img src="../assets/img/qiyejianjie/map/unGansuMap.png" @click="unMapClick(1)" v-if="showMap != '1'">
        </div>
        <div class="chengdu">
          <p class="WtDiaopoit" v-if="showMap == '2'"></p>
          <em v-if="showMap == '2'"></em>
          <img src="../assets/img/qiyejianjie/map/chengduMap.png" @click="mapClick(2)" v-if="showMap == '2'">
          <img src="../assets/img/qiyejianjie/map/unChengduMap.png" @click="unMapClick(2)" v-if="showMap != '2'">
        </div>
        <div class="guangdong">
          <p class="WtDiaopoit" v-if="showMap == '3'"></p>
          <em v-if="showMap == '3'"></em>
          <img src="../assets/img/qiyejianjie/map/guangdongMap.png" @click="mapClick(3)" v-if="showMap == '3'">
          <img src="../assets/img/qiyejianjie/map/unGuangdongMap.png" @click="unMapClick(3)" v-if="showMap != '3'">
        </div>
        <div class="anhui">
          <p class="WtDiaopoit" v-if="showMap == '4'"></p>
          <em v-if="showMap == '4'"></em>
          <img src="../assets/img/qiyejianjie/map/anhuiMap.png" @click="mapClick(4)" v-if="showMap == '4'">
          <img src="../assets/img/qiyejianjie/map/unAnhuiMap.png" @click="unMapClick(4)" v-if="showMap != '4'">
        </div>
        <div class="jiangsu">
          <p class="WtDiaopoit" v-if="showMap == '5'"></p>
          <em v-if="showMap == '5'"></em>
          <img src="../assets/img/qiyejianjie/map/jiangsuMap.png" @click="mapClick(5)" v-if="showMap == '5'">
          <img src="../assets/img/qiyejianjie/map/unJiangsuMap.png" @click="unMapClick(5)" v-if="showMap != '5'">
        </div>
        <div class="zhejiangMap">
          <p class="WtDiaopoit" v-if="showMap == '6'"></p>
          <em v-if="showMap == '6'"></em>
          <img src="../assets/img/qiyejianjie/map/zhejiangMap.png" @click="mapClick(6)" v-if="showMap == '6'">
          <img src="../assets/img/qiyejianjie/map/unzhejiangMap.png" @click="unMapClick(6)" v-if="showMap != '6'">
        </div>
        <div class="shanghai">
          <p class="WtDiaopoit" v-if="showMap == '7'"></p>
          <em v-if="showMap == '7'"></em>
          <img src="../assets/img/qiyejianjie/map/shanghaiMap.png" @click="mapClick(7)" v-if="showMap == '7'">
          <img src="../assets/img/qiyejianjie/map/unShanghaiMap.png" @click="unMapClick(7)" v-if="showMap != '7'">
        </div>
        <div class="mapTips" v-if="showMap == '1'">
          <div class="title">
            <p class="h2">嘉峪关</p>
            <p class="diaopoit"></p>
          </div>
          <span class="cr0">甘肃盛世信恒新能源科技有限责任公司</span>
          <span>甘肃省嘉峪关市嘉东工业园区金港路567号</span>
          <div class="telBox">
            <img src="../assets/img/qiyejianjie/map/tel.png" class="tel" alt="">
            <span>&nbsp;&nbsp;：135 6487 7812</span>
          </div>
        </div>
        <div class="mapTips" v-if="showMap == '2'">
          <div class="title">
            <p class="h2">成都</p>
            <p class="diaopoit"></p>
          </div>
          <span class="cr0">成都万鸿昌国际贸易有限公司</span>
          <span>成都市金牛区金丰路6号量力交易大厦C座1509号</span>
          <div class="telBox">
            <img src="../assets/img/qiyejianjie/map/tel.png" class="tel" alt="">
            <span>&nbsp;&nbsp;：028-64632088</span>
            <img src="../assets/img/qiyejianjie/map/email.png" class="email" alt="">
            <span>&nbsp;&nbsp;：028-64633488</span>
          </div>
        </div>
        <div class="mapTips" v-if="showMap == '3'">
          <div class="title">
            <p class="h2">佛山</p>
            <p class="diaopoit"></p>
          </div>
          <span class="cr0">广东兰格钢铁贸易有限公司</span>
          <span>广东省佛山市顺德区乐从镇细海工业区欧浦商务大厦504室</span>
          <div class="telBox">
            <img src="../assets/img/qiyejianjie/map/tel.png" class="tel" alt="">
            <span>&nbsp;&nbsp;：0757-28862308</span>
          </div>
        </div>
        <div class="mapTips" v-if="showMap == '4'">
          <div class="title">
            <p class="h2">马鞍山</p>
            <p class="diaopoit"></p>
          </div>
          <span class="cr0">安徽鸿兴新科技有限公司</span>
          <span>安徽省马鞍山市花山区解放路街道西湖花园荷香公寓69栋315室</span>
          <div class="telBox">
            <img src="../assets/img/qiyejianjie/map/tel.png" class="tel" alt="">
            <span>&nbsp;&nbsp;：151 2119 4053</span>
          </div>
        </div>
        <div class="mapTips" v-if="showMap == '5'">
          <div class="title">
            <p class="h2">苏州</p>
            <p class="diaopoit"></p>
          </div>
          <span class="cr0">苏州烨贸国际贸易有限公司</span>
          <span>江苏省苏州市常熟市经济技术开发区研究院路7号303-304室</span>
          <div class="telBox" style="width: 350px">
            <img src="../assets/img/qiyejianjie/map/tel.png" class="tel" alt="">
            <span>&nbsp;&nbsp;：0512-52138963</span>
            <img src="../assets/img/qiyejianjie/map/email.png" class="email" alt="">
            <span>&nbsp;&nbsp;：0512-52138961</span>
          </div>
        </div>
        <div class="mapTips" v-if="showMap == '6'">
          <div class="title">
            <p class="h2">杭州</p>
            <p class="diaopoit"></p>
          </div>
          <span class="cr0">浙江中登进出口有限公司</span>
          <span>浙江省杭州市萧山区大成名座3幢2单元1102室</span>
          <div class="telBox">
            <img src="../assets/img/qiyejianjie/map/tel.png" class="tel" alt="">
            <span>&nbsp;&nbsp;：0571-83586344</span>
          </div>
        </div>
        <div class="mapTips" v-if="showMap == '7'">
          <div class="title">
            <p class="h2">上海</p>
            <p class="diaopoit"></p>
          </div>
          <span class="cr0">
            上海万鸿国际贸易有限公司<br/>
            上海世贝材料科技有限公司<br/>
            上海尾易网络信息服务有限公司<br/>
            上海鸿旗电力发展有限公司
          </span>
          <span>上海市宝山区友谊路1558弄35栋</span>
          <div class="telBox">
            <img src="../assets/img/qiyejianjie/map/tel.png" class="tel" alt="">
            <span>&nbsp;&nbsp;：400-600-8891</span>
            <img src="../assets/img/qiyejianjie/map/email.png" class="email" alt="">
            <span>&nbsp;&nbsp;：021-51261971</span>
          </div>
        </div>
      </div>
      <div class="numBox" id="numBox">
        <p>
          <b id="employNum">8</b>个<span>服务中心</span>
        </p>
        <p>
          <b id="storeNum">10</b><span>大仓库</span>
        </p>
        <p>
          <b id="salesNum">40</b>万吨<span>年销售量</span>
        </p>
        <p>
          超<b id="sumOnSale">20</b>亿<span>年销售额</span>
        </p>
      </div>
    </div>


   
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
export default {
data(){
 return{
  heardersw:false,
   logo: 'logoBg',
   index: '1',
   iconIndex: '1',
   iconFlag: false,
   iconFlag1: false,
   iconFlag2: false,
   iconFlag3: false,
   iconFlag4: false,
   imgFlag: false,
   imgFlag1: false,
   imgFlag2: false,
   imgFlag3: false,
   imgFlag4: false,
   page: 1,
   showMap: 1,
   alreadyExecuted:false,
   text: ''
 }
},
mounted(){
 console.log(this.$store.state.currPage);
  setTimeout(() => {
    this.iconFlag = true;
    this.imgFlag = true;
  },1000)

  var that = this
  //    //判断到达底部
  window.onscroll = function(){

    //定义变量获取屏幕视口宽度
    let windowWidth = document.body.clientWidth;
    console.log(windowWidth);
    if(windowWidth>1500){
      //变量scrollTop是滚动条滚动时，距离顶部的距离
      let winscrollTop = document.documentElement.scrollTop||document.body.scrollTop;
      let numBox = document.getElementById("numBox").offsetTop;
      console.log(winscrollTop);
      console.log(numBox);
      if(winscrollTop > 4430){
        if(!this.alreadyExecuted) {
          that.addNumber(0, 8, 'employNum');
          that.addNumber(0, 10, 'storeNum');
          that.addNumber(0, 40, 'salesNum');
          that.addNumber(0, 20, 'sumOnSale');
          this.alreadyExecuted = true;
        }
      }
    }
  }

},
  created() {
    this.text = '上海鸿旗发展集团有限公司是金属（新）材料现代服务型企业，构建涵盖产业互联网、尾货处置、产业供应链、新材料研究、金属材料采购服务、贸易等细分领域需求的完整现代服务体系。\n经过数年发展，鸿旗集团为光伏电站工程、中高端电器设备、高铁建筑、工业建筑、农业养殖业装备及构筑物、轻型钢结构、地下管廊工程等领域提供服务赋能。鸿旗集团始终坚持“用心赢得尊敬”的核心价值观。服务近4000家企业，近百家大型行业龙头央企、国企、上市公司，致力于为客户提供超值的产品和服务，为员工和社会创造最大价值，实现共同发展，彼此成就。\n目前鸿旗集团正在践行“五星”战略：学习型企业、家庭幸福型企业、钢铁供应链服务创新型企业、3.0企业、鸿旗新材等。实现“让员工幸福”“让客户感动”“让社会尊重”“让祖国放心”的经营目标。展望未来，鸿旗集团将秉承和落实“成就社会，服务民众，致力于成为一家为行业和社会做出巨大贡献的公司。”的愿景和“构建以客户为中心的中国钢铁营销中心”的使命。坚持全产业链现代服务和智慧升级，与时代同频共振，成就伟大事业。'
  },
  watch: {
    iconIndex(val) {
      console.log(val)
      this.imgFlag = false;
      this.imgFlag1 = false;
      this.imgFlag2 = false;
      this.imgFlag3 = false;
      this.imgFlag4 = false;
    }
  },
methods:{
  mousemove(index) {
    console.log(index);
    this.index = index;
  },
  mousemoveIcon(index) {
    console.log(index);
    let _this = this;
    _this.iconIndex = index;
    setTimeout(() => {
      switch (index) {
        case "1":
          _this.iconFlag = true;
          _this.iconFlag1 = false;
          _this.iconFlag2 = false;
          _this.iconFlag3 = false;
          _this.iconFlag4 = false;
          _this.imgFlag = true;
          _this.imgFlag1 = false;
          _this.imgFlag2 = false;
          _this.imgFlag3 = false;
          _this.imgFlag4 = false;
          break;
        case "2":
          _this.iconFlag = false;
          _this.iconFlag1 = true;
          _this.iconFlag2 = false;
          _this.iconFlag3 = false;
          _this.iconFlag4 = false;
          _this.imgFlag = false;
          _this.imgFlag1 = true;
          _this.imgFlag2 = false;
          _this.imgFlag3 = false;
          _this.imgFlag4 = false;
          break;
        case "3":
          _this.iconFlag = false;
          _this.iconFlag1 = false;
          _this.iconFlag2 = true;
          _this.iconFlag3 = false;
          _this.iconFlag4 = false;
          _this.imgFlag = false;
          _this.imgFlag1 = false;
          _this.imgFlag2 = true;
          _this.imgFlag3 = false;
          _this.imgFlag4 = false;
          break;
        case "4":
          _this.iconFlag = false;
          _this.iconFlag1 = false;
          _this.iconFlag2 = false;
          _this.iconFlag3 = true;
          _this.iconFlag4 = false;
          _this.imgFlag = false;
          _this.imgFlag1 = false;
          _this.imgFlag2 = false;
          _this.imgFlag3 = true;
          _this.imgFlag4 = false;
          break;
        case "5":
          _this.iconFlag = false;
          _this.iconFlag1 = false;
          _this.iconFlag2 = false;
          _this.iconFlag3 = false;
          _this.iconFlag4 = true;
          _this.imgFlag = false;
          _this.imgFlag1 = false;
          _this.imgFlag2 = false;
          _this.imgFlag3 = false;
          _this.imgFlag4 = true;
          break;
      }
    },500)
  },
  mouseleave(index) {
    this.imgFlag = false;
    this.imgFlag1 = false;
    this.imgFlag2 = false;
    this.imgFlag3 = false;
    this.imgFlag4 = false;
  },
  showhearder(){
    this.heardersw =! this.heardersw
  },
  nextBtn() {
    this.page++
    if (this.page > 3) {
      this.page = 1;
    }
  },
  prveBtn() {
    this.page--
    if (this.page < 1) {
      this.page = 3;
    }
  },
  mapClick(index) {
    console.log(index)
    this.showMap = index;
  },
  unMapClick(index) {
    this.showMap = index;
  },
  addNumber(start, end, id){
    var o = document.getElementById(id);
    var i = start;
    var Interval;
    if(i < end){
      Interval = setInterval(function(){
        i += 1; // 设置每次增加的动态数字，可调整
        if(i > end) {
          clearInterval(Interval); // 清除setInterval的time，这个方法w3c可具体查看文档
          o.innerHTML = end.toLocaleString(); // 此赋值是为了避免最后一次增加过后的数据和真实数据不同
          i = 0;
        } else {
          o.innerHTML = i.toLocaleString();
        }
      }, 100); // 数据跳转的速度控制
    }
  },
  addNumberbig(start, end, id){
    var o = document.getElementById(id);
    var i = start;
    var Interval;
    if(i < end){
      Interval = setInterval(function(){
        i += 2; // 设置每次增加的动态数字，可调整
        if(i > end) {
          clearInterval(Interval); // 清除setInterval的time，这个方法w3c可具体查看文档
          o.innerHTML = end.toLocaleString(); // 此赋值是为了避免最后一次增加过后的数据和真实数据不同
          i = 0;
        } else {
          o.innerHTML = i.toLocaleString();
        }
      }, 10); // 数据跳转的速度控制
    }
  },
  /*
   跳转其他公司
  * */
  //跳转到万鸿
  toWh() {
    this.$router.push({ path: '/wanhong'});
  },
  // 跳转到盛世信恒
  toSS() {
    this.$router.push({ path: '/newenergy'});
  },
  // 跳转到世贝
  toSb() {
    this.$router.push({ path: '/aboutUs'});
  },
  // 跳转到电力
  toDl() {
    this.$router.push({ path: '/weiyiwang'});
  },
  // 跳转到尾易网
  toWy() {
    this.$router.push({ path: '/weiyiw'});
  },
},
name: 'App',
components: {
  Foot,
  titletop,
 
}
}
</script>
<style lang="scss" scope>
.qiyejs{
  overflow-x: hidden;
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/dbanner.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
background-image: url(../assets/img/bigbanhder.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
  border-radius: 8px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 80px;
  color: white;
  .posionbando{
    font-size: 50px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 74px;
  }
  .posionbandt{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
margin-top: 20px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 232px;
  color: white;
  .bigsix{
    font-size: 60px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 89px;
letter-spacing: 12px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}
  /* 集团介绍 */
  .jtjs {
    width: 100%;
    height: 823px;
    margin-top: 165px;
    box-sizing: border-box;
    padding: 0 0 0 160px;
    position: relative;
    overflow: hidden;
    .logo {
      position: absolute;
      width: 524px;
      height: 567px;
    }
    .content {
      width: 1116px;
      display: flex;
      margin-left: 343px;
      position: relative;
      margin-top: 236px;
      z-index: 5;
      p {
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #010001;
        line-height: 70px;
        margin-right: 75px;
      }
      textarea {
        width: 1035px;
        height: 266px;
        background: transparent;
        //background: #FFFFFF;
        border: none;
        font-size: 22px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #010001;
        line-height: 38px;
        letter-spacing: 1px;
        padding-right: 62px;
        resize:none;
      }
      textarea::-webkit-scrollbar {
        width: 7px;
        background-color: #F5F5F5;
        border-radius: 4px;
      }
      /*滚动条设置*/
      textarea::-webkit-scrollbar-thumb{
        -webkit-box-shadow: inset 0 0 6px #C82227;
        border-radius: 4px;
        background-color: #C82227;
      }
    }
    .honorFlag {
      position: absolute;
      left: 248px;
      bottom: 0;
      width: 1424px;
      height: 162px;
    }
  }
  /* 集团架构 */
  .jtjg {
    width: 100%;
    margin-top: 96px;
    .h1 {
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #010001;
      line-height: 70px;
      text-align: center;
    }
    .h2 {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 45px;
      display: block;
      text-align: center;
      margin-top: 70px;
    }
    .logo {
      display: block;
      margin: 80px auto 0;
      text-align: center;
    }
    .lineBg {
      display: block;
      margin: 10px auto;
    }
    .logo-box {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      justify-content: space-between;
      padding: 0 160px;
      .logoBox {
        width: 210px;
        height: 186px;
        background: #F5F5F5;
        display: flex;
        border-radius: 4px;
        flex-direction: column;
        align-items: center;
        box-sizing: border-box;
        padding: 29px 0 0 0;
        p {
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          text-align: center;
          line-height: 30px;
          margin-top: 15px;
        }
      }
      .logoBg {
        background: #C82227;
        p {
          color: #FFFFFF;
        }
      }
    }
    .tips {
      width: 1600px;
      height: 90px;
      box-sizing: border-box;
      padding: 0 0 0 85px;
      box-shadow: 0px 5px 9px 0px rgba(0,0,0,0.21), 0px 22px 23px 0px rgba(0,0,0,0.03);
      border-radius: 12px;
      margin: 0 auto;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 90px;
    }
  }
  /* 企业文化 */
  .qywh {
    margin-top: 70px;
    box-sizing: border-box;
    overflow: hidden;
    .h1 {
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #010001;
      text-align: center;
      line-height: 70px;
      margin-bottom: 66px;
    }
    .wenhuaBox {
      display: flex;
      box-sizing: border-box;
      padding: 0 0 0 160px;
      .iconBox {
        width: 600px;
        height: 554px;
        box-sizing: border-box;
        box-shadow: 0px 5px 9px 0px rgba(0,0,0,0.21), 0px 22px 23px 0px rgba(0,0,0,0.03);
        border-radius: 12px;
        position: relative;
        padding: 61px 0 0 54px;
        .h2Box {
          display: flex;
          img {
            width: 44px;
            height: 45px;
            margin-right: 37px;
          }
          span {
            font-size: 32px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #C82227;
            line-height: 45px;
          }
        }
        .h3 {
          font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
          margin-top: 43px;
        }
        .hongqiBg {
          position: absolute;
          bottom: 0;
        }
      }
      .iconBox1 {
        width: 250px;
        height: 500px;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 61px 0 0 35px;
        .h2Box {
          display: flex;
          align-items: center;
          span {
            font-size: 22px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: bold;
            color: #000000;
            line-height: 30px;
            margin-left: 18px;
          }
        }
      }
      .iconBg {
        background-image: url(../assets/img/qiyejianjie/wenhua/hongqiBg1.png);
        background-position: 100%;
        background-size: 100% 100%;
      }
      .iconBg1 {
        background-image: url(../assets/img/qiyejianjie/wenhua/jiazhiBg1.png);
        background-position: 100%;
        background-size: 100% 100%;
      }
      .iconBg2 {
        background-image: url(../assets/img/qiyejianjie/wenhua/zhunzeBg1.png);
        background-position: 100%;
        background-size: 100% 100%;
      }
      .iconBg3 {
        background-image: url(../assets/img/qiyejianjie/wenhua/yjBg1.png);
        background-position: 100%;
        background-size: 100% 100%;
      }
      .iconBg4 {
        background-image: url(../assets/img/qiyejianjie/wenhua/yuanzeBg1.png);
        background-position: 100%;
        background-size: 100% 100%;
      }
      .animation1 {
        animation:fadenum 0.5s;
      }
      .animation2 {
        animation:reduceAn 0.5s;
      }
      @keyframes fadenum{
        0%{
          width:250px;
          opacity: 0;
          height: 500px;
        }
        100%{
          width:600px;
          height: 554px;
          opacity: 1;
        }
      }
      @keyframes reduceAn{
        100%{
          width:250px;
          opacity: 0;
          height: 500px;
        }
      }
    }
  }
  /* 企业发展 */
  .qyfz{
    overflow: hidden;
    margin-top: 80px;
    .h1 {
      text-align: center;
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #010001;
      line-height: 70px;
      margin-bottom: 93px;
    }
    .contentBox {
      width: 100%;
      height: 487px;
      background-image: url(../assets/img/qiyejianjie/yunBg.png);
      background-position: 100%;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .yearBox {
        width: 100%;
        display: block;
        margin: 0 auto;
        .line {
          width: 1600px;
          height: 24px;
          display: block;
          margin: 0 auto;
          background-image: url(../assets/img/qiyejianjie/yearBg.png);
          background-position: 100%;
          background-size: 100% 100%;
        }
        .yearLine {
          position: relative;
          img {
            position: absolute;
            top: -110px;
            left: 620px;
          }
          .contentText {
            position: absolute;
            top: -120px;
            left: 643px;
            p {
              font-size: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #C82227;
            }
            span {
              display: flex;
              align-items: center;
              em {
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #C82227;
                margin-right: 6px;
              }
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #C82227;
              line-height: 20px;
            }
          }
        }

        .yearLine1 {
          position: relative;
          img {
            position: absolute;
            top: 0;
            left: 918px;
          }
          .contentText {
            position: absolute;
            top: 77px;
            left: 938px;
            p {
              font-size: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #C82227;
            }
            span {
              display: flex;
              align-items: center;
              em {
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #C82227;
                margin-right: 6px;
              }
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #C82227;
              line-height: 20px;
            }
          }
        }

        .yearLine2 {
          position: relative;
          img {
            position: absolute;
            top: -200px;
            left: 1206px;
          }
          .contentText {
            position: absolute;
            top: -210px;
            left: 1230px;
            p {
              font-size: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #C82227;
            }
            span {
              display: flex;
              align-items: center;
              em {
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #C82227;
                margin-right: 6px;
              }
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #C82227;
              line-height: 20px;
            }
          }
        }

        .yearLine3 {
          position: relative;
          img {
            position: absolute;
            top: 0;
            left: 1486px;
          }
          .contentText {
            position: absolute;
            top: 77px;
            left: 1510px;
            p {
              font-size: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #C82227;
            }
            span {
              display: flex;
              align-items: center;
              em {
                display: block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #C82227;
                margin-right: 6px;
              }
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #C82227;
              line-height: 20px;
            }
          }
        }
      }
      .btnBox {
        position: absolute;
        margin-top: 80px;
        width: 1600px;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  /* 营销渠道 */
  .yxqd {
    overflow: hidden;
    width: 100%;
    height: 1159px;
    box-sizing: border-box;
    padding: 0 0 0 151px;
    position: relative;
    .h1 {
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #010001;
      line-height: 70px;
      text-align: center;
    }
    .content {
      position: relative;
      .cpt {
        margin-top: 206px;
      }
      .gansu {
        position: absolute;
        top: 116px;
        left: 695px;
        .WtDiaopoit {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background: #FFFFFF;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
          position: absolute;
          top: 50px;
          left: 50px;
          animation: myfirst 3s infinite;
        }
        em {
          display: block;
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 50%;
          opacity: 1;
          position: absolute;
          top: 55px;
          left: 55px;
        }
      }
      .chengdu {
        position: absolute;
        top: 380px;
        left: 768px;
        .WtDiaopoit {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background: #FFFFFF;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
          position: absolute;
          top: 100px;
          left: 100px;
          animation: myfirst 3s infinite;
        }
        em {
          display: block;
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 50%;
          opacity: 1;
          position: absolute;
          top: 105px;
          left: 105px;
        }
      }
      .guangdong {
        position: absolute;
        top: 653px;
        left: 1145px;
        .WtDiaopoit {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background: #FFFFFF;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
          position: absolute;
          top: 50px;
          left: 60px;
          animation: myfirst 3s infinite;
        }
        em {
          display: block;
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 50%;
          opacity: 1;
          position: absolute;
          top: 55px;
          left: 65px;
        }
      }
      .anhui {
        position: absolute;
        top: 361px;
        left: 1264px;
        .WtDiaopoit {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background: #FFFFFF;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
          position: absolute;
          top: 50px;
          left: 50px;
          animation: myfirst 3s infinite;
        }
        em {
          display: block;
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 50%;
          opacity: 1;
          position: absolute;
          top: 55px;
          left: 55px;
        }
      }
      .jiangsu {
        position: absolute;
        top: 342px;
        left: 1300px;
        .WtDiaopoit {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background: #FFFFFF;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
          position: absolute;
          top: 20px;
          left: 50px;
          animation: myfirst 3s infinite;
        }
        em {
          display: block;
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 50%;
          opacity: 1;
          position: absolute;
          top: 25px;
          left: 55px;
        }
      }
      .zhejiangMap {
        position: absolute;
        top: 459px;
        left: 1362px;
        .WtDiaopoit {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background: #FFFFFF;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
          position: absolute;
          top: 50px;
          left: 50px;
          animation: myfirst 3s infinite;
        }
        em {
          display: block;
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 50%;
          opacity: 1;
          position: absolute;
          top: 55px;
          left: 55px;
        }
      }
      .shanghai {
        position: absolute;
        top: 436px;
        left: 1434px;
        .WtDiaopoit {
          width: 33px;
          height: 33px;
          border-radius: 50%;
          background: #FFFFFF;
          display: flex;
          box-sizing: border-box;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
          position: absolute;
          top: 0;
          left: 0;
          animation: myfirst 3s infinite;
        }
        em {
          display: block;
          width: 22px;
          height: 22px;
          box-sizing: border-box;
          background: #FFFFFF;
          border-radius: 50%;
          opacity: 1;
          position: absolute;
          top: 5px;
          left: 5px;
        }
      }
      .mapTips {
        position: absolute;
        top: 41px;
        right: 140px;
        width: 350px;
        min-height: 200px;
        background: #FFFFFF;
        box-shadow: 0px 7px 20px 0px rgba(0,0,0,0.16);
        border-radius: 4px;
        box-sizing: border-box;
        padding: 22px 30px 0 31px;
        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .h2 {
            font-size: 22px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 30px;
          }
          .diaopoit {
            width: 16px;
            height: 16px;
            border: 3px solid #C82227;
            border-radius: 50%;
            animation: myfirst 3s infinite;
          }
        }
        span {
          display: inline-block;
          margin-top: 7px;
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #666666;
          line-height: 20px;
        }
        .cr0 {
          color: #000000;
          font-weight: bold;
        }
        .telBox {
          margin-top: 10px;
          display: flex;
          align-items: center;
          .tel {
            width: 13px;
            height: 14px;
          }
          .email {
            width: 14px;
            height: 14px;
            margin-left: 21px;
          }
          span {
            margin-top: 0;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
            line-height: 20px;
          }
        }
      }
    }
    .numBox {
      width: 100%;
      box-sizing: border-box;
      padding: 0 160px 0 0;
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-around;
      p {
        font-size: 60px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #C82227;
        line-height: 84px;
      }
      span {
        font-size: 30px;
        color: #000000;
        margin-left: 20px;
      }
    }
  }
}
@keyframes myfirst {
  /* 设置为百分50可以缩放 */
  50% {
    transform: scale(1.5);
  }
}
</style>