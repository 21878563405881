<template>
  <div class="jiandang">
    <div class="scllboom" v-if="syindex == 0">
      <div class="titleftlscll">
        <img src="../../assets/img/dangjianimg.png" class="titleimg" alt="" />
        <!-- <div class="imgclick">
          <img
            @click="imgclickjian"
            src="../../assets/img/zuohua.png"
            style="width: 35px"
            alt=""
          />
          <img
            @click="imgclickadd"
            src="../../assets/img/youhua.png"
            style="width: 35px"
            alt=""
          />
        </div> -->
      </div>
      <div class="titrighttlscll">
        <div class="rightop">
          <div class="rightopone">
            <p style="color: rgb(200, 36, 41)">2022</p>
            <p style="color: #aaa">11</p>
          </div>
          <div style="text-align: right; margin-top: 5px; color: #aaa">06</div>
        </div>
        <div class="righboom">
          中国共产党诞生101周年纪念日前夕，上海市工商联钢铁贸易商会冷轧镀锌分会及上海万鸿国际贸易有限公司党员等10人前往新华社通讯社中国金融信息中心参观”信仰铸忠诚，永远跟党走“1921年-1949年上海金融系统党的建设与革命斗争图片展，深刻感受红色印迹中的历史故事。
        </div>
      </div>
    </div>

    <div class="scllboom" v-if="syindex == 1">
      <div class="titleftlscll">
        <img src="../../assets/img/dangjianimg.png" class="titleimg" alt="" />
        <div class="imgclick">

          <img
            @click="imgclickjian"
            src="../../assets/img/zuohua.png"
            style="width: 35px"
            alt=""
          />
          <img
            @click="imgclickadd"
            src="../../assets/img/youhua.png"
            style="width: 35px"
            alt=""
          />
        </div>
      </div>
      <div class="titrighttlscll">
        <div class="rightop">
          <div class="rightopone">
            <p style="color: rgb(200, 36, 41)">2022</p>
            <p style="color: #aaa">11</p>
          </div>
          <div style="text-align: right; margin-top: 5px; color: #aaa">06</div>
        </div>
        <div class="righboom">2中国共产党诞生101周年纪念日前夕，上海市工商联钢铁贸易商会冷轧镀
锌分会及上海万鸿国际贸易有限公司党员等10人前往新华社通讯社中国
金融信息中心参观”信仰铸忠诚，永远跟党走”1921年-1949年上海金融
系统党的建设与革命斗争图片展，深刻感受红色印迹中的历史故事。</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      syindex: 0,
    };
  },

  mounted() {},
  methods: {
    imgclickjian() {
      if (this.syindex > 0) {
        this.syindex = this.syindex - 1;
      }
    },
    imgclickadd() {
      if (this.syindex < 1) {
        this.syindex = this.syindex + 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.jiandang {
  height: 572px;
  .scllboom {
    display: flex;
     align-content: center;
    max-width: 1600px;
    margin: 50px auto;
    height: 400px;
    // border: 1px solid red;
    .titleftlscll {
      // border: 1px solid red;
      .titleimg {
        // border: 1px solid red;
        width: 864px;
        height: 556px;
      }
      .imgclick {
        width: 200px;
        // border: 1px solid red;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;
       
      }
    }
    .titrighttlscll {
      padding: 10px 20px;
      width: 864px;
      // width: 400px;
      // border: 1px solid red;
      .rightop {
        box-shadow:10px 6px 30px 1px rgb(245,245,245);
        height: 60px;
        width: 100%;
        padding:  10px;
        box-sizing: border-box;
        .rightopone {
          // padding: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid rgb(200, 36, 41);
        }
      }
      .righboom {
        padding: 30px;
        box-shadow:10px 6px 30px 1px rgb(245,245,245);
        text-align: left;
        margin-top: 80px;
        color: #aaa;
        font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 60px;
      }
    }
  }
}
</style>
