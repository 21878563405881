<template>
  <div class="example-3d">
    <swiper class="swiper  swiper-no-swiping" :options="swiperOption">
      <swiper-slide v-for="(item, index) in imgArr" :key="index">
        <img
            :src="item.src"
            alt=""
        />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev" @click="prevBtn"></div>
      <div class="swiper-button-next" slot="button-next" @click="nextBtn"></div>
    </swiper>
    <div class="contentText">
      <p>{{ textArr[index].p }}</p>
      <span>{{ textArr[index].span }}</span>
      <b>{{ textArr[index].b }}</b>
      <em>联系我们：400-600-8891</em>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-example-3d-coverflow',
  title: '3D Coverflow effect',
  components: {
    Swiper,
    SwiperSlide
  },
  props:['imgList','SlideIndex','prevFlag'],
  data() {
    return {
      imgArr:[
        {
          src:require("../../assets/img/rcfz/rcfzSwiper.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper1.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper2.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper3.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper4.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper5.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper6.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper7.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper8.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper9.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper10.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper11.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper12.png")
        },
      ],
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        initialSlide :4,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: 870,
          depth: 100,
          modifier: 1,
          slideShadows : false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      textArr: [
        {
          p: '镀铝锌（GL）',
          span: '常用锌层150g/㎡（双面），其厚度达到40um；55%镀铝锌产品（镀层≥150g）一般有使用年限承诺。',
          b: '主要应用领域：建筑屋面、钢构檩条、轻钢龙骨、配电柜等结构产品。'
        },
        {
          p: '彩涂',
          span: '以冷轧钢板或镀锌钢板或镀铝锌钢板或锌铝镁钢板为基板，\n' +
              '经过表面预处理（脱脂、清洗、化学转化处理）；\n' +
              '以连续的方法涂上一层或多层液态涂料，经过烘烤和冷却所得彩色涂层钢板。',
          b: '主要应用领域：建筑 、家电、门窗、家具、交通运输。'
        },
        {
          p: '纯钛卷板',
          span: '要产品纯钛卷板的圆/方锭、无缝钛管、棒线材及相关加工件；\n' +
              '钛产品主要应用于医疗器材、植入用钛、航空航天装甲、\n' +
              '防护、海水淡化、海洋钻进平台、船舶及海洋装备、化工冶金等工业、\n' +
              '烟囱脱硫脱硝用钛及民用领域的广泛应用。',
          b: '主要应用领域：民用领域、烟囱脱硫脱硝用钛、化工冶金、海洋钻进平台、\n' +
              '船舶及海洋装备、海水淡化、航空航天装甲防护、医疗器材植入用钛。'
        },
        {
          p: '热轧',
          span: '用连铸板坯或初轧板坯作原料，经步进式加热炉加热，高压水除磷后进入粗轧机，\n' +
              '粗轧料经切头、尾、再进入精轧机，实施计算机 控制轧制，终轧后即经过层流冷\n' +
              '却（计算机控制冷却速率）和卷取机卷取、成为直发卷；其卷重较重、钢卷内径为760mm。',
          b: '主要应用领域：适用于一般结构钢和工程用热轧钢板、钢带，\n' +
              '可供焊管、冷轧料、自行车零件、以及重要焊接、铆接、栓接构件。'
        },
        {
          p: '冷轧',
          span: '用热轧钢卷为原料，经酸洗去除氧化皮后进行冷连轧，其成品为轧硬卷，由于连续冷变形引起的冷作硬化使轧硬卷的强度、硬度上升、韧塑指标下降，冲压性能恶化，只能用于简单变形的零件。轧硬卷可作为热镀锌厂的原料，因为热镀锌机组均设置有退火线。轧硬卷重一般在6~13.5吨，钢卷内径为610mm。',
          b: '主要应用领域：广泛应用于汽车制造、家电产品、仪表开关、建筑、\n' +
              '办公家具等行业。'
        },
        {
          p: '镀锌（GI）',
          span: '常用锌层275g/㎡（双面），其厚度为40um左右；\n' +
              '钢厂无使用年限承诺，客户可根据具体工程选择增减镀层使用。',
          b: '主要应用领域：工业檩条'
        }
      ],
      index: ''
    }
  },
  created() {
    this.swiperOption.initialSlide = this.$props.SlideIndex;
    this.index = this.$props.SlideIndex;
  },
  mounted() {
    this.imgArr = this.$props.imgList;
  },
  methods: {
    prevBtn() {
      --this.swiperOption.initialSlide
      this.index = this.swiperOption.initialSlide;
    },
    nextBtn() {
      ++this.swiperOption.initialSlide
      this.index = this.swiperOption.initialSlide;
    }
  }
}
</script>

<style lang="scss" scoped>
.example-3d {
  width: 100%;
  box-sizing: border-box;
  height: 765px;
  padding: 50px 100px 50px 10px;
  margin-bottom: 100px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
}

.swiper {
  height: 100%;
  width: 885px;
  overflow: initial;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 952px;
    height: 765px;
    text-align: center;
    font-weight: bold;
    img {
      width: 648px;
      height: 765px;
    }
  }
  .swiper-button-prev {
    top: 115%;
    left: 45%;
    width: 35px;
    height: 35px;
    background-image: url(../../assets/img/cpjs/leftBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next {
    top: 115%;
    right: 45%;
    width: 35px;
    height: 35px;
    background-image: url(../../assets/img/cpjs/rightBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next:after{
    content: none;
  }
  .swiper-button-prev:after{
    content: none;
  }
}
.contentText {
  width: 700px;
  display: flex;
  margin-left: 100px;
  box-sizing: border-box;
  padding-top: 30px;
  flex-direction: column;
  p {
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 37px;
    margin-bottom: 56px;
  }
  span {
    display: block;
    height: 341px;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 31px;
  }
  b {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
    margin: 28px 0 57px;
  }
  em {
    font-style: normal;
    display: block;
    width: 420px;
    height: 60px;
    background: #DA232E;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 60px;
    text-align: center;
    border-radius: 8px;
  }
}
</style>