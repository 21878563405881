<template>
  <div class="indexcenter">
    <div class="backimg">
        <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
       <div v-if="heardersw">
        <titletop />
       </div>
      <div class="optionbander">
        <div class="bigsix">致力于为清洁能源发电项目提供系统解决方案</div>
        <div class="smallsix">零碳建造-绿色生产</div>
      </div>
      <div class="backsmaimg">
       <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
       <div class="posionband">
        <div class="posionbando">甘肃盛世信恒 </div>
        <div class="posionbando">新能源科技责任公司</div>
        <div class="posionbandt" >鸿旗企业/企业简介/下属公司</div>
      </div>
     </div>
      </div>

      <div style="max-width: 1600px; margin: 100px auto;margin-bottom: 0 ;">
        <div  style="
          margin: 60px 0;
          margin-top: 200px;
          display: flex;
          justify-content: space-between;
          align-items: center;
        ">
         <img src="../assets/img/shengshi.png" alt="">  <div style="position: relative; left: -60px;"><img  src="../assets/img/shwenz.png" alt=""></div> 
        </div>
         
        <p style="margin: 0px 0; font-size: 26px; font-weight: 500;">关于我们</p>
       <p style="font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 40px;margin: 50px 0;">
        甘肃盛世信恒新能源科技有限责任公司，为清洁能源发电项目提供系统解决方案；
是一家专业生产太阳能光伏支架、光伏支架配件、及太阳能光伏支架的技术研发、加工制造、销售服务一体的现代化高新技术企业。
       </p>
       <p style="font-size: 26px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 50px;background: #2281C0; width: 333px;
height: 50px;
text-align: center;
background: #2281C0;
border-radius: 4px;">业务联系：13564877812</p>

<div  style="
       margin-top: 400px;
          display: flex;
          height:518px;
          justify-content: space-between;
          align-items: center;
        ">
         <div style="position: relative; left: -60px;"><img  src="../assets/img/shwenz.png" alt=""></div> 
        <div style="position: relative; left: 94px;top: -182px; z-index: 10;"><img  src="../assets/img/xaihua.png" alt=""></div> 
        <div style="position: relative; left: -60px;top: -130px;z-index: 9;"><img  src="../assets/img/xaiju.png" alt=""></div> 
        <div style="position: relative; left: -172px;top: -10px;z-index: 8;"><img  src="../assets/img/xaijusd.png" alt=""></div> 
       
        </div>
    




      </div>
    
      <div class="backnewsh">
      <p style="max-width: 1600px;
height: 120px;
font-size: 22px;
margin: 0 auto;
padding-top: 80px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #FFFFFF;
line-height: 50px;">盛世信恒拥有13000平方米生产厂区，采用行业先进设备，专业型钢及打孔生产线15条，冲压机床21台。

拥有独立的货物储存空间可供周转，充足的运行设备保障生产周期，公司项目辐射工程甘、新、青、藏、蒙等多个地区。
</p>
  </div>
    
 
    <div>
      <p style="font-size: 46px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 47px;text-align: center;margin: 80px 0;">
        产品介绍
      </p>
      <div>
        <div class="zhongzi">
  <div class="left">
    
    <chanpin />
  </div>
<!--  <div class="right">
     <p class="rightop">太阳能光伏支架-锌铝镁光伏支架</p>
     <div class="righcen">
  <p class="righcentext">原材料主要以酒钢优质锌铝镁资源为主， </p>
  <p class="righcentext">  产品同等厚度下是纯锌镀层防腐性能15倍， </p>
  <p class="righcentext"> 其耐腐蚀性能高、自修复、长寿命、易加工，成本优、</p>
  <p class="righcentext">供货周期短更好地满足光伏行业的需求。</p>
     </div>
     <p class="righbomcp">联系我们:13564877812</p>
  </div>-->
      </div>
  
      </div>
    </div>


   
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import chanpin from '../components/shehui/chanpin.vue'
export default {
data(){
 return{
  heardersw:false,
 }
},
mounted(){
 console.log(this.$store.state.currPage);

},
methods:{

  showhearder(){
    this.heardersw =! this.heardersw
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
  chanpin,
 
}
}
</script>
<style lang="scss" scope>
 .zhongzi{
  max-width: 1600px;
  margin: 100px auto;
  display: flex;
  align-items: center;
  .right{
   margin-left: 80px;
   .rightop{
    font-size: 26px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 37px;
   }
   .righcen{
    margin: 100px 0;
   }
   .righcentext{
    font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 60px;
   }
   .righbomcp{
    font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
line-height: 45px;
background: rgb(34,129,192);
padding: 10px;
width: 356px;
height: 45px;
color: white;
   }
  }
 }
.backnewsh{
  width: 100%;
  height: 314px;
  background-image: url(../assets/img/ssbackdws.png);
  background-size: 100% 100%;
}

.animationone{
  animation:1s fadeInUp;
  }
  .animationtwo{
  animation:3s fadeInUp;
  }
   .animationthree{
  animation:4s fadeInUp;
  }


.indexcenter{
  overflow-x: hidden;
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/ssbanner.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
  border-radius: 8px;
background-image: url(../assets/img/ssdadaoh.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 40px;
  color: white;
  .posionbando{
    font-size: 50px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 74px;
  }
  .posionbandt{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
margin-top: 20px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 232px;
  color: white;
  .bigsix{
    font-size: 60px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 89px;
letter-spacing: 12px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}


}

</style>