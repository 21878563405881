<template>
  <div class="indexcenter">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
        <div v-if="heardersw">
        <titletop />
       </div>
    <div class="optionbander">
      <div class="bigsix">寻同路者 登峰见海</div>
      <!-- <div class="smallsix" >为行业与社会做出巨大贡献的公司</div> -->
    </div>
    <div class="backsmaimg">
     <div class="posionband">
      <div class="posionbando">合伙人制</div>
      <div class="posionbandt" >让员工幸福/心力资源/人才招聘</div>
    </div>
   </div>
    </div>

    <div class="banitem">
      <p class="banitemtitle">
        人才招聘
      </p>
   
   
     
      <div class="baniteitem">
   <p class="iteitemone iteitemonered">
    合伙人/Partner
   </p>
   <p class="iteitemtwo">
    共同奋斗，共同分享， 拥抱变化，彼此成就
   </p>
   <p class="iteitemtwo">
    岗位职责:
   </p>
   <p class="payitem">
    1.根据集团战略目标及行业发展趋势，制定区域总体市场策略并负责实施。
   </p>
   <p class="payitem">
    2.组建区域市场营销团队，根据集团战略制定区域经营增长策略，带领团队实施。
   </p>
   <p class="payitem">
    3.总部和各分公司给予产品品牌宣传、业务培训、市场活动等支持，开拓市场，发现新客户群体，维护现有客户长期稳定发展。
   </p>
   <p class="iteitemtwo">
    岗位职责:
   </p>
   <p class="payitem">
    1、有带领团队奋进的梦想与激情；
   </p>
   <p class="payitem">
    2、能独立或带领团队负责该区域的销售和服务。
   </p>
   <p class="payitem">
    3、有一定的市场认知、市场分析能力和战略思维，有丰富人脉资源，有一定的销售渠道者优先。
   </p>
   <p class="payitem">
    4、全国各地不限地域。
   </p>
   <div class="iteitemthreeww">
    <p class="itemleft">
      *应聘请将简历发送至：worldhonorhr@163.com（邮件标题注明应聘职位）
    </p>
    <div class="itemright" @click="addaply">
      <img src="../assets/img/jiahaoplay.png" alt="">
      <span class="text">申请职位</span>
    </div>
   </div>
      </div>
    </div>
    

 


   
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
export default {
data(){
 return{
  heardersw:false,
 }
},
mounted(){
 console.log(this.$store.state.currPage);

},
methods:{
  addaply(){
    this.$router.push({ path: '/positioninfo'});
  },
  showhearder(){
    this.heardersw =! this.heardersw
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
}
}
</script>
<style lang="scss" scope>

 
 
.banitem{
  margin: 0 auto;
  margin-top: 180px;
  max-width: 1600px;
  // border: 1px solid red;
 .zhongzi{
  max-width: 1600px;
  margin: 100px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
 }
 .jinzita{
  max-width: 1273px;
  margin: 100px auto;
  // border: 1px solid red;
 }
  .banitemtitle{
    width: 128px;
height: 48px;
font-size: 32px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 47px;
padding-bottom: 10px ;
  border-bottom: 6px solid rgb(200,34,39);
  margin-bottom: 20px;
  }
  .baniteitem{
    padding-bottom: 30px;
    // border-bottom: 2px solid #979797;
    margin: 80px 0;
    .iteitemone{
      font-size: 32px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #010001;
line-height: 45px;
    }
    .iteitemonered{
      color: #C82227;
      font-weight: 500;
    }
    .iteitemtwo{
      font-size: 22px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #000000;
line-height: 30px;
margin: 30px 0;
    }
    .payitem{
      font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #676767;
margin: 30px 0;
    }
    .iteitemthreeww{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 80px;
      .itemleft{
        font-size: 22px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
color: #000000;
line-height: 30px;

      }
      .itemright{
        background:#C82227 ;
        display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 30px;
      .text{
        font-size: 22px;
font-family: PingFangSC-Medium, PingFang SC;
font-weight: 500;
line-height: 30px;
margin-left: 20px;
color: white;

      }
      }
    }
  }
}


.indexcenter{
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/banbanne.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
  border-radius: 8px;
background-image: url(../assets/img/bigbanhder.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 80px;
  color: white;
  .posionbando{
    font-size: 50px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 74px;
  }
  .posionbandt{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
margin-top: 20px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 40%;
  color: white;
  .bigsix{
    font-size: 42px;
font-family: LiSungLight;
color: #000000;
line-height: 42px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}


}

</style>