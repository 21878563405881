<template>
  <div class="supplychain">
    <div class="backimg">
      <img
        src="../assets/img/hearder.png"
        alt=""
        class="hearderimg"
        @click="showhearder"
      />
      <div v-if="heardersw">
        <titletop />
      </div>
      <div class="optionbander">
        <div class="bigsix">新一代钢贸服务解决方案</div>
        <div class="smallsix">以服务打造新业务增长极</div>
      </div>
      <div class="backsmaimg">
        <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
        <div class="posionband">
          <div class="posionbando">供应链服务/鸿旗普惠</div>
          <div class="posionbandt">让客户感动</div>
        </div>
      </div>
    </div>

    <div style="max-width: 1600px; margin: 100px auto">
      <div style="margin-top: 300px">
        <div style="text-align: center">
          <p
            style="
              font-size: 50px;
              font-weight: 500;
              color: #000000;
              line-height: 70px;
              margin-bottom: 60px;
            "
          >
            鸿旗承诺
          </p>
          <p
            style="
              font-size: 32px;
              font-weight: 500;
              color: #000000;
              line-height: 45px;
              letter-spacing: 2px;
            "
          >
            我们坚信：品质+服务=信心
          </p>
          <div
            style="
              padding-bottom: 50px;
              box-shadow: 1px 30px 30px 20px rgb(246, 246, 246);
            "
          >
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 1000px;
                margin: 100px auto;
                border-bottom: 1px solid rgb(200, 200, 200);
                padding: 100px 200px;
                padding-bottom: 100px;
              "
            >
              <img src="../assets/img/pinzh.png" alt="" />
              <img src="../assets/img/fuwuj.png" alt="" />
              <img src="../assets/img/xinxin.png" alt="" />
            </div>
            <p
              style="
                width: 1092px;
                height: 72px;
                font-size: 18px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #000000;
                line-height: 36px;
                letter-spacing: 2px;
                margin: 0 auto;
              "
            >
              *保证提供的产品均采用优质的原材料，确保材料经过严格的进出料检验，确保完全符合国际质量标准和满足产品性能要求；
              如客户发现我司材料技术品质低于原产厂家质保书要求，我司将在第一时间给予退换货，并承担相应损失。
            </p>
          </div>
        </div>
      </div>

      <div
        style="margin: 200px 0; display: flex; justify-content: space-between"
      >
        <img src="../assets/img/shouh.png" alt="" />
        <div>
          <p
            style="
              font-size: 50px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 70px;
              letter-spacing: 3px;
              margin: 50px 0;
            "
          >
            售后宗旨
          </p>
          <p
            style="
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 45px;
              letter-spacing: 2px;
            "
          >
            精诚服务、贴心跟踪
          </p>
          <p
            style="
              width: 809px;
              height: 120px;
              font-size: 22px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
              line-height: 30px;
              letter-spacing: 1px;
              margin-top: 50px;
            "
          >
            产品使用中出现质量异议，接到投诉迅速响应：江浙沪地区4-10小时内到达，
            其他区域24-48小时内到达；处理完毕后，随时跟进处理结果，确保产品稳定可靠使用，以最大的诚意满足客户需求。
          </p>
        </div>
      </div>
      <div
        style="margin: 200px 0; display: flex; justify-content: space-between"
      >
      
        <div>
          <p
            style="
              font-size: 50px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 70px;
              letter-spacing: 3px;
              margin: 50px 0;
            "
          >
          物流
          </p>
          <p
            style="
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 45px;
              letter-spacing: 2px;
            "
          >
          陆运、海运、火车运输
          </p>
          <p
            style="
              width: 809px;
              height: 120px;
              font-size: 22px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
              line-height: 30px;
              letter-spacing: 1px;
              margin-top: 50px;
            "
          >
          优质的物流公司：30家以上的物流单位配合服务。<br/>
优势的配送服务：陆运、海运、火车运输。<br/>
以客户为中心 : 根据交期需求7天24小时为客户运输。
          </p>
        </div>
        <img src="../assets/img/yunsh.png" alt="" />
     
      </div>
    
      <div
        style="margin: 200px 0; display: flex; justify-content: space-between"
      >
        <img src="../assets/img/cangchu.png" alt="" />
        <div>
          <p
            style="
              font-size: 50px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 70px;
              letter-spacing: 3px;
              margin: 50px 0;
            "
          >
          仓储
          </p>
          <p
            style="
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 45px;
              letter-spacing: 2px;
            "
          >
          精诚服务、贴心跟踪
          </p>
          <p
            style="
              width: 809px;
              height: 120px;
              font-size: 22px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
              line-height: 30px;
              letter-spacing: 1px;
              margin-top: 50px;
            "
          >
          8个服务中心、10大仓库覆盖天津、甘肃、成都、湖南、佛山、
重庆、广西、苏州、南通、上海等地多区域、多层次网络。
          </p>
        </div>
      </div>
     
      <div
        style="margin: 200px 0; display: flex; justify-content: space-between"
      >
     
        <div>
          <p
            style="
              font-size: 50px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 70px;
              letter-spacing: 3px;
              margin: 50px 0;
            "
          >
          加工
          </p>
          <p
            style="
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 45px;
              letter-spacing: 2px;
            "
          >
          纵剪分条等全流程解决方案
          </p>
          <p
            style="
              width: 809px;
              height: 120px;
              font-size: 22px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
              line-height: 30px;
              letter-spacing: 1px;
              margin-top: 50px;
            "
          >
          即能保证高性价比的产品，又能在服务方面保证具
有专业化、标准化、人性化和周到的服务。

          </p>
        </div>
        <img src="../assets/img/jgong.png" alt="" />
   
      </div>
   
   
      <div
        style="margin: 200px 0; display: flex; justify-content: space-between"
      >
        <img src="../assets/img/zhaopin.png" alt="" />
        <div>
          <p
            style="
              font-size: 50px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #000000;
              line-height: 70px;
              letter-spacing: 3px;
              margin: 50px 0;
            "
          >
          鸿旗普惠
          </p>
          <p
            style="
              font-size: 32px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #000000;
              line-height: 45px;
              letter-spacing: 2px;
            "
          >
          助力实体经济发展
          </p>
          <p
            style="
              width: 809px;
              height: 120px;
              font-size: 22px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #666666;
              line-height: 30px;
              letter-spacing: 1px;
              margin-top: 50px;
            "
          >
          鸿旗普惠是上海鸿旗企业发展集团有限公司联合集团公司旗下各业务分公司开展的供应链金融、融资担保、融资咨询、融资租赁、银行助贷等业务的总称。鸿旗惠普致力于协助钢铁行业以及钢铁延展行业广大小微型企业、企业主、个体工商户以及钢铁行业从业人员获得专业借款服务，助力中国实体经济的发展。
          </p>
          <p style="color: #EE0000; font-size: 22px;margin-top: 50px;">
            *如果您有相关需求，请提交基本信息至：worldhonorhr@163.com
          </p>
        </div>
      </div>
   
   
    </div>

    <Foot />
  </div>
</template>
<script>
import Foot from "../components/footToo.vue";
import titletop from "../components/titletop.vue";
export default {
  data() {
    return {
      heardersw: false,
    };
  },
  mounted() {
    console.log(this.$store.state.currPage);
  },
  methods: {
    showhearder() {
      this.heardersw = !this.heardersw;
    },
  },
  name: "App",
  components: {
    Foot,
    titletop,
  },
};
</script>
<style lang="scss" scope>
.animationone {
  animation: 1s fadeInUp;
}
.animationtwo {
  animation: 3s fadeInUp;
}
.animationthree {
  animation: 4s fadeInUp;
}

.supplychain {
  overflow: hidden;
  .backimg {
    // border: 1px solid red;
    height: 600px;
    background-image: url(../assets/img/dbanner.png);
    background-position: 100%;
    background-size: 100% 100%;
    position: relative;
  }
  .hearderimg {
    width: 29px;
    height: 21px;
    position: absolute;
    right: 50px;
    top: 20px;
  }
  .showhearder {
    padding: 50px;
    padding-top: 100px;

    width: 345px;
    height: 608px;
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: white;
    z-index: 10;
    .heaitem {
      // border: 1px solid red;
      display: flex;
      align-items: center;
      margin: 30px 0;
    }
  }
  .liactive {
    color: rgb(230, 82, 86);
  }
  .backsmaimg {
    width: 36%;
    height: 300px;
    border-radius: 8px;
    background-image: url(../assets/img/bigbanhder.png);
    background-position: 50%;
    background-repeat: no-repeat;
    position: absolute;
    bottom: -100px;
    right: 220px;
    .posionband {
      position: absolute;
      // border: 1px solid red;
      left: 100px;
      top: 80px;
      color: white;
      .posionbando {
        font-size: 50px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 74px;
      }
      .posionbandt {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 27px;
        margin-top: 20px;
      }
    }
  }
  .optionbander {
    position: absolute;
    top: 239px;
    left: 232px;
    color: white;
    .bigsix {
      font-size: 60px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 89px;
      letter-spacing: 12px;
    }
    .smallsix {
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 27px;
      letter-spacing: 6px;
      margin-top: 20px;
    }
  }
}
</style>