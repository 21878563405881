<template>
  <div class="sahnpinye">
    <div class="backimg">
      <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
      <div v-if="heardersw">
      <titletop />
     </div>
      <div class="optionbander">
      <div class="bigsix">服务百万企业 触达亿万用户</div>
      <div class="smallsix">产品第一 简单可信</div>
    </div>
      <div class="backsmaimg">
      <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
      <div class="posionband">
      <div class="posionbando">产品介绍/核心服务商</div>
      <div class="posionbandt" >让客户感动</div>
    </div>
   </div>
    </div>
    <!-- 核心服务商 -->
    <div style="max-width: 1600px; margin: 100px auto 0" id="hxfws">
      <div  style="
        margin-top: 200px;

      ">
         <p style="width: 162px;
height: 48px;
font-size: 32px;
font-family: AppleSystemUIFont;
color: #010001;
line-height: 47px;border-bottom: 4px solid #C82227;padding-bottom: 10px;">核心服务商<a href="#hzkh" style="width: 100%;display: block;height: 100%;"></a></p>
      </div>
         <img style="margin: 100px 0;" src="../assets/img/hxfws.png" alt="">

    </div>
    <!-- 产品介绍 -->
    <div class="cpjs">
<!--      <div class="headerBox">
        <div class="leftBox">
          <p>产品介绍</p>
          <em></em>
        </div>
        <div class="btnBox">
          <img src="../assets/img/cpjs/leftBtn.png" alt="">
          <img src="../assets/img/cpjs/rightBtn.png" alt="">
        </div>
      </div>
      <div class="content">
        <ul>
          <li class="maxWidth">
            <p>
              锌铝镁合金镀层由锌（Zn）、铝（Al）、
              镁（Mg）高温固化形成，其显微结构由Zn、
              Al和Zn2Mg的致密三元共晶组织构成，从而
              使钢板表面形成一层致密的、有效防止腐蚀
              因子穿透的屏障。与普通镀锌钢板相比，其
              质量特性优点主要体现在超耐蚀、自修复、
              长寿命、易加工四个方面。主打产品中的
              钢旗锌铝镁彩涂SZAMPPG  、钢旗锌铝镁
              彩涂SZAMSTR、PVDF覆膜板更是广泛应用
              于对耐腐蚀需求较高的行业和高端建筑。
            </p>
            <span>钢旗3镁11铝不锈锌铝镁</span>
          </li>
          <li class="maxWidth"></li>
          <li class="maxWidth"></li>
        </ul>
      </div>-->
      <swiperQiye2
          :img-list="imgArr"
          :slide-index="initialSlide"
      />
    </div>
    <!-- 项目案例 -->
    <div class="xmal">
      <div class="headerBox">
        <div class="leftBox">
          <p>项目案例</p>
          <em></em>
        </div>
      </div>
      <swiperQiye
          :img-list="imgList"
          :slide-index="initialSlide"
          @childEvent="getChildData"
      />
    </div>
    <!-- 战略合作客户 -->
    <div class="hzkh" id="hzkh">
      <div class="headerBox">
        <div class="leftBox">
          <p>战略合作客户</p>
          <em></em>
        </div>
      </div>
      <div class="contentBox">
        <ul>
          <li>
            <p @click="showTip('1')" :class="[index == '1' ? 'fz22' : '']">
              江西晶科光伏材料有限公司
            </p>
            <span v-if="index == '1'">
              江西晶科光伏材料有限公司成立于2010年<br/>
              注册资金1亿元人民币，是一家专业从事太阳能<br/>
              系统配套材料研发、生产与销售的高新技术企业。
            </span>
          </li>
          <li>
            <p @click="showTip('2')" :class="[index == '2' ? 'fz22' : '']">隆基绿能科技股份有限公司</p>
            <span v-if="index == '2'">
              隆基绿能科技股份有限公司，致力于成为全球最具价值的太阳能科技公司。<br/>
              隆基绿能聚焦科技创新，构建单晶硅片、电池组件、工商业分布式解决方案、绿色能源解决方案、氢能装备五大业务板块。形成支撑全球零碳发展的“绿电”+“绿氢”产品和解决方案。
            </span>
          </li>
          <li>
            <p @click="showTip('3')" :class="[index == '3' ? 'fz22' : '']">江苏中信博新能源科技股份有限公司</p>
            <span v-if="index == '3'">
              江苏中信博新能源科技股份有限公司，主营业务为光伏跟踪支架、固定支架及BIPV系统的研发、设计、生产和销售，是一家世界领先的光伏跟踪支架、固定支架及BIPV系统制造商和解决方案提供商。2020年8月，中信博成功登陆中国A股科创板。
            </span>
          </li>
          <li>
            <p @click="showTip('4')" :class="[index == '4' ? 'fz22' : '']">天合光能股份有限公司</p>
            <span v-if="index == '4'">
              天合光能股份有限公司业务覆盖光伏组件的研发、生产和销售，电站及系统产品，光伏发电及运维服务、智能微网及多能系统的开发和销售以及能源云平台运营等，致力于成为全球光伏智慧能源解决方案的领导者，助力新型电力系统变革，创建美好零碳新世界。
            </span>
          </li>
          <li>
            <p @click="showTip('5')" :class="[index == '5' ? 'fz22' : '']">博思格钢铁集团</p>
            <span v-if="index == '5'">
              博思格钢铁集团是全球领先的高品质金属镀面和彩涂钢铁产品的供应商，也是全球最大的预制钢结构建筑制造商之一。博思格中国拥有金属镀面和彩涂钢卷、来实建筑系统以及巴特勒预制钢结构建筑系统，目前在国内拥有4个世界级的生产厂，约1300名员工，在国内建立了覆盖极其广泛的销售网络。
            </span>
          </li>
          <li>
            <p @click="showTip('6')" :class="[index == '6' ? 'fz22' : '']">河南牧原集团</p>
            <span v-if="index == '6'">
              牧原食品股份有限公司始创于1992年，2014年上市。现已形成集饲料加工、生猪育种、生猪养殖、屠宰加工为一体的猪肉产业链。牧原始终秉承“让人们吃上放心猪肉”的愿景，致力于为社会生产安全、美味、健康的高品质猪肉食品，提升大众生活品质，让人们享受丰盛人生。
            </span>
          </li>
        </ul>
        <div class="imgBox" v-if="index == '1'">
          <img src="../assets/img/cpjs/SCCbg.png" alt="">
          <img src="../assets/img/cpjs/SCCbg-z.png" class="iconImg" alt="">
          <p @click="toCR('1')">访问江西晶科光伏材料有限公司></p>
        </div>
        <div class="imgBox" v-if="index == '2'">
          <img src="../assets/img/cpjs/SCCbg1.png" alt="">
          <img src="../assets/img/cpjs/SCCbg1-z.png" class="iconImg" alt="">
          <p @click="toCR('2')">隆基绿能科技股份有限公司></p>
        </div>
        <div class="imgBox" v-if="index == '3'">
          <img src="../assets/img/cpjs/SCCbg2.png" alt="">
          <img src="../assets/img/cpjs/SCCbg2-z.png" class="iconImg" alt="">
          <p @click="toCR('3')">江苏中信博新能源科技股份有限公司></p>
        </div>
        <div class="imgBox" v-if="index == '4'">
          <img src="../assets/img/cpjs/SCCbg3.png" alt="">
          <img src="../assets/img/cpjs/SCCbg3-z.png" class="iconImg" alt="">
          <p @click="toCR('4')">天合光能股份有限公司></p>
        </div>
        <div class="imgBox" v-if="index == '5'">
          <img src="../assets/img/cpjs/SCCbg4.png" alt="">
          <img src="../assets/img/cpjs/SCCbg4-z.png" class="iconImg" alt="">
          <p @click="toCR('5')">博思格钢铁集团></p>
        </div>
        <div class="imgBox" v-if="index == '6'">
          <img src="../assets/img/cpjs/SCCbg5.png" alt="">
          <img src="../assets/img/cpjs/SCCbg5-z.png" class="iconImg" alt="">
          <p @click="toCR('6')">河南牧原集团></p>
        </div>
      </div>
    </div>
 
    


   
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import swiperQiye from '../components/shehui/swiperQiye.vue'
import swiperQiye2 from '../components/shehui/swiperQiye2.vue'
export default {
data(){
 return{
   heardersw:false,
   imgList: [
     {
       src:require("../components/shehui/img/project/projectBg.png")
     },
     {
       src:require("../components/shehui/img/project/projectBg1.png")
     },
     {
       src:require("../components/shehui/img/project/projectBg2.png")
     },
     {
       src:require("../components/shehui/img/project/projectBg3.png")
     },
     {
       src:require("../components/shehui/img/project/projectBg4.png")
     },
     {
       src:require("../components/shehui/img/project/projectBg5.png")
     },
     {
       src:require("../components/shehui/img/project/projectBg6.png")
     },
     {
       src:require("../components/shehui/img/project/projectBg7.png")
     },
   ],
   imgArr: [
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg.png")
     },
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg1.png")
     },
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg2.png")
     },
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg3.png")
     },
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg4.png")
     },
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg5.png")
     },
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg6.png")
     },
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg7.png")
     },
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg8.png")
     },
     {
       src:require("../assets/img/cpjs/cpjsBg/cpjsBg9.png")
     },
   ],
   initialSlide: 0,
   prevFlag: false,
   index: '1',
   id: ''
 }
},
created() {
  this.id = this.$route.query.id;
  console.log(this.id);
},
mounted(){
  var hash = window.location.hash;
  var index = hash.lastIndexOf("#");
  if (index != -1) {
    var id = hash.substring(index + 1, hash.length + 1);
    var div = document.getElementById(id);
    if (div) {
      setTimeout(function () {
        console.log(window.document.body);
        window.document.body.animate({scrollTop: window.document.body.div.offset().top - 43}, 500)
      }, 500);
    }
  }
  console.log(this.$store.state.currPage);
},
methods:{
  getChildData(data) {
    console.log(data);
  },
  showhearder(){
    this.heardersw =! this.heardersw
  },
  showTip(index) {
    this.index = index;
  },
  toCR(index) {
    let url;
    switch (index) {
      case '1':
        url = 'https://www.jinkopvm.com';
        break;
      case '2':
        url = 'https://www.longi.com';
        break;
      case '3':
        url = 'https://www.arctechsolar.com';
        break;
      case '4':
        url = 'https://www.trinasolar.com';
        break;
      case '5':
        url = 'https://www.bluescope.com';
        break;
      case '6':
        url = 'https://www.muyuanfoods.com';
        break;
    }
    window.open(url);
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
  swiperQiye,
  swiperQiye2
}
}
</script>
<style lang="scss" scope>


.animationone{
  animation:1s fadeInUp;
  }
  .animationtwo{
  animation:3s fadeInUp;
  }
   .animationthree{
  animation:4s fadeInUp;
  }

li {
  list-style: none;
}
.sahnpinye{
  overflow: hidden;
.backimg{
// border: 1px solid red;
height: 600px;
background-image: url(../assets/img/dbanner.png);
background-position: 100%;
background-size: 100% 100%;
position: relative;
}
.hearderimg{
width: 29px;
height: 21px;
position: absolute;
right: 50px;
top: 20px;
}
.showhearder{
padding: 50px ;
padding-top: 100px;

width: 345px;
height: 608px;
position: absolute;
right: 0px;
top: 0px;
background-color: white;
z-index: 10;
.heaitem{
// border: 1px solid red;
display: flex;
align-items: center;
margin: 30px 0;

}

}
.liactive{
  color: rgb(230,82,86);
}
.backsmaimg{
width: 36%;
height: 300px;
  border-radius: 8px;
background-image: url(../assets/img/bigbanhder.png);
background-position: 50%;
background-repeat: no-repeat;
position: absolute;
bottom: -100px;
right: 220px;
.posionband{
  position: absolute;
  // border: 1px solid red;
  left: 100px;
  top: 80px;
  color: white;
  .posionbando{
    font-size: 50px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 74px;
  }
  .posionbandt{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
margin-top: 20px;
  }
}
}
.optionbander{
  position: absolute;
  top: 239px;
  left: 232px;
  color: white;
  .bigsix{
    font-size: 60px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 89px;
letter-spacing: 12px;
  }
  .smallsix{
    font-size: 18px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #FFFFFF;
line-height: 27px;
letter-spacing: 6px;
    margin-top: 20px;
  }
}


  .headerBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 160px;
    margin-bottom: 96px;
    .leftBox {
      p {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #010001;
        line-height: 47px;
      }
      em {
        margin-top: 14px;
        width: 126px;
        height: 6px;
        background: #C82227;
        display: block;
      }
    }
    .btnBox {
      img:nth-child(1) {
        margin-right: 28px;
      }
    }
  }
  .content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0 0 160px;
    overflow: hidden;
    ul {
      width: 10000px;
      li {
        width: 400px;
        float: left;
        height: 504px;
        background-image: url(../assets/img/cpjs/cpjsBg.png);
        background-position: 100%;
        background-size: 100% 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding: 78px 35px 0 0;
        margin-right: 80px;
        p {
          width: 354px;
          height: 304px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 30px;
        }
        span {
          display: block;
          text-align: left;
          margin-top: 20px;
          width: 354px;
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 45px;
        }
      }
      .maxWidth {
        width: 801px;
      }
    }
  }
  .xmal {
    margin-top: 105px;
  }
  .hzkh {
    .contentBox {
      box-sizing: border-box;
      padding: 0 160px;
      display: flex;
      justify-content: space-between;
      li {
        p {
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #666666;
          line-height: 30px;
          margin-bottom: 27px;
        }
        .fz22 {
          font-size: 22px;
          color: #000000;
        }
        span {
          display: block;
          width: 369px;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #666666;
          line-height: 30px;
          margin: 9px 0 36px;
        }
      }
      .imgBox {
        display: flex;
        flex-direction: column;
        margin-bottom: 66px;
        .iconImg {
          width: 282px;
          height: 97px;
          margin-top: 5px;
        }
        p {
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #C82227;
          line-height: 30px;
        }
      }
    }
  }
}

</style>