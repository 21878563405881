<template>
  <div class="swiperBox">
    <div class="banner-card">
      <ul class="ul">
       
        
        <li>
          <img
            src="./img/qiyegongxue/qiyeBg1.png"
            alt=""
          />
        </li>
        <li>
          <img
            src="./img/qiyegongxue/qiyeBg.png"
            alt=""
          />
         
        </li>
        <li>
          <img
            src="./img/qiyegongxue/qiyeBg2.png"
            alt=""
          />
        </li>
       
      </ul>
       
    </div>

    <div class="left-btn" @click="leftMove()">
      <img src="../../components/shehui/img/staffImg/leftBtn.png" alt="">
    </div>
    <div class="right-btn" @click="rightMove()">
      <img src="../../components/shehui/img/staffImg/rightBtn.png" alt="">
    </div>
    <div v-if="yuankacti=='one'"  style="padding:10px 20px;opacity: 0.9; box-sizing: border-box; z-index: 99999;width: 300px;height: 192px; position: relative;top: -370px;left: 572px;background: white;border-left: 4px solid red;">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <p class="wanjin">学习型企业</p>
              <p class="diaopoit"></p>
            </div>
            <div class="jinjinboom">
             企业共学,通过团队公司与公司共同学习的方式,让企业员工都能做到自我管理,让企业为和员工保持良好的知识同频,让员工可以到工作中提升自驱力,让员工都成为管理者,企业和员工处于一体。
            </div>
          </div>
          <div v-if="yuankacti=='two'"  style="padding:10px 20px;opacity: 0.9; box-sizing: border-box; z-index: 99999;width: 300px;height: 140px; position: relative;top: -360px;left: 572px;background: white;border-left: 4px solid red;">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <p class="wanjin">知行合一社成立</p>
              <p class="diaopoit"></p>
            </div>
            <div class="jinjinboom">
              2021年 鸿旗集团成立上海阳明心学知行合一社阳明鸿志书院。
            </div>
          </div>
          <div v-if="yuankacti=='three'"  style="padding:10px 20px;opacity: 0.9; box-sizing: border-box; z-index: 99999;width: 300px;height: 162px; position: relative;top: -360px;left: 572px;background: white;border-left: 4px solid red;">
            <div style="display: flex;justify-content: space-between;align-items: center;">
              <p class="wanjin">企业共学</p>
               <p class="diaopoit"></p>
            </div>
            <div class="jinjinboom">
              2022年 鸿旗集团、和合祥生态文化、上海多尔利服饰3家公司共学阳明心学100天。
            </div>
          </div>
  </div>
</template>

<script>
import quanpoit from '../shehui/quanpoit.vue';
  export default {
  components: { quanpoit },
  data() {
    return {
      default: {
        //默认值
        classArray: ["one", "two", "three"],
        leftSlider: true,
      },
      bannerWrap: null,   
      time: null,
      lisArray: null,   //卡片的集合
      classlist: null,
      yuankacti:'one',
    
    };
  },
  methods: {
    constructor() {
      this.bannerWrap = document.getElementsByClassName("banner-card")[0];  
      let lis = this.bannerWrap.getElementsByTagName("li");  
      // 将类数组转化为数组
      this.lisArray = Array.from(lis);  
      this.classlist = this.default.classArray;
      //获取左右按钮
      this.move(this.classlist);
      this.timer();
      this.listenMouseEvent();
    },
    //移动
    move(list) {
      this.lisArray.forEach((value, index) => {
        value.setAttribute("class", list[index]); //给每一个li添加对应的类名
        this.yuankacti = list[0]
      });
    },
    // 向左运动
    leftMove() {
      let popValue = this.classlist.pop(); //删除并返回数组的最后一个       
      this.classlist.unshift(popValue); //把刚才删除的最后一个添加到数组开头
      this.move(this.classlist);        //调用移动方法，每一个li会根据新数组的位置进行移动，从而实现轮播的效果
    },
    // 向右运动
    rightMove() {
      let shiftValue = this.classlist.shift(); //删除并返回数组的第一个
      this.classlist.push(shiftValue); //把刚才删除的第一个push到数组末尾
      this.move(this.classlist);
    },
    // 定时器自动切换
    timer() {
      this.time = setInterval(this.leftMove.bind(this), 300000);
    },
    // 鼠标移入移出事件
    listenMouseEvent() {
      this.bannerWrap.addEventListener("mouseover", () => {
        clearInterval(this.time);
       
      });
      this.bannerWrap.addEventListener("mouseout", () => {
        this.timer();
      });
    },
    // // 点击左右按钮事件
    // listenMouseClickBtnEvent() {
    //   this.leftBtn.addEventListener("click", () => {
    //     this.leftMove();
    //   });
    //   this.rightBtn.addEventListener("click", () => {
    //     this.rightMove();
    //   });
    // },
  },
  mounted() {
    this.constructor();
  },
};

</script>

<style scoped lang="scss">
.swiperBox{
  height: 300px;
}
@keyframes myfirst {
	/* 设置为百分50可以缩放 */
	50% {
		transform: scale(1.5);
	}
}

.wanjin{
  font-size: 20px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #010001;
line-height: 45px;
}
.diaopoit{
  width: 16px;
  height: 16px;
  border: 3px solid red;
  border-radius: 50%;
  animation: myfirst 3s infinite;
}
.jinjinboom{
  text-align: left;
  font-size: 14px;
font-family: PingFangSC-Regular, PingFang SC;
font-weight: 400;
color: #666666;
line-height: 25px;
}
.banner-card {
  width: 100%;
  height: 450px;
  line-height: 250px;
  position: relative;
  margin-top: 100px;
}
.banner-card ul {
  width: 1600px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.banner-card li {
  width: 652px;
  height: 352px;
  position: absolute;
  top: 25px;
  list-style-type: none;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 4px;
  // border: 1px solid red;
}
.banner-card li img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.one {
  z-index: 99;
  left: -374px;
  transform: scale(0.9);
}

.two {
  z-index: 100;
  left: 542px;
  transform: scale(1);
}
.three {
  left: 1342px;
  z-index: 99;
  transform: scale(0.9);
}
.four {
  z-index: 98;
  left: 2142px;
  transform: scale(0.8);
}
.five {
  left: 2942px;
  z-index: 97;
  transform: scale(0.7);
}
.six {
  left: 3742px;
  z-index: 96;
  transform: scale(0.6);
}

.banner-card div {
  transition: 0.3s;
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
}
.banner-card div span {
  display: inline-block;
  font-size: 25px;
}

.left-btn {
  width: 100px;
    height: 20px;
  left: 340px;
  top: -260px;
  z-index: 10000;
  // border: 1px solid red;
  position: relative;
  img{
    width: 100px;
    height: 20px;
  }
}
.right-btn {
  width: 100px;
    height: 20px;
  left: 1236px;
  top: -280px;
  z-index: 10000;
  // border: 1px solid red;
  position: relative;
  img{
    width: 100px;
    height: 20px;
  }
}

</style>