<template>
  <div class="example-3d">
    <div class="leftBox">
      <p>产品介绍</p>
      <em></em>
    </div>
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in imgArr" :key="index">
        <img
            :src="item.src"
            alt=""
        />
        <div class="tipsBox">
          <textarea disabled>{{ tipsArr[index].p }}</textarea>
          <span>{{ tipsArr[index].span }}</span>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev" @click="prevBtn"></div>
      <div class="swiper-button-next" slot="button-next" @click="nextBtn"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-example-3d-coverflow',
  title: '3D Coverflow effect',
  components: {
    Swiper,
    SwiperSlide
  },
  props:['imgList','SlideIndex','prevFlag'],
  data() {
    return {
      imgArr:[],
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        initialSlide :4,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: -400,
          depth: 100,
          modifier: 1,
          slideShadows : false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      tipsArr: [
        {
          'p':'常用锌层275g/㎡（双面），其厚度为40um左右；钢厂无使用年限承诺，客户可根据具体工程选择增减镀层使用。',
          'span': '镀锌（GL）'
        },
        {
          'p': '以冷轧钢板或镀锌钢板或镀铝锌钢板或锌铝镁钢板为基板，经过表面预处理（脱脂、清洗、化学转化处理），以连续的方法涂上一层或多层液态涂料，经过烘烤和冷却所得彩色涂层钢板。',
          'span': '彩涂'
        },
        {
          'p': '主要产品纯钛卷板的圆/方锭、无缝钛管、棒线材及相关加工件。钛产品主要应用于医疗器材、植入用钛、航空航天装甲、防护、海水淡化、海洋钻进平台、船舶及海洋装备、化工冶金等工业、烟囱脱硫脱硝用钛及民用领域的广泛应用。',
          'span': '纯钛卷板'
        },
        {
          'p': '锌铝镁钢板是新型高耐腐蚀性镀层钢板，其镀层成分主要以锌为主，由锌外加1.5%-11%的铝，1.5%-3%的镁及微量的硅组成（不同厂家比例稍有区别），锌铝镁合金镀层钢板因优异的耐蚀性以及良好的加工成形性、焊接性、外观装饰性等，主要应用在建筑行业，正逐步向家电、汽车制造等行业推广应用。锌铝镁除了能直接进行加工以外，还可作为彩涂板卷的基板。\n' +
              '根据镀层中铝含量的不同，将锌铝镁镀层分为：\n' +
              '低铝锌铝镁镀层：铝含量：1%-3.5%\n' +
              '中铝锌铝镁镀层：铝含量：5%-11%\n' +
              '高铝锌铝镁镀层：铝含量：55%\n' +
              '目前我国国内主要以生产低铝锌铝镁为主，酒钢、首钢、宝钢等钢厂中铝锌铝镁、高铝锌铝镁均能生产。\n',
          'span': '锌铝镁'
        },
        {
          'p': '智顶 BIPV：\n用于新厂房建设，需要按照光伏屋面送审、验收。\n' +
              '双顶 BIPV：\n有效适配各种类型彩钢瓦屋面不拆除。\n' +
              '睿顶 BIPV：\n用于新厂房建设，按照普通彩钢瓦屋面送审、验收。',
          'span': '光伏建筑一体化'
        },
        {
          'p': '光伏支架作为搭建及承载关顾系统的重要“骨骼”部分，光伏支架的坚固性、耐腐蚀性尤为重要。锌铝镁材料相较于传统的热镀锌，其耐腐蚀性、自修复性能大大提高，帮助延长支架的使用寿命。',
          'span': '锌铝镁光伏支架'
        },
        {
          'p': '固定、密封太阳能电池组件、增强组件强度，延长使用寿命，便于运输、安装。所以太阳能光伏组件所使用的边框具有良好的抗氧化、耐腐蚀等性能。',
          'span': '锌铝镁光伏边框'
        },
        {
          'p': '热轧：\n适用于一般结构钢和工程用热轧钢板、钢带，可供焊管、冷轧料、自行车零件、以及重要焊接、铆接、栓接构件。',
          'span': '热轧'
        },
        {
          'p': '冷轧：\n广泛应用于汽车制造、家电产品、仪表开关、建筑、办公家具等行业。',
          'span': '冷轧'
        },
        {
          'p': '常用锌层150g/㎡（双面），其厚度达到40um；55%镀铝锌产品（镀层≥150g）一般有使用年限承诺。',
          'span': '镀铝锌（GL）'
        },
      ]
    }
  },
  created() {
    this.swiperOption.initialSlide = this.$props.SlideIndex;
  },
  mounted() {
    this.imgArr = this.$props.imgList;
  },
  methods: {
    prevBtn() {
      this.$emit('childEvent', this.swiperOption.initialSlide);
      console.log(this.swiperOption.initialSlide);
    },
    nextBtn() {
      this.$emit('childEvent', this.swiperOption.initialSlide);
      console.log(this.swiperOption.initialSlide);
    }
  }
}
</script>

<style lang="scss" scoped>
.example-3d {
  height: 550px;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 100px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  .leftBox {
    position: absolute;
    top: 0;
    left: 9%;
    p {
      font-size: 32px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #010001;
      line-height: 47px;
    }
    em {
      margin-top: 14px;
      width: 126px;
      height: 6px;
      background: #C82227;
      display: block;
    }
  }
}

.swiper {
  width: 100%;
  overflow: initial;
  .swiper-wrapper {
    transform: translate3d(0, 0px, 0px) !important;
  }
  .swiper-slide {
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 801px;
    text-align: center;
    font-weight: bold;
    img {
      height: 100%;
      width: 100%;
    }
    .tipsBox {
      position: absolute;
      margin-top: 52px;
      width: 400px;
      right: -15px;
      height: 504px;
      text-align: left;
      textarea {
        margin: 0;
        width: 375px;
        height: 304px;
        text-align: left;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        background: transparent;
        border: none;
        line-height: 30px;
        resize:none;
      }
      textarea::-webkit-scrollbar {
        width: 7px;
        background-color: #F5F5F5;
      }
      /*滚动条设置*/
      textarea::-webkit-scrollbar-thumb{
        -webkit-box-shadow: inset 0 0 6px #D8D8D8;
        background-color: #D8D8D8;
      }
      span {
        display: block;
        text-align: left;
        margin-top: 20px;
        width: 354px;
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 45px;
      }
    }
  }
  .swiper-button-prev {
    left: 90%;
    top: -11%;
    width: 35px;
    height: 35px;
    background-image: url(../../assets/img/cpjs/leftBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next {
    right: 5%;
    top: -11%;
    width: 35px;
    height: 35px;
    background-image: url(../../assets/img/cpjs/rightBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next:after{
    content: none;
  }
  .swiper-button-prev:after{
    content: none;
  }
}
</style>