<template>
  <div>


<div style="height: 650px;">
  <div v-if="syindex==0">
  <div class="jiandang" >
    <div class="scllboom">
        <div class="titrighttlscll">
        <div class="rightop">
        <div class="rightopone">
        <p style="color:#C82227; font-size: 22px;">力拓与首钢集团签署了合作备忘录</p>
        <p style="color: #aaa;">10</p>
       </div>
       <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
       <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2022</p> 
       <p style="text-align: right; color: #aaa;">09</p> 
       </div>
        </div>
        <div class="righboom">
          近日，力拓与首钢集团签署了合作备忘录，旨在研究、设计和实施钢铁行业价值链的低碳解决方案。双方的合作将集中在低碳烧结技术、高炉和转炉优化，以及碳捕集与利用技术等领域。力拓和首钢是合作近30年的贸易伙伴，本次合作备忘录的签署是双方合作关系深入发展的又一重要里程碑。双方将利用各自在研发、技术、工艺、设备、物流和行业协调等方面的优势，合作推动实现减少全球气候变化影响、降低碳排放的目标。
        </div>
      
</div>
        <div class="titleftlscll">
          <img src="../xinwen/img/hyo1.png" class="titleimg" alt="" />
 
      </div>
     
      </div>
    
  </div>

</div>
<!--  <div class="jiandang" v-if="syindex==1">
    <div class="scllboom">
        <div class="titrighttlscll">
        <div class="rightop">
        <div class="rightopone">
          <p style="color:#C82227; font-size: 22px;">钢铁行情预测</p>
        <p style="color: #aaa;">2</p>
       </div>
       <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
        <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2023</p> 
       <p style="text-align: right; color: #aaa;">06</p> 
       </div>
        </div>
        <div class="righboom">
          上周钢材市场呈现冲高回落态势，主要是大部分下游工程项目尚未复工，钢价无量空涨不可持续。元宵节过后，下游工程项目将加快复工。当前钢厂多数处于小幅亏损或盈亏边缘，上周供应增速偏快，导致市场情绪谨慎。短期钢价或震荡偏弱运行。
        </div>
      
</div>
        <div class="titleftlscll">
          <img src="../xinwen/img/hyt.png" class="titleimg" alt="" />
 
      </div>
     
      </div>
  
  </div>
  <div class="jiandang" v-if="syindex==2">
    <div class="scllboom">
        <div class="titrighttlscll">
        <div class="rightop">
        <div class="rightopone">
          <p style="color:#C82227; font-size: 22px;">冷轧板卷行情</p>
        <p style="color: #aaa;">2</p>
       </div>
       <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
        <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2023</p> 
       <p style="text-align: right; color: #aaa;">07</p> 
       </div>
        </div>
        <div class="righboom">
          2月7日国内冷轧市场价格部分下调，市场交投氛围冷清。据兰格钢铁云商平台监测统计，国内1m冷卷均价4598元，较上一个工作交易日价格下调9元。上海1.0mm冷轧板卷价格4510元，广州1.0mm冷轧板卷价格4570元，天津1.0mm冷轧板卷价格4480元。华北地区天津市场价格暂稳，据贸易商反馈，近期钢材库存水平较春节前快速提升，现货价格报价多稳，市场交投氛围冷清，贸易商为出货可接受议价。
        </div>
      
</div>
        <div class="titleftlscll">
          <img src="../xinwen/img/hyth.png" class="titleimg" alt="" />
 
      </div>
     
      </div>
  
  </div>-->
 
</div>
<div class="xinwenimgclick ">

<img
        @click="imgclickjian"
        src="../../assets/img/zuohua.png"
        style="width: 35px"
        alt=""
      />
      <img
        @click="imgclickadd"
        src="../../assets/img/youhua.png"
        style="width: 35px"
        alt=""
      />
</div>


</div>
</template>

<script>
export default {
  data() {
    return {
      syindex: 0,
    };
  },

  mounted() {},
  methods: {
    imgclickjian() {
      // if (this.syindex > 0) {
      //   this.syindex = this.syindex - 1;
      // }
    },
    imgclickadd() {
      // if (this.syindex < 2) {
      //   this.syindex = this.syindex + 1;
      // }
    },
  },
};
</script>

<style scoped lang="scss">
 .xinwenimgclick{
  // border: 1px solid red;
    width: 180px;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
  }
.jiandang {
  // height: 572px;
  .scllboom {
    display: flex;
    // align-items: center;
    max-width: 1600px;
    margin: 50px auto;
    height: 400px;
    // border: 1px solid red;
    .titleftlscll {
      // border: 1px solid red;
      .titleimg {
        // border: 1px solid red;
        width: 864px;
        height: 556px;
      }
      .imgclick {
        width: 200px;
        // border: 1px solid red;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;
       
      }
    }
    .titrighttlscll {
      padding: 10px 20px;
      width: 864px;
      // height: 600px;
      // width: 400px;
      // border: 1px solid red;
      .rightop {
        box-shadow:10px 6px 30px 1px rgb(245,245,245);
        height: 70px;
        width: 100%;
        padding:  10px;
        box-sizing: border-box;
        .rightopone {
          // padding: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid rgb(200, 36, 41);
        }
      }
      .righboom {
        width: 800px;
        height: 380px;
        padding: 10px;
        box-sizing: border-box;
        box-shadow:10px 6px 30px 1px rgb(245,245,245);
        text-align: left;
        // margin-top: 80px;
        color: #aaa;
        font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 60px;
      }
    }

  }
}

</style>
