<template>
  <div>


<div style="height: 650px;">
  <div v-if="syindex==0">
  <div class="jiandang" >
    <div class="scllboom">
        <div class="titrighttlscll">
        <div class="rightop">
        <div class="rightopone">
        <p style="color:#C82227; font-size: 22px;">鸿运天降于美好心灵，旗开得胜于彼此成就</p>
        <p style="color: #aaa;">11</p>
       </div>
       <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
       <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2022</p> 
       <p style="text-align: right; color: #aaa;">25</p> 
       </div>
        </div>
        <div class="righboom">
          “阳明心学”十年践行路，2022中小微创企业学习会，于11月25日上午9点顺利召开鸿旗集团大楼荣幸地成为此次学习会直播点之一。成都万鸿昌、广东兰格、安徽鸿兴、浙江中登、苏州烨贸等分支机构同步召开学习会，此次学习会上，亦有众多中小微创、上市公司董事长齐聚课堂，分享他们各自企业的经历及经验之道，大家共话民营企业发展大势，旨在通过十年方法论，一起现场做练习，从心出发，助力中小微创企业突围并抢占高地。 </div>
      
</div>
        <div class="titleftlscll">
          <img src="../xinwen/img/jituo.png" class="titleimg" alt="" />
 
      </div>
     
      </div>
    
  </div>

</div>
  <div class="jiandang" v-if="syindex==1">
    <div class="scllboom">
        <div class="titrighttlscll">
        <div class="rightop">
        <div class="rightopone">
          <p style="color:#C82227; font-size: 22px;">鸿旗集团2023年开工大吉</p>
        <p style="color: #aaa;">01</p>
       </div>
       <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
        <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2023</p> 
       <p style="text-align: right; color: #aaa;">30</p> 
       </div>
        </div>
        <div class="righboom">
          新年新气象，新年新征程。2023年1月30日上午9:18分，新春大年初九，鸿旗集团各级领导及全体员工在公司举行了隆重的开工仪式。开工仪式上，全体员工集体诵读鸿旗心约，万鸿国际总经理胡晓军、世贝材料总经理姚付进、尾易网CEO储康康、鸿旗电力总经理赵传龙分别为全体员工送上开工致辞、新年祝福并带来了新的憧憬和蓝图，最后由皇平董事长为大家总结致辞并分发开门红包。</div>
      
</div>
        <div class="titleftlscll">
          <img src="../xinwen/img/jituant.png" class="titleimg" alt="" />
 
      </div>
     
      </div>
  
  </div>
  <div class="jiandang" v-if="syindex==2">
    <div class="scllboom">
        <div class="titrighttlscll">
        <div class="rightop">
        <div class="rightopone">
          <p style="color:#C82227; font-size: 22px;">业务进行时 | 销售的四个阶段</p>
        <p style="color: #aaa;">12</p>
       </div>
       <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
        <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2023</p> 
       <p style="text-align: right; color: #aaa;">08</p> 
       </div>
        </div>
        <div class="righboom">
          2022年12月8日下午14:00时，鸿旗集团第12期《业务进行时》如约而至。本次会议由万鸿国际下属分支机构广东兰格的欧道武同仁进行主讲。本次主讲内容主要分为四个阶段：开发阶段、成交阶段、维护阶段及售后阶段。 </div>
      
</div>
        <div class="titleftlscll">
          <img src="../xinwen/img/jituanth.png" class="titleimg" alt="" />
 
      </div>
     
      </div>
  
  </div>
  <div class="jiandang" v-if="syindex==3">
    <div class="scllboom">
      <div class="titrighttlscll">
        <div class="rightop">
          <div class="rightopone">
            <p style="color:#C82227; font-size: 22px;">鸿旗集团乔迁 | 新起点、新征程、新梦想，扬帆起航，鹏程万里！</p>
            <p style="color: #aaa;">9</p>
          </div>
          <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
            <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2022</p>
            <p style="text-align: right; color: #aaa;">28</p>
          </div>
        </div>
        <div class="righboom">
          万鸿国际创始于2010年12月，2021年鸿旗集团全产业集群式服务创新体系成立，十二年里，面对前所未有的市场挑战和机遇，迎难而上，不断超越自我，取得了巨大的成绩。2022年9月28日9点18分，鸿旗集团乔迁仪式在舞狮秧歌锣鼓炮竹声中正式启动，此次乔迁，是鸿旗集团发展历程中的重要里程碑，是鸿旗集团扬帆启航的新平台，更是集团领导人带领全体同仁锐意进取、迈向成功的重要标志，是我们鸿旗集团做大做强的全新起跑线。
        </div>

      </div>
      <div class="titleftlscll">
        <img src="../xinwen/img/hyo2.png" class="titleimg" alt="" />

      </div>

    </div>

  </div>
  <div class="jiandang" v-if="syindex==4">
    <div class="scllboom">
      <div class="titrighttlscll">
        <div class="rightop">
          <div class="rightopone">
            <p style="color:#C82227; font-size: 22px;">鸿旗团建 | 道可顿悟，德需渐修</p>
            <p style="color: #aaa;">3</p>
          </div>
          <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
            <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2023</p>
            <p style="text-align: right; color: #aaa;">4</p>
          </div>
        </div>
        <div class="righboom">
          此次旅行，我们特意先赶往了绍兴阳明墓祭拜阳明先生。借用余秋雨先生的话来讲：“ 中国历史上能文能武的人很多，但在两方面都臻于极致的却廖若晨星好像一切都要等到王阳明的出现 ，才能让奇迹真正产生” 其宽广的人文精神，足以跨越时间、地域和种族， 是值得一个人一生，去学习与修行的。 有幸参观王阳明故居，不仅仅是一次简单的人文之旅，更是一个穿越时空和先贤交流的精神之旅。
        </div>
      </div>
      <div class="titleftlscll">
        <img src="../xinwen/img/hyo3.png" class="titleimg" alt="" />

      </div>

    </div>

  </div>
  <div class="jiandang" v-if="syindex==5">
    <div class="scllboom">
      <div class="titrighttlscll">
        <div class="rightop">
          <div class="rightopone">
            <p style="color:#C82227; font-size: 22px;">鸿运天降于美好心灵 · 旗开得胜于彼此成就</p>
            <p style="color: #aaa;">3</p>
          </div>
          <div style=" margin-top: 5px; display: flex; justify-content: space-between; ">
            <p style="text-align: left; color: rgb(200,36,41); font-size: 22px;">2023</p>
            <p style="text-align: right; color: #aaa;">3</p>
          </div>
        </div>
        <div class="righboom">
          3月3日，鸿旗集团隆重召开2023年销售启动大会。本次会议采取线上线下相结合的方式，各子公司及分支机构的同仁齐聚会场，共同开启2023年新征程。在过去一年的时间里，我们时刻准备着，以精益求精的态度、壁立千仞的格局、德厚流光的品质，面对前所未有的市场挑战和机遇，奋勇前行、迎难而上，不断超越自我，创造了数个历史新高，一次又一次书写着传奇与经历。
        </div>
      </div>
      <div class="titleftlscll">
        <img src="../xinwen/img/hyo4.png" class="titleimg" alt="" />

      </div>

    </div>

  </div>

</div>
<div class="xinwenimgclick ">

<img
        @click="imgclickjian"
        src="../../assets/img/zuohua.png"
        style="width: 35px"
        alt=""
      />
      <img
        @click="imgclickadd"
        src="../../assets/img/youhua.png"
        style="width: 35px"
        alt=""
      />
</div>


</div>
</template>

<script>
export default {
  data() {
    return {
      syindex: 0,
    };
  },

  mounted() {},
  methods: {
    imgclickjian() {
      if (this.syindex > 0) {
        this.syindex = this.syindex - 1;
      }
    },
    imgclickadd() {
      if (this.syindex < 5) {
        this.syindex = this.syindex + 1;
      }
    },
  },
};
</script>

<style scoped lang="scss">
 .xinwenimgclick{
  // border: 1px solid red;
    width: 180px;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
  }
.jiandang {
  // height: 572px;
  .scllboom {
    display: flex;
    // align-items: center;
    max-width: 1600px;
    margin: 50px auto;
    height: 400px;
    // border: 1px solid red;
    .titleftlscll {
      // border: 1px solid red;
      .titleimg {
        // border: 1px solid red;
        width: 864px;
        height: 556px;
      }
      .imgclick {
        width: 200px;
        // border: 1px solid red;
        margin: 20px auto;
        display: flex;
        justify-content: space-between;
       
      }
    }
    .titrighttlscll {
      padding: 10px 20px;
      width: 864px;
      // height: 600px;
      // width: 400px;
      // border: 1px solid red;
      .rightop {
        box-shadow:10px 6px 30px 1px rgb(245,245,245);
        height: 70px;
        width: 100%;
        padding:  10px;
        box-sizing: border-box;
        .rightopone {
          // padding: 30px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 2px solid rgb(200, 36, 41);
        }
      }
      .righboom {
        width: 800px;
        height: 380px;
        padding: 10px;
        box-sizing: border-box;
        box-shadow:10px 6px 30px 1px rgb(245,245,245);
        text-align: left;
        // margin-top: 80px;
        color: #aaa;
        font-size: 22px;
font-family: PingFangSC-Light, PingFang SC;
font-weight: 300;
color: #666666;
line-height: 60px;
      }
    }

  }
}

</style>
