<template>
    <div class="app-container">
        <!--主体为swiper标签-->
        <!--属性 :options 绑定的是data中设置的swiper配置项-->
        <!--属性 ref 用于获取该dom元素，在计算属性computed中将被调用-->
        <!--属性 id 为swiper最外层容器设置css样式-->
        <swiper :options="swiperOption" ref="mySwiper" id="mySwiper">

            <!--必须的组件-->
            <!--每页幻灯片使用swiper-slide标签-->
            <!--幻灯片组件生成的标签自带.swiper-slide的类名，但单类名选择器设置的部分css(如宽高)将被覆盖-->
            <swiper-slide class="swiper_slide_item" v-for="(v,i) in imgArr">
                <img :src="v" alt="" style="width: 100%;">
            </swiper-slide>

            <!--&lt;!&ndash; 可选的控件 &ndash;&gt;
            &lt;!&ndash;分页器&ndash;&gt;
            <div class="swiper-pagination" slot="pagination"></div>
            &lt;!&ndash;滚动条&ndash;&gt;
            <div class="swiper-scrollbar" slot="scrollbar"></div>
            &lt;!&ndash;前进后退按钮&ndash;&gt;
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>-->
        </swiper>

    </div>
</template>

<script>
  export default {
    name: "app-container",
    components:{},
    props:[],
    data(){
      return {
          imgArr:[
              require('./img/o.jpg'),
              require('./img/q.jpeg'),
              require('./img/i.jpg'),
              require('./img/e.png'),
              require('./img/w.jpg'),
          ],
        swiperOption:{
          effect: "coverflow",
          grabCursor: true,
          centeredSlides: true,
          slidesPerView: "auto",
          coverflowEffect: {
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
          },
        },
      }
    },
    watch:{},
    computed:{
        swiper() {
            return this.$refs.mySwiper.$swiper;
        },
    },
    methods:{},
    mounted() {}
  }
</script>

<style scoped lang="scss">
.app-container{
  width: 100%;
  height: 100%;
    /*容器样式*/
    #mySwiper{
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .swiper_slide_item {
        background-position: center;
        background-size: cover;
        width: 300px;
        /*height: 300px;*/
    }
}
</style>
