<template>
  <div class="example-3d">
    <swiper class="swiper  swiper-no-swiping" :options="swiperOption">
      <swiper-slide v-for="(item, index) in imgArr" :key="index">
        <img
            :src="item.src"
            alt=""
        />
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev" @click="prevBtn"></div>
      <div class="swiper-button-next" slot="button-next" @click="nextBtn"></div>
    </swiper>
    <div class="contentText">
      <em>鸿旗集团第一届摄影书画亲子展</em>
      <span>
        世界因为我们的描绘而变得更加美丽<br/>
        世界因为我们的想象而变得更加神奇<br/>
        鸿旗家长们带着小朋友一起绘制拍摄<br/>
        一起来欣赏小朋友们五彩斑斓的世界
      </span>
      <p>我们在这里等你</p>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-example-3d-coverflow',
  title: '3D Coverflow effect',
  components: {
    Swiper,
    SwiperSlide
  },
  props:['imgList','SlideIndex','prevFlag'],
  data() {
    return {
      imgArr:[
        {
          src:require("../../assets/img/rcfz/rcfzSwiper.png")
        },
      ],
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        initialSlide :4,
        loop:true,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: 870,
          depth: 100,
          modifier: 1,
          slideShadows : false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
    }
  },
  created() {
    this.swiperOption.initialSlide = this.$props.SlideIndex;
    this.index = this.$props.SlideIndex;
  },
  mounted() {
    this.imgArr = this.$props.imgList;
  },
  methods: {
    prevBtn() {
      --this.swiperOption.initialSlide
      this.index = this.swiperOption.initialSlide;
    },
    nextBtn() {
      ++this.swiperOption.initialSlide
      this.index = this.swiperOption.initialSlide;
    }
  }
}
</script>

<style lang="scss" scoped>
.example-3d {
  width: 100%;
  box-sizing: border-box;
  height: 765px;
  padding: 50px 100px 50px 10px;
  margin-bottom: 100px;
  display: flex;
  justify-content: space-between;
}

.swiper {
  height: 100%;
  width: 885px;
  overflow: initial;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 952px;
    height: 765px;
    text-align: center;
    font-weight: bold;
    img {
      width: 648px;
      height: 765px;
    }
  }
  .swiper-button-prev {
    top: 115%;
    left: 45%;
    width: 35px;
    height: 35px;
    background-image: url(../../assets/img/cpjs/leftBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next {
    top: 115%;
    right: 45%;
    width: 35px;
    height: 35px;
    background-image: url(../../assets/img/cpjs/rightBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next:after{
    content: none;
  }
  .swiper-button-prev:after{
    content: none;
  }
}
.contentText {
  width: 700px;
  display: flex;
  margin-left: 100px;
  flex-direction: column;
  p {
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 37px;
  }
  span {
    display: block;
    font-size: 20px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 31px;
    margin-bottom: 100px;
  }
  b {
    font-size: 20px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 30px;
    margin: 28px 0 57px;
  }
  em {
    font-style: normal;
    display: block;
    width: 450px;
    height: 60px;
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #010001;
    line-height: 60px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 200px;
  }
}
</style>