<template>
  <div class="wanhong">
    <div class="backimg">
        <img src="../assets/img/hearder.png" alt="" class="hearderimg" @click="showhearder">
       <div v-if="heardersw">
        <titletop />
       </div>
      <div class="optionbander">
        <div class="bigsix">为中华民族伟大复兴努力奋斗</div>
        <div class="smallsix">值得信赖-值得托付</div>
      </div>
      <div class="backsmaimg">
       <!-- <img class="banderdsd" src="../assets/img/bigbanhder.png" alt=""> -->
       <div class="posionband">
        <div class="posionbando">上海万鸿国际贸易有限公司</div>
        <div class="posionbandt" >鸿旗企业/企业简介/下属公司</div>
      </div>
     </div>
      </div>
    <!--  关于万鸿  -->
    <div class="aboutWh">
      <div class="content">
        <p>关于万鸿</p>
        <b>ABOUT WINHONOR</b>
        <span>
          上海万鸿国际贸易有限公司始创于2010年，自成立以来
          秉持“资源是大家的，共同奋斗、共同分享、拥抱变化、
          彼此成就“的经营目标，打造业内知名品牌，公司以服务
          终端客户为核心策略，赋能钢结构、钢板仓、立体车库、
          太阳能光伏支架等行业发展，以极高品质和供应链服务为
          钢贸行业赋能。
        </span>
        <em>业务联系：400-600-8891</em>
      </div>
    </div>
    <!--  中部banner  -->
    <img src="../assets/img/wanhong/mBanner.png" class="mBanner" alt="">
    <!--  业务分布  -->
    <div class="mapBox">
      <div class="leftBox">
        <div class="textBox">
          <p class="h1">万&nbsp;鸿&nbsp;国&nbsp;际&nbsp;分&nbsp;支&nbsp;机&nbsp;构</p>
          <div class="tipsBox">
            <div>
              <p>超1000</p>
              <em></em>
              <span>家客户</span>
            </div>
            <div>
              <p>5</p>
              <em></em>
              <span>家分公司</span>
            </div>
            <div>
              <p>超40万吨</p>
              <em></em>
              <span>年销售量</span>
            </div>
          </div>
        </div>
        <div class="corpBox">
          <img src="../assets/img/wanhong/Corp.png" alt="">
          <img src="../assets/img/wanhong/Corp1.png" alt="">
          <div class="imgTips">
            <img src="../assets/img/wanhong/Corp2.png" alt="">
            <img src="../assets/img/wanhong/Corp2Tips.png" class="Corp2Tips" alt="">
          </div>
          <img src="../assets/img/wanhong/Corp3.png" alt="">
          <div class="imgTips1">
            <img src="../assets/img/wanhong/Corp4.png" alt="">
            <img src="../assets/img/wanhong/dot.png" class="dot" alt="">
            <div class="Corp4TipsBox">
              <img src="../assets/img/wanhong/Corp4Tips.png" class="Corp2Tips" alt="">
              <img src="../assets/img/wanhong/Corp4Tips1.png" class="Corp2Tips" alt="">
              <img src="../assets/img/wanhong/Corp4Tips2.png" class="Corp2Tips" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="rightBox">
        <img src="../assets/img/wanhong/map.png" alt="">
      </div>
    </div>
    <!--  产品介绍  -->
    <div class="cpjs">
      <p class="h1">产品介绍</p>
      <swiperCp
          :img-list="imgArr"
          :slide-index="SlideIndex"
          ref="childEvent"
      />
    </div>
   <Foot />
  </div>
</template>
<script>
import Foot from '../components/footToo.vue'
import titletop from '../components/titletop.vue'
import swiperCp from '../components/shehui/swiperCp.vue'
export default {
data(){
 return{
  heardersw:false,
   imgArr:[
     {
       src:require("../assets/img/wanhong/cpjs/cpjs1.png")
     },
     {
       src:require("../assets/img/wanhong/cpjs/cpjs2.png")
     },
     {
       src:require("../assets/img/wanhong/cpjs/cpjs3.png")
     },
     {
       src:require("../assets/img/wanhong/cpjs/cpjs4.png")
     },
     {
       src:require("../assets/img/wanhong/cpjs/cpjs5.png")
     },
     {
       src:require("../assets/img/wanhong/cpjs/cpjs6.png")
     },
   ],
   SlideIndex: 2,
   childEvent: ''
 }
},
mounted(){
 console.log(this.$refs.childEvent);
  this.childEvent = this.$refs.childEvent.swiperOption.initialSlide;
},
  watch: {
    childEvent(val) {
      console.log(val)
    }
  },
methods:{

  showhearder(){
    this.heardersw =! this.heardersw
  }
},  
name: 'App',
components: {
  Foot,
  titletop,
  swiperCp
}
}
</script>
<style lang="scss" scope>


.animationone{
  animation:1s fadeInUp;
  }
  .animationtwo{
  animation:3s fadeInUp;
  }
   .animationthree{
  animation:4s fadeInUp;
  }


.wanhong{
  .backimg{
  // border: 1px solid red;
  height: 600px;
  background-image: url(../assets/img/wanhong/banner.png);
  background-position: 100%;
  background-size: 100% 100%;
  position: relative;
  }
  .hearderimg{
  width: 29px;
  height: 21px;
  position: absolute;
  right: 50px;
  top: 20px;
  }
  .showhearder{
  padding: 50px ;
  padding-top: 100px;

  width: 345px;
  height: 608px;
  position: absolute;
  right: 0px;
  top: 0px;
  background-color: white;
  z-index: 10;
  .heaitem{
  // border: 1px solid red;
  display: flex;
  align-items: center;
  margin: 30px 0;

  }

  }
  .liactive{
    color: rgb(230,82,86);
  }
  .backsmaimg{
  width: 36%;
  height: 300px;
    border-radius: 8px;
  background-image: url(../assets/img/bigbanhder.png);
  background-position: 50%;
  background-repeat: no-repeat;
  position: absolute;
  bottom: -100px;
  right: 220px;
  .posionband{
    position: absolute;
    // border: 1px solid red;
    left: 60px;
    top: 80px;
    color: white;
    .posionbando{
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 74px;
    }
    .posionbandt{
      font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 27px;
  margin-top: 20px;
    }
  }
  }
  .optionbander{
    position: absolute;
    top: 239px;
    left: 232px;
    color: white;
    .bigsix{
      font-size: 60px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 89px;
  letter-spacing: 12px;
    }
    .smallsix{
      font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 27px;
  letter-spacing: 6px;
      margin-top: 20px;
    }
  }
  .aboutWh {
    width: 1471px;
    height: 696px;
    background-image: url(../assets/img/wanhong/aboutWh.png);
    background-position: 100%;
    background-size: 100% 100%;
    margin: 215px auto 0;
    overflow: hidden;
    .content {
      width: 550px;
      margin-left: 865px;
      margin-top: 47px;
      display: flex;
      flex-direction: column;
      p {
        font-size: 32px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 45px;
      }
      b {
        font-size: 22px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #999999;
        line-height: 21px;
        letter-spacing: 2px;
        margin-top: 34px;
        margin-bottom: 28px;
      }
      span {
        font-size: 22px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #666666;
        line-height: 60px;
      }
      em {
        display: block;
        font-style: normal;
        width: 300px;
        height: 47px;
        font-size: 22px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 34px;
        line-height: 47px;
        background: #D60C18;
        border-radius: 5px;
        text-align: center;
      }
    }
  }
  .mBanner {
    width: 100%;
    height: 308px;
    margin-top: 106px;
  }
  .mapBox {
    width: 100%;
    height: 1075px;
    box-sizing: border-box;
    padding: 68px 119px 0 142px;
    background: #F7F8FB;
    display: flex;
    justify-content: space-between;
    .leftBox {
      height: 100%;
      width: 606px;
      .textBox {
        width: 606px;
        .h1 {
          font-size: 32px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 41px;
          margin-bottom: 20px;
        }
        .tipsBox {
          display: flex;
          align-items: center;
          margin-top: 41px;
          justify-content: space-between;
          div {
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
              font-size: 32px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #000000;
              line-height: 47px;
            }
            em {
              display: block;
              width: 33px;
              height: 4px;
              background: #D60C18;
              margin: 10px 0;
            }
            span {
              font-size: 22px;
              font-family: PingFangSC-Light, PingFang SC;
              font-weight: 300;
              color: #000000;
              line-height: 41px;
            }
          }
        }
      }
      .corpBox {
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-top: 69px;
        img {
          width: 606px;
          height: 112px;
          margin-bottom: 30px;
        }
        .imgTips {
          position: relative;
          .Corp2Tips {
            position: absolute;
            right: -215px;
            top: 35px;
            width: 219px;
            height: 35px;
          }
        }
        .imgTips1 {
          position: relative;
          .dot {
            position: absolute;
            width: 14px;
            height: 14px;
            right: -10px;
            top: 45px;
          }
          .Corp4TipsBox {
            display: flex;
            flex-direction: column;
            position: absolute;
            right: -220px;
            top: -15px;
            img {
              width: 180px;
              height: 34px;
              margin: 0;
            }
            img:nth-child(2) {
              margin: 13px 0;
            }
          }
        }
      }
    }
    .rightBox {
      margin-left: 10px;
      img {
        width: 917px;
        height: 771px;
      }
    }
  }
  .cpjs {
    box-sizing: border-box;
    padding: 106px 0 0 0;
    .h1 {
      font-size: 50px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #010001;
      line-height: 47px;
      text-align: center;
    }
  }
}

</style>