<template>
  <div class="example-3d">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="(item, index) in imgArr" :key="index">
        <img
            :src="item.src"
            alt=""
        />
        <div class="tips">
          <p>{{ tipsArr[index].tipsP }}</p>
          <span>{{ tipsArr[index].tipsSpan }}</span>
          <div class="weight">
            <b>{{ tipsArr[index].tipsB }}</b>
          </div>
        </div>
      </swiper-slide>
      <div class="swiper-button-prev" slot="button-prev" @click="prevBtn"></div>
      <div class="swiper-button-next" slot="button-next" @click="nextBtn"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'

export default {
  name: 'swiper-example-3d-coverflow',
  title: '3D Coverflow effect',
  components: {
    Swiper,
    SwiperSlide
  },
  props:['imgList','SlideIndex','prevFlag'],
  data() {
    return {
      imgArr:[
        {
          src:require("../../assets/img/rcfz/rcfzSwiper.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper1.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper2.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper3.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper4.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper5.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper6.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper7.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper8.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper9.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper10.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper11.png")
        },
        {
          src:require("../../assets/img/rcfz/rcfzSwiper12.png")
        },
      ],
      swiperOption: {
        effect: 'coverflow',
        grabCursor: true,
        initialSlide :4,
        centeredSlides: true,
        slidesPerView: 'auto',
        coverflowEffect: {
          rotate: 0,
          stretch: -400,
          depth: 100,
          modifier: 1,
          slideShadows : false
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      tipsArr: [
        {
          'tipsP': '彩涂 白灰 0.6*1000',
          'tipsSpan': '鄂尔多斯京东方',
          'tipsB': '100吨'
        },
        {
          'tipsP': '镀锌 2.0/2.5*1250',
          'tipsSpan': '正大饲料厂',
          'tipsB': '100吨'
        },
        {
          'tipsP': '镀锌1.5*1250',
          'tipsSpan': '太湖农业专业合作社',
          'tipsB': '100吨'
        },
        {
          'tipsP': '镀铝锌 1.0*1250',
          'tipsSpan': '上海国展中心',
          'tipsB': '2000吨'
        },
        {
          'tipsP': '彩涂 白灰 0.6*1250',
          'tipsSpan': '商飞上海基地',
          'tipsB': '500吨'
        },
        {
          'tipsP': '彩涂 深中灰 海灰 1.0 0.6 HDP/HDP TS350GD AZ150 25/20',
          'tipsSpan': '广东金晟兰冶金一期',
          'tipsB': '100吨'
        },
        {
          'tipsP': '几字钢',
          'tipsSpan': 'La Loma项目',
          'tipsB': '1280吨'
        },
        {
          'tipsP': '3镁11铝不锈锌铝镁',
          'tipsSpan': '内乡牧原产业园',
          'tipsB': '1000吨'
        },
      ]
    }
  },
  created() {
    this.swiperOption.initialSlide = this.$props.SlideIndex;
  },
  mounted() {
    this.imgArr = this.$props.imgList;
  },
  methods: {
    prevBtn() {
      this.$emit('childEvent', this.swiperOption.initialSlide);
      console.log(this.swiperOption.initialSlide);
    },
    nextBtn() {
      this.$emit('childEvent', this.swiperOption.initialSlide);
      console.log(this.swiperOption.initialSlide);
    }
  }
}
</script>

<style lang="scss" scoped>
.example-3d {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-bottom: 100px;
}

.swiper {
  height: 100%;
  width: 100%;

  .swiper-slide {
    display: flex;
    align-items: start;
    flex-direction: column;
    width: 952px;
    text-align: center;
    font-weight: bold;
    img {
      height: 100%;
      width: 100%;
    }
    .tips {
      margin-top: 52px;
      p {
        font-size: 22px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        text-align: left;
        line-height: 30px;
      }
      span {
        display: block;
        margin-top: 22px;
        text-align: left;
        font-size: 22px;
        font-family: PingFangSC-Light, PingFang SC;
        font-weight: 300;
        color: #666666;
        line-height: 30px;
      }
      .weight {
        width: 100%;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        text-align: left;
        margin-top: 15px;
        b {
          display: inline-block;
          border-bottom: 2px solid #C82227;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 30px;
        }
      }
    }
  }
  .swiper-button-prev {
    left: 12%;
    width: 154px;
    height: 28px;
    background-image: url(./img/staffImg/leftBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next {
    right: 12%;
    width: 154px;
    height: 28px;
    background-image: url(./img/staffImg/rightBtn.png);
    background-position: 100%;
    background-repeat: no-repeat;
  }
  .swiper-button-next:after{
    content: none;
  }
  .swiper-button-prev:after{
    content: none;
  }
}
</style>