<template>
  <div class="swiperBox">
    <div class="banner-card">

      <ul class="ul">
        <li v-for="(item,index) in imgarr" :key="index">
          <img
              :src="item.src"
              alt=""
          />

        </li>
      </ul>
    </div>
    <div class="backcore">
    </div>

    <div style="width: 160px;margin: 0 auto; display: flex;justify-content: space-between; align-items: center;">
      <div  @click="leftMove()">
        <img src="../../assets/img/sszuo.png" alt="">
      </div>
      <div  @click="rightMove()">
        <img src="../../assets/img/ssyou.png" alt="">
      </div>
    </div>
    <div class="right" style="right: 10%" v-if="yuankacti == 'one'">
      <p class="rightop">钢旗3镁11铝锌铝镁彩涂产品 SZAMPPG</p>
      <div class="righcen" style="margin-bottom: 50px">
        <p class="righcentext" style="width: 682px;">其与普通聚酯彩涂板相比，产品有更好的防腐性和耐久性。
          加工成型性优异，油漆质保期在15年以上，满足中、高档建筑物的耐久性要求。
          钢旗技术人员针对业主厂房所处环境以及使用环境，选用合适的高端油漆类别，
          引入私人订制概念，连同锌铝镁基板，整体保年限在40年以上。</p>
      </div>
      <div style="font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          width: 682px;
          line-height: 30px;
          margin-bottom: 20px;"
      >
        使用领域：蓄牧养殖行业厂房 、制药行业厂房 、化工行业厂房 、
        物流仓库行业厂房、一般制造业厂房。
      </div>
      <p class="righbomcp" style="width: 400px;text-align: center;">联系我们:400-600-8891</p>
    </div>
    <div class="right" style="right: 10%" v-if="yuankacti == 'two'">
      <p class="rightop">钢旗3镁11铝锌铝镁彩涂产品 SZAMSTR</p>
      <div class="righcen" style="margin-bottom: 50px">
        <p class="righcentext" style="width: 682px;">  具有良好的稳定性、抗腐蚀性和机械性能 ，正常条件下油漆质保期在20年以上。
          钢旗技术人员针对业主厂房所处环境以及使用环境，选用合适的高端油漆类别，
          引入私人订制概念，连同锌铝镁基板，整体质保年限在50年以上。</p>
      </div>
      <div style="font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          width: 682px;
          line-height: 30px;
          margin-bottom: 20px;"
      >
        使用领域：高端建筑厂房、机场、体育馆、大型会展中心、车站等民生高端建筑系统、火电厂厂房 、环境腐蚀较严重区域的建筑物（如海边、重工业污染区域或城市中心等。）
      </div>
      <p class="righbomcp" style="width: 400px;text-align: center;">联系我们:400-600-8891</p>
    </div>
    <div class="right" v-if="yuankacti == 'three'">
      <p class="rightop">钢旗3镁11铝锌铝镁覆膜产品 SZAMAKO</p>
      <div class="righcen">
        <p class="righcentext" style="width: 682px;"> 为耐候、防腐等特殊需求制定的高标准产品。<br/>色牢度高、耐超高低温、耐酸碱、耐化学溶剂腐蚀。</p>
      </div>
      <div style="font-size: 22px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          width: 682px;
          line-height: 30px;
          margin-bottom: 20px;"
      >
        使用领域：广泛应用于化工厂、化肥厂、畜牧厂、造纸厂、冶炼厂、制药厂、
        陶瓷厂、危废处理及沿海工业厂房。
      </div>
      <p class="righbomcp" style="width: 400px;text-align: center;">联系我们:400-600-8891</p>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      default: {
        //默认值
        classArray: ["one", "two", "three"],
        leftSlider: true,
      },
      yuankacti:'one',
      bannerWrap: null,
      time: null,
      lisArray: null,   //卡片的集合
      classlist: null,
      imgarr:[
        {
          src:require("../../assets/img/newenergy/gq.png")
        },
        {
          src:require("../../assets/img/newenergy/gq1.png")
        },
        {
          src:require("../../assets/img/newenergy/gq2.png")
        },
      ]

    };
  },
  methods: {
    constructor() {
      this.bannerWrap = document.getElementsByClassName("banner-card")[0];
      let lis = this.bannerWrap.getElementsByTagName("li");
      // 将类数组转化为数组
      this.lisArray = Array.from(lis);
      this.classlist = this.default.classArray;
      //获取左右按钮
      this.move(this.classlist);

      this.listenMouseEvent();
      // this.listenMouseClickBtnEvent();
    },
    //移动
    move(list) {
      this.lisArray.forEach((value, index) => {
        value.setAttribute("class", list[index]); //给每一个li添加对应的类名
        console.log(list[0]);
        this.yuankacti = list[0]
      });
    },
    // 向左运动
    leftMove() {
      let popValue = this.classlist.pop(); //删除并返回数组的最后一个
      this.classlist.unshift(popValue); //把刚才删除的最后一个添加到数组开头
      this.move(this.classlist);        //调用移动方法，每一个li会根据新数组的位置进行移动，从而实现轮播的效果
    },
    // 向右运动
    rightMove() {
      let shiftValue = this.classlist.shift(); //删除并返回数组的第一个
      this.classlist.push(shiftValue); //把刚才删除的第一个push到数组末尾
      this.move(this.classlist);
    },
    // 定时器自动切换
    timer() {
      this.time = setInterval(this.leftMove.bind(this), 3000);
    },
    // 鼠标移入移出事件
    // listenMouseEvent() {
    //   this.bannerWrap.addEventListener("mouseover", () => {
    //     clearInterval(this.time);

    //   });
    //   this.bannerWrap.addEventListener("mouseout", () => {
    //     this.timer();
    //   });
    // },
    // // 点击左右按钮事件
    // listenMouseClickBtnEvent() {
    //   this.leftBtn.addEventListener("click", () => {
    //     this.leftMove();
    //   });
    //   this.rightBtn.addEventListener("click", () => {
    //     this.rightMove();
    //   });
    // },
  },
  mounted() {
    this.constructor();
    console.log(this.lisArray);
  },
};

</script>

<style scoped lang="scss">

.backcore{
  width: 720px;
  height: 70px;
  background: rgb(34,129,192);
  position: relative;
  top: -320px;
  left: 6px;
}
.yuanquan{
  width: 100px;
  margin: 0 auto;
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .yuank{
    width: 20px;
    height: 20px;
    border-radius: 20px;
    border: 1px solid red;
  }
  .yuankactive{
    background:red ;
  }
}
.banner-card {
  width: 100%;
  height: 600px;
  line-height: 250px;
  position: relative;
  // margin-top: 100px;
}
.banner-card ul {
  width: 700px;
  height: 600px;
  margin: 0 auto;
  text-align: center;
  position: relative;
  overflow: hidden;
}
.banner-card li {
  width: 516px;
  height: 600px;
  position: absolute;
  top: 25px;
  list-style-type: none;
  transition: 0.3s;
  cursor: pointer;
  border-radius: 4px;
}
.banner-card li img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.one {
  z-index: 99;
  left: 0px;
  transform: scale(0.9);
}

.two {
  z-index: 100;
  left: 100px;
  transform: scale(1);
}
.three {
  left: 200px;
  z-index: 99;
  transform: scale(0.9);
}
.four {
  z-index: 98;
  left: 2142px;
  transform: scale(0.8);
}
.five {
  left: 2942px;
  z-index: 97;
  transform: scale(0.7);
}
.six {
  left: 3742px;
  z-index: 96;
  transform: scale(0.6);
}

.banner-card div {
  transition: 0.3s;
  position: absolute;
  top: 0;
  cursor: pointer;
  opacity: 0;
}
.banner-card div span {
  display: inline-block;
  font-size: 25px;
}

.left-btn {
  width: 100px;
  height: 20px;
  left: 340px;
  top: -200px;
  z-index: 10000;
  // border: 1px solid red;
  position: relative;
  img{
    width: 100px;
    height: 20px;
  }
}
.right-btn {
  width: 100px;
  height: 20px;
  left: 1168px;
  top: -220px;
  z-index: 10000;
  // border: 1px solid red;
  position: relative;
  img{
    width: 100px;
    height: 20px;
  }
}

.right{
  position: absolute;
  right: 10%;
  top: 255%;
  margin-left: 80px;
  .rightop{
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 37px;
    margin-top: 30px;
  }
  .righcen{
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .righcentext{
    font-size: 22px;
    font-family: PingFangSC-Light, PingFang SC;
    font-weight: 300;
    color: #666666;
    line-height: 60px;
  }
  .righbomcp{
    font-size: 32px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 45px;
    //background: #E43E04;
    border-radius: 4px;
    padding: 10px;
    width: 400px;
    height: 45px;
    color: white;
  }
}
</style>